import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';

import { ExcelService } from '../../../../../service/excel.service';
import * as XLSX from 'xlsx';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-ledger-detail',
  templateUrl: './ledger-detail.component.html',
  styleUrls: ['./ledger-detail.component.css'],
  providers: [DatePipe]
})
export class LedgerDetailComponent implements OnInit {
  
 @ViewChild('ledgerDetailTable', { static: false }) tableContainer!: ElementRef;
  fileName = 'ledger_detail.xlsx';

  math = Math;
  selecTed: any;
  cmpny: any;
  units: any;
  branch_stocks: any;
  total_stocks: any;
  branch_stksbool: boolean;
  searchleadger: any; ledger_sel_id: any;
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  
  filter_amnt_optns = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  users: any;
  added_by: any;
  vch_type: any;
  voucher: any;
  pageLoadingImg: any;
  up: any;
  reportData: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  period_type: any;
  bal: any;
  is_notes: boolean;
  with_prev_bal: boolean;
  supplier_inv_no: boolean;
  
  is_branch_ref_no: boolean;
  is_sup_inv_no: boolean;
  is_ref_no: boolean;
  colSpan: number;
  disp_void: any;
  logoImgUrl :any = '';
  printPreview: any;
  recPriviewLoader: boolean;

  payPriviewLoader: boolean;
  salesPriviewLoader: boolean;
  resultobj: any;
  showdata: boolean;
  purchaseItems: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: 'P',
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  };
  payType = ['Cash', 'Credit'];
  tempImgUrl: any;
  previewData: any[];
  is_prew_description: any;
  gd_name:any;
  purchData: any;
  purchDataItems: string[];
  purchDataRetAmount:any;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  qr_inv: any;
  previewDataitems = [];
  stocktr_amount = 0;
  transferdate: any;
  received_date: any;
  cur_lang = '';
  country_dec: number=2;
  ptype: any;
  date1: string;
  date2: string;
  usr_hide_others_sales_data: any;
  user_vanroute: any;
  ledger_id: any;
  noLedger: boolean;
  msg: any;
  is_disply_rabate_pdc: boolean;
  amount_filter: any;
  amnt_val1: any;
  amnt_val2: any;
  dis_cash_cust: boolean;
  loged_branch_id: any;
  userType: any;
  branch_all: any;
  filter_branch_id: number;
  disply_rabate_pdc: any;
 
  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService,
              private translate: TranslateService, private datepipe: DatePipe, private currencyPipe: CurrencyPipe, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    this.userType = this.coreService.getUserData('user_type');
    this.filter_branch_id = 1;
    this.getAllBranch();
    // $("#report-wrapper").hide();
    this.pageLoadingImg = false;
    this.up = this.coreService.getUserPrivilage();
    this.searchVoucherType('');
    this.cmpny = this.coreService.getUserData('cmpny');
    this.exportLoader = false;
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.loged_branch_id = this.coreService.getUserData('branch_id');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.is_notes= localStorage.getItem('is_notes')? JSON.parse(localStorage.getItem('is_notes')): true;
    this.is_sup_inv_no= localStorage.getItem('is_sup_inv_no') ? JSON.parse(localStorage.getItem('is_sup_inv_no')):true;
    this.is_branch_ref_no= localStorage.getItem('is_branch_ref_no') ?JSON.parse(localStorage.getItem('is_branch_ref_no')):true;
    this.is_ref_no= localStorage.getItem('is_ref_no') ?JSON.parse(localStorage.getItem('is_ref_no')):true;
    this.colSpan = 8;
    this.with_prev_bal = false;
    this.supplier_inv_no = false;
    this.disp_void = '1';
    this.getHeaderImage();
    this.getTemplete();
    this.previewData = [];
    this.is_prew_description = [];

    this.country_dec=this.coreService.setDecimalLength();

    let searchval = new FormData();
    searchval.append("period_type", 't');
    this.activatedRoute.params.subscribe(params => {
      this.ledger_sel_id = params['ledger_sel_id'];
      this.ptype = params['ptype'];

      console.log("params");
      console.log(params);

     
      if (this.ledger_sel_id && this.ptype) {

          searchval.append('ledger_id', this.ledger_sel_id);
          searchval.append('ledger_sel_id', this.ledger_sel_id);
        if(this.ptype=='c'){

          this.date1= this.apiService.formatDate(params['d1']);
          this.date2= this.apiService.formatDate(params['d2']);
          searchval.append("period_type", this.ptype);
          searchval.append("date1", this.date1);
          searchval.append("date2", this.date2);
        }else{
          searchval.append("period_type", this.ptype);
        }

        this.apiService.getLedgerDetailRep(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          this.reportData = res.data;
          this.selecTed = res.data;
          this.bal = 0 - res.data.op_balance;



          if (this.selecTed.vouchers.length > 0) {
            for (let i = 0; i < this.selecTed.vouchers.length; i++) {
              this.selecTed.vouchers[i].balance = this.bal = this.bal + (this.selecTed.vouchers[i].vch_in - this.selecTed.vouchers[i].vch_out);
            }
          }

        });
        
      }

      if (this.ledger_sel_id) {

          searchval.append('ledger_id', this.ledger_sel_id);
          searchval.append('ledger_sel_id', this.ledger_sel_id);
        this.apiService.getLedgerDetailRep(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          this.reportData = res.data;
          this.selecTed = res.data;


          this.bal = 0 - res.data.op_balance;
          if (this.selecTed.vouchers.length > 0) {
            for (let i = 0; i < this.selecTed.vouchers.length; i++) {
              this.selecTed.vouchers[i].balance = this.bal = this.bal + (this.selecTed.vouchers[i].vch_in - this.selecTed.vouchers[i].vch_out);
            }
          }

        });
        
      }
    });

  }
  // openPDF


  // form search  ledger
  searchLedger(search: string) {
    // $('#report-wrapper').hide();

    const searchval = new FormData();
    searchval.append('search', search);
    this.user_vanroute =this.coreService.getUserData('usr_van_line');
    if(this.usr_hide_others_sales_data && this.user_vanroute){
      
      searchval.append('van_line', this.user_vanroute);
    }
    this.apiService.searchAccLedgerRpt(searchval, 1).subscribe((res: any) => {
      this.searchleadger = res.data ? res.data.data : '';
      // this.selecTed = [];
    });

  }

  changeColSpan(val) {
   
localStorage.setItem('is_notes', JSON.stringify(this.is_notes));
localStorage.setItem('is_sup_inv_no', JSON.stringify(this.is_sup_inv_no));
localStorage.setItem('is_branch_ref_no', JSON.stringify(this.is_branch_ref_no));
localStorage.setItem('is_ref_no', JSON.stringify(this.is_ref_no));

    if (val) {
      this.colSpan = this.colSpan + 1;
    } else {
      this.colSpan = this.colSpan - 1;
    }
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.NonVoided','Production.Voided','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '0', name: res['Production.Voided'] },
    
      ];
    });

  


  }

  findLedger(ledger_code) {
    if(ledger_code){
    const searchval = new FormData();
    searchval.append('ledger_code', ledger_code);

    this.apiService.getLedgerDetailcode(searchval).subscribe((res) => {
      // this.ledger_id = res['data']['ledger_id'];
      if(res['data']){
        this.msg =  res['msg'];
        this.ledger_id = res['data']['ledger_id'];
        this.selectLedger('', this.ledger_id, 'g');
        console.log('this.msg ',this.msg );
        
      }
      else {
        this.msg =  res['msg'];
        this.ledger_id = null;
        // this.selectLedger('', this.ledger_id, 'g');
        console.log('this.msg ',this.msg );
        $('#report-wrapper').hide();
        this.selecTed = [];
        // this.clearCustomer();
      }

    });
  }

  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: 'P',
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      };
      if(res.data.prnt_common_header_img){
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
      }
      else if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }

      if (res.data.prnt_header_type === 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }

  exportAsXLSX(form, ledger_sel_id): void {

    form.value.ledger_id = ledger_sel_id;


    const searchval = new FormData();

    this.selecTed = [];

    searchval.append('ledger_id', ledger_sel_id);

    this.apiService.getLedgerDetailRep(searchval).subscribe((res) => {

      this.reportData = res.data;
    });

    this.excelService.exportAsExcelFile(this.reportData, 'Ledger Detail Report');
  }
  selectLedger(form, lid, flag, excel = null) {

     this.is_disply_rabate_pdc= false;
    if (flag === 'g') {
      if (lid) {
        this.amount_filter =null;
        this.amnt_val1=null;
        this.amnt_val2=null;
        this.dis_cash_cust=false;
        this.added_by = '';
        this.voucher = '';
        this.ledger_sel_id = lid;
        // $("#report-wrapper").show();
        const searchval = new FormData();
        this.pageLoadingImg = true;
        this.selecTed = [];
      
        this.with_prev_bal = false;
        this.supplier_inv_no = false;
        // this.is_branch_ref_no = true;
        // this.is_sup_inv_no = true;
        // this.is_ref_no = true;
          // this.is_notes = true;
        
        // this.colSpan = 8;


        searchval.append('ledger_id', lid);
        searchval.append('ledger_sel_id', lid);

        if(this.coreService.getUserData('cmpny') == 'NAW'){
          searchval.append('period_type', 'lm');
          this.period_type = 'lm';
        } else {
          searchval.append('period_type', 't');
          this.period_type = 't';
        }
        
        this.apiService.getLedgerDetailRep(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          this.reportData = res.data;
          this.selecTed = res.data;
         


          this.bal = 0 - res.data.op_balance;
          if (this.selecTed.vouchers.length > 0) {
            for (let i = 0; i < this.selecTed.vouchers.length; i++) {
              this.selecTed.vouchers[i].balance = this.bal = this.bal + (this.selecTed.vouchers[i].vch_in - this.selecTed.vouchers[i].vch_out);
            }
          }

          
            setTimeout(() => {
              this.checkTableclospan();  
            }, 500);


        });

      } else{
        $('#report-wrapper').hide();
        this.selecTed = [];
      }
    } else {
      if (form.value.period_type === 'c') {
        form.value.date1 = (form.value.date1) ? this.apiService.formatDate(form.value.date1) : form.value.date1;
        form.value.date2 = (form.value.date2) ? this.apiService.formatDate(form.value.date2) : form.value.date2;
      // console.log("before : "+form.value.vch_id);
        if (form.value.vch_id >= 0 && (form.value.vch_id !== '' && form.value.vch_id != null)) {
        form.value.vch_id = Number(form.value.vch_id);
        }


      }

      if (excel === 1) {


        form.value.ledger_sel_id = this.ledger_sel_id;
        this.apiService.getLedgerDetailRepExcel(form.value).subscribe((res) => {

          this.reportData = res.data;
          this.excelService.exportAsExcelFile(this.reportData, 'Ledger Detail Report');

        });
      } else {
        this.selecTed = [];
        // this.is_notes = true;
        // this.is_branch_ref_no = true;
        // this.is_ref_no = true;
        // this.colSpan = 7;

        this.pageLoadingImg = true;
        form.value.ledger_sel_id = this.ledger_sel_id;

        // console.log("before : "+form.value.vch_id);
        if (form.value.vch_id >= 0 && (form.value.vch_id !== '' && form.value.vch_id != null)) {
          form.value.vch_id = Number(form.value.vch_id);
        }


        this.apiService.getLedgerDetailRep(form.value).subscribe((res) => {
          this.pageLoadingImg = false;
          this.reportData = res.data;
          this.selecTed = res.data;
         

          this.bal = 0 - res.data.op_balance;
          if (this.selecTed.vouchers.length > 0) {
            for (let i = 0; i < this.selecTed.vouchers.length; i++) {
              this.selecTed.vouchers[i].balance = this.bal = this.bal + (this.selecTed.vouchers[i].vch_in - this.selecTed.vouchers[i].vch_out);
            }
          }


        });

      }
    }



  }

  searchUser(search: string) {
    const searchval = new FormData();
    searchval.append('usr_name', search);
    this.apiService.getUserByName(searchval).subscribe((res: any) => {
      this.users = res.data;
    });
  }
  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.vch_type = res.data;
    });
  }

  checkTableclospan() {
    // Function to set the initial colSpan when selecting a ledger
    if (this.tableContainer) {
      const table = this.tableContainer.nativeElement;

    
      const headers = table.querySelectorAll('thead th');
      if (headers.length) {
        this.colSpan= headers.length -3;

        console.log('Number of <th> elements: ', headers.length);
      } else {
        console.log('Table headers not found!');
      }
    }
  }


  exportexcel(): void {
    this.exportLoader = true;
    /* table id is passed over here */
    const element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  // To get Header image which will be the part of pdf report
  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {

      if(res.data.prnt_common_header_img){
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
      }else if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type === 2) {

        if(res.data.prnt_common_header_img){
          this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
        }else if(res.data.prnt_header_img_path){
          this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        }else{
          this.logoImgUrl = '';
        } 
        
      } else {
        this.logoImgUrl = '';
      }
    });
  }

  // pdf download
  generatePdf() {

    const heads = [
        this.translate.instant('ACCOUNTS.Vch_No'),
        this.translate.instant('ACCOUNTS.Particulars'),
        this.translate.instant('Common.Vocher_type'),
        this.translate.instant('Common.date'),
        this.translate.instant('HOME.debit'),
        this.translate.instant('HOME.credit'),
        this.translate.instant('Common.Balance')
    ];

    if(this.is_ref_no) {
      heads.splice(2,0, this.translate.instant('Purchase.refno'))
    }

    if(this.is_branch_ref_no) {
      heads.splice(3,0, this.translate.instant('transaction.Branch_Ref_No'))
    }
    // if(this.is_sup_inv_no) {
    //   heads.splice(4,0, this.translate.instant('Purchase.sup_inv_no'))
    // }

    if(this.is_notes) {
      heads.splice(5,0, this.translate.instant('Purchase.notes'))
    }


    const data = [];
    const headerHeight = 52;
    const currency = (this.selecTed.opening_balance > 0) ? 'DR' : (this.selecTed.opening_balance < 0) ? 'CR' : '';

    if (this.selecTed.report.length) {
      
      if(this.with_prev_bal){
        const arr = ['', this.translate.instant('HOME.previous_balance'), '','', this.currencyPipe.transform(this.selecTed.op_balance_no_disp_dr, '', '', '1.1-3') , this.currencyPipe.transform(this.selecTed.op_balance_no_disp_cr, '', '', '1.1-3'),''];
        if(this.is_ref_no) {
          arr.splice(2,0, '')
        }

      if(this.is_branch_ref_no) {
          arr.splice(3,0, '')
        }
        // if(this.is_sup_inv_no) {
        //   arr.splice(4,0, item.sup_inv_no)
        // }

      if(this.is_notes) {
          arr.splice(5,0,'')
        }

      data.push(arr);
      }
      this.selecTed.vouchers.forEach(item => {
        var ledger_name = (this.translate.currentLang == 'Arabic') ? item.ledger_alias :
         item.ledger_name;
    
        const arr = [item.vch_no,ledger_name,item.vchtype_name, this.datepipe.transform(item.vch_date, 'dd/MM/yyyy'), (item.vch_in).toFixed(this.country_dec) , (item.vch_out).toFixed(this.country_dec),(item.balance).toFixed(this.country_dec)];
        if(this.is_ref_no) {
          arr.splice(2,0, item.ref_no)
        }
        if(this.is_branch_ref_no) {
            arr.splice(3,0, item.branch_ref_no)
          }

        if(this.is_notes) {
            arr.splice(5,0,item.vch_notes)
          }

        data.push(arr);
        });
    }

    const arrCol = [];
    if (this.is_ref_no) { arrCol.push(''); }
    if (this.is_branch_ref_no) { arrCol.push(''); }
    // if (this.is_sup_inv_no) { arrCol.push(''); }
    if (this.is_notes) { arrCol.push(''); }
    if(this.with_prev_bal){
      data.push(['', ...arrCol, '', '', '', (this.selecTed.total_in_amt+(this.selecTed.op_balance_no_disp_dr ? this.selecTed.op_balance_no_disp_dr : 0)).toFixed(this.country_dec), (this.selecTed.total_out_amt+(this.selecTed.op_balance_no_disp_cr ? this.selecTed.op_balance_no_disp_cr : 0)).toFixed(this.country_dec), '']);    
    }else{
      data.push(['', ...arrCol, '', '', '', (this.selecTed.total_in_amt).toFixed(this.country_dec), (this.selecTed.total_out_amt).toFixed(this.country_dec), '']);   
    }
    const lblXPos = 18;
    const valueXPos = 55;

    let doc = this.excelService.getPdfObj();

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text('Ledger Details Report', 85, headerHeight - 2);


    doc.setFontSize(10);
    // doc.text('Branch', lblXPos, headerHeight + 5);
    doc.text(`${this.translate.instant('HOME.company_name')}`, lblXPos, headerHeight + 5);
    doc.setFontSize(11);
    doc.text(':  ' + this.branch_display_name,  valueXPos, headerHeight + 5);

    doc.setFontSize(10);
    doc.text('Period', lblXPos, headerHeight + 10);
    doc.text(':  ' + this.selecTed.period,  valueXPos, headerHeight + 10);
    if(this.translate.currentLang == 'Arabic'){
      var lname = this.selecTed.ledger_alias;
      var gname = this.selecTed.accgrp_name_ar;
    } else {
      var lname = this.selecTed.ledger_name;
      var gname = this.selecTed.accgrp_name;
    }
    doc.text(`${this.translate.instant('ACCOUNTS.Ledger_Name')}`, lblXPos, headerHeight + 15);
    doc.text(':  ' + lname,  valueXPos, headerHeight + 15);

    doc.text(`${this.translate.instant('ACCOUNTS.Grouped_Under')}`, lblXPos, headerHeight + 20);
    doc.text(':  ' + gname,  valueXPos, headerHeight + 20);

    doc.text(`${this.translate.instant('ACCOUNTS.Company_Branch')}`, lblXPos, headerHeight + 25);
    doc.text(':  ' + this.selecTed.branch_display_name,  valueXPos, headerHeight + 25);

    doc.setFontSize(11);
    doc.setTextColor(88, 186, 15);
    doc.text(`${this.translate.instant('ACCOUNTS.Opening_Balance_On')} ` + this.selecTed.branch_date + ' :', lblXPos, headerHeight + 32);
    doc.text(this.selecTed.opening_balance + currency,  valueXPos + 30, headerHeight + 32);

    doc.rect(lblXPos + 115, headerHeight + 26, 60, 7);
    doc.text(`${this.translate.instant('Common.closing_bal')}:`, lblXPos + 118, headerHeight + 29);
    doc.text(this.selecTed.clsngblnc,  valueXPos + 125 - this.selecTed.clsngblnc.length, headerHeight + 29);
    doc.setTextColor(0, 0, 0);
    doc.line(lblXPos, headerHeight + 35, lblXPos + 178, headerHeight + 35);


    doc = this.excelService.addTableToPdf(doc, headerHeight + 38, lblXPos, heads, data, this.logoImgUrl, this.selecTed.branch_display_name);

    doc.setFontSize(10);
    doc.setDrawColor(0, 0, 0);
    // tslint:disable-next-line:no-string-literal
    const tblFooterY = doc['lastAutoTable'].finalY + 5;
    doc.line(lblXPos, tblFooterY - 11, lblXPos + 178, tblFooterY - 11);
    doc.line(lblXPos, tblFooterY, lblXPos + 178, tblFooterY);

    // doc.text(this.translate.instant('Common.closing_bal') + ':', lblXPos , tblFooterY + 4);
    // doc.text(this.selecTed.cl_balance_disp_dr ? this.selecTed.cl_balance_disp_dr : this.selecTed.cl_balance_disp_cr,  valueXPos + 100, tblFooterY + 4);


    // doc.text(this.translate.instant('Common.current_total') + ':', lblXPos , tblFooterY + 4);
    // doc.text(this.selecTed.curr_total_disp_dr ? this.selecTed.curr_total_disp_dr : this.selecTed.curr_total_disp_cr,  valueXPos + 100, tblFooterY + 4);

    // doc.line(lblXPos, tblFooterY + 5, lblXPos + 178, tblFooterY + 5);
    // doc.text(this.translate.instant('Common.opening_bal') + ':', lblXPos, tblFooterY + 9);
    // doc.text(this.selecTed.op_balance_disp_dr ? this.selecTed.op_balance_disp_dr : this.selecTed.op_balance_disp_cr,  valueXPos + 100, tblFooterY + 9);
    if(this.with_prev_bal){
      doc.line(lblXPos, tblFooterY - 5, lblXPos + 178, tblFooterY - 5);
      doc.text(this.translate.instant('Common.closing_bal') + ':', lblXPos, tblFooterY - 1);
      doc.text(this.selecTed.cl_balance_disp_dr ? this.selecTed.cl_balance_disp_dr : this.selecTed.cl_balance_disp_cr,  valueXPos + 100, tblFooterY - 1);
      doc.line(lblXPos, tblFooterY + 15, lblXPos + 178, tblFooterY + 15);
    }
    if(!this.with_prev_bal){
      doc.text(this.translate.instant('Common.current_total') + ':', lblXPos , tblFooterY + 4);
      doc.text(this.selecTed.curr_total_disp_dr ? this.selecTed.curr_total_disp_dr : this.selecTed.curr_total_disp_cr,  valueXPos + 100, tblFooterY + 4);

      doc.line(lblXPos, tblFooterY + 5, lblXPos + 178, tblFooterY + 5);
      doc.text(this.translate.instant('Common.opening_bal') + ':', lblXPos, tblFooterY + 9);
      doc.text(this.selecTed.op_balance_disp_dr ? this.selecTed.op_balance_disp_dr : this.selecTed.op_balance_disp_cr,  valueXPos + 100, tblFooterY + 9);

      doc.line(lblXPos, tblFooterY + 10, lblXPos + 178, tblFooterY + 10);
      doc.text(this.translate.instant('Common.closing_bal') + ':', lblXPos, tblFooterY + 14);
      doc.text(this.selecTed.cl_balance_disp_dr ? this.selecTed.cl_balance_disp_dr : this.selecTed.cl_balance_disp_cr,  valueXPos + 100, tblFooterY + 14);

      doc.line(lblXPos, tblFooterY + 15, lblXPos + 178, tblFooterY + 15);
    }

    doc.save(`Ledger Details -  + ${this.selecTed.ledger_name}.pdf`);
  }

  viewContra(id) {
    this.recPriviewLoader = true;

    this.apiService.getContra({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;

      // console.log(this.printPreview.rec_ttl_amount);

    });

  }
  viewJournal(id) {
    this.recPriviewLoader = true;

    this.apiService.getJournal({ ref_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;
      console.log(' this.printPreview', this.printPreview);
      


    });

  }
  viewPayment(id) {
    this.payPriviewLoader = true;

    this.apiService.getPayment({ rec_no: id }).subscribe((res) => {
      this.payPriviewLoader = false;

      this.printPreview = res;


    });

  }
  viewReceipt(id) {
    this.recPriviewLoader = true;
    this.apiService.getReceipt({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
      console.log('his.printPreview',this.printPreview);
      
    });
  }
  sale_search(search) {
    this.salesPriviewLoader = true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        console.log(' this.saleData ', this.saleData );
        
      }


    });
  }


  previewPurchase(id) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', id);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
      // setTimeout(function () { $("#previewButton").click(); }, 1000);

    });
  }

  getPurchRetPreview(id: any) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purchret_id', id);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.purchData = res['data'][0];
      this.purchDataItems = res['data'][0]['items']; 
      if(!res['data'][0].purchret_frieght)
      res['data'][0].purchret_frieght = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0; 
      
      this.gd_name = 'Shop';

      this.purchDataRetAmount = Number(res['data'][0].purchret_amount);
      this.purchData.purchret_amount = Number(res['data'][0].purchret_amount)-Number(res['data'][0].purchret_tax)
      +Number(res['data'][0].purchret_discount);
    });

  }

  getSalesRetPreview(prdn_id) {
    this.saleprivewLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });

    });

  }

  getStockTranPreview(prdn_id) {

    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      // this.branch_from = res.from_branch;
      this.previewDataitems = res.items;
      this.stocktr_amount = res.stocktr_amount;
      this.transferdate = this.datepipe.transform(res.stocktr_date, 'dd/MM/yyyy');
      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);
    });

  }
  getStockRecPreview(prdn_id) {
    this.previewData = [];
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      this.received_date = new Date(res.stocktr_date);
    });

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      this.filter_branch_id = res.selected;

      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }


}
