import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit } from '../../../../model/stockedit.model';
import { Branch } from '../../../../model/branch.model';
import { log } from 'util';

@Component({
  selector: 'app-update-rate-popup',
  templateUrl: './update-rate-popup.component.html',
  styleUrls: ['./update-rate-popup.component.css']
})
export class UpdateRatePopupComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @Input()search_prd_id;
  @Input() name: string;
  @Output() update = new EventEmitter<void>();
  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null, prd_units: null,
    manufacture_date: null, expiry_date: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };


sel_urates:any[];

  stkprd_all: string[];
  rec_unit_rates: any[];
  branches: [];

  branchids: number[];
  data_unit_rates: any[]
  resultobj: any = {};
  error = '';
  stkprdsel: any;
  pageLoadingImg: boolean;
  branch_units: any;
  skIp: any;
  taKe: any;
  toTal: any;
  counT: any;
  branch_name: any;
  up: any;
  prd_tax:any;
  chk_all:any;
  cmpny:any;
  is_rate_2: boolean;
  barcode:any;
  // units_rates:any;
  usr_hide_purch_cost: any;
  country_dec: string;
  display_qty: JQuery<HTMLElement>;
  batches = [];
  prod_alias: any;
  cur_lang: string;
  Add_Barcodes: any;
  unit_id: any;
  additional_barcodes: any;
  generalSearch_type: any;
  g_settings: any;
  gdstocksdata: any;
  ErrCL:any;
  ErrCLRate2:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private scroller: ViewportScroller) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    this.is_rate_2 = false;
    this.cmpny = this.coreService.getUserData('cmpny');
    this.up = this.coreService.getUserPrivilage();
   this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
   this.country_dec=this.coreService.setDecimalLength();

  }
  ngOnChanges() {
    this.searchStkPrd();
   }
   updateFunction() {
    this.searchStkPrd();
  }
  
  searchStkPrd() {
    let searchval = new FormData();
    searchval.append("prd_id", this.search_prd_id);
    this.apiService.getQtybyNameNew(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      if( this.stkprd_all.length > 0){
             this.selectStockPrd(this.stkprd_all[0]);    
    }
   
    });
  }

  
  //form search select
  selectStockPrd(res: any) { 
    if(res.prd_id != undefined){
    this.getBranches(res.prd_id);
    this.getBatches(res.prd_id);
    }
    
    this.stkprd_all = [];
    var units_notsel = [];
    var sel_unit_rates = [];
    this.branch_units = [];
    if (res) {
      this.selecTed = res;
      this.Add_Barcodes = this.selecTed.additional_barcodes;
      sel_unit_rates = this.selecTed.units_rates;         
      this.branchids = this.selecTed.branch_id;
      this.prd_tax = this.selecTed.prd_tax;
      this.display_qty=this.selecTed.bs_rate2_display_qty;
      
   if(this.selecTed.units_rates.length >0){
    this.sel_urates = this.selecTed.units_rates;
    this.findtaxesrate(this.sel_urates)
   }
 
 
      
     
      $('#chk_all').prop('checked',false);
      $('#error_in').html('');
      $('#editstockform').show();
      // $('.collapse').hide();
    }
  }

  updateStock(form: { value: any; }) {
    this.pageLoadingImg = true;
    let errObjArr = {};
    let errObjArrrate2 = {};
    let validaterr=false;
    var errorobj = false;
    var confrm=true;
    let unit_rates=[];
    let units_eans=[];
    let unit_rate2
    let unit_rate
// validation ************** and push 
    this.sel_urates.forEach((element,i) => {
  
      units_eans.push({
        "unit_id":  element.sur_unit_id,"unit_ean":   element.produnit_ean_barcode,
     });

      if (element.unit_type ==1 || element.unitratechk==true) {
  
   
        if (Number(element.sur_unit_rate) <= 0) {
          errorobj = true;
        }
        if (!errorobj) {
          unit_rate=element.sur_unit_rate
        }
      
               if((Number(element.sur_unit_rate))<(form.value.purchase_rate*Number(element.unit_base_qty))){  
                validaterr=true;
                errObjArr[i] = 'Sales Rate is Less Than Purch.Rate';
       }

        
  
         if(form.value.is_rate_2 ==true){


        if (Number(element.sur_unit_rate2) <= 0) {
                  errorobj = true;
                }
                if (!errorobj) {
                 unit_rate2 = element.sur_unit_rate2;
                }
        if((Number(element.sur_unit_rate2))<(form.value.purchase_rate*Number(element.unit_base_qty))){  
              validaterr=true;
              errObjArrrate2[i] = 'Sales Rate is Less Than Purch.Rate';
        }
    
      
       }
      } 

      if(!errorobj && (element.unit_type == 1 || element.unitratechk==true)){
        if(form.value.is_rate_2 ==true){
        unit_rates.push({
          "unit_rate": unit_rate,"unit_rate2": unit_rate2,"unit_id":  element.sur_unit_id,"unit_ean":   element.produnit_ean_barcode,
        });
      }else{
        unit_rates.push({
          "unit_rate": unit_rate, "unit_id":  element.sur_unit_id,"unit_ean":   element.produnit_ean_barcode,
        });

      }
    }
      
  });

  
  this.ErrCL=errObjArr;
  this.ErrCLRate2=errObjArrrate2;
  
  if (errorobj) {
    $('#error_in').html('Please enter  value greater than zero');
    this.pageLoadingImg = false;
  }
  else {
    $('#error_in').html('');
    this.pageLoadingImg = false;
  }
  if(validaterr){
    confrm = confirm("Rate is Less Than Purch.Rate");
 } 
 // end validation ************** and push
    if (!errorobj && confrm) {
      this.pageLoadingImg=true;
       const value = {
          'unit_rates': unit_rates,
          'is_rate_2': form.value.is_rate_2,
          'purchase_rate': form.value.purchase_rate,
          'prd_id':form.value.prd_id,
          'stock_id': form.value.stock_id,
          'units_eans': units_eans,
          'selBatch':form.value.selBatch,
          ...(form.value.is_rate_2==true ? { 'display_qty': form.value.display_qty } : {})
        }
      this.apiService.updateStock(value).subscribe((res: Stockedit) => {
        this.pageLoadingImg = false;
        if (res.error != null) {
          this.resultobj = res.error;
          if (typeof res.sh_rr !== 'undefined') {
            $('#error_in').html(res.error);
            document.getElementById("edit-group").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
            });
          }else{
            this.resultobj = this.coreService.getValidation(this.resultobj);
          }
        }
        else {
          $("#br_err").text("");
          this.searchStkPrd();
          this.selecTed.bs_stock_quantity_shop='';
          this.selecTed.bs_stock_quantity_gd='';
          this.selecTed.bs_stock_quantity_van='';
          this.resultobj = {};
          this.coreService.createfunct(form, this.resultobj, 'Updated');
          $('#error_in').html('');
         $('#close-updateRateModal').click();
        }

      });
    }
  }

  getBranches(prd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getBranchesWithUnits(searchval).subscribe((branch: Branch) => {
      this.branches = branch['data'];
    });
  }


  getBatches(prd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getBatches(searchval).subscribe((batch: Branch) => {
      this.batches = batch['data'];
    });
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }


  isNumberKey1(e) {
    //if the letter is not digit then display error and don't type anything
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      //display error message
      $("#errmsg").html("Digits Only").show().fadeOut("slow");
      return false;
    }
  }


  selectBatch(batchId, prd_id) {

    let inpVal = new FormData();
    inpVal.append("batchId", batchId);
    inpVal.append("prd_id", prd_id);
    this.apiService.batchUnitRate(inpVal).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        console.log("error");
      }
      else {
       this.sel_urates = res['data'][0]['units_rates'];
      if(this.sel_urates.length >0){
        this.findtaxesrate(this.sel_urates)  
         }
      
      }

    });
  }

  selectStockPrdBranch(branchid, prd_id) {

    let inpVal = new FormData();
    inpVal.append("branchid", branchid);
    inpVal.append("prd_id", prd_id);
    this.apiService.selBranchviseQty(inpVal).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        console.log("error");
      }
      else {
        this.branch_units = res['data'];
      }

    });

  }

  useBranchData(data) {
    const found = this.selecTed.units_rates.some(el => el.unit_name === data.unit_name);
    var existingArray = this.selecTed.units_rates.filter((x => x.unit_name === data.unit_name));
    this.selecTed.units_rates.find(x => x.unit_name === existingArray[0].unit_name).sur_unit_rate = data.unit_rate;
    this.findtaxesrate(this.selecTed.units_rates)
  }

  useBranchDataAll() {
    for (var i = 0; i < this.branch_units.length; i++) {
      if (this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name)) { this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name).sur_unit_rate = this.branch_units[i].unit_rate; }
    }

   this.findtaxesrate(this.selecTed.units_rates)    
  }

  findtaxesrate(unitRates){
    unitRates.forEach((element,i) => {
      if(element.sur_unit_rate){
        this.sel_urates[i].sur_unit_rate_inp2= Number(element.sur_unit_rate) +  Number(((element.sur_unit_rate*this.prd_tax)/100))
       }else{
        this.sel_urates[i].sur_unit_rate_inp2=0;
       }
       if(element.sur_unit_rate2){
        this.sel_urates[i].sur_unit_rate2_inp2=Number(element.sur_unit_rate2) + Number(((element.sur_unit_rate2*this.prd_tax)/100))
       }else{
        this.sel_urates[i].sur_unit_rate2_inp2=0;
       }
        });
  }

  inc_tax(keyword,id){
    if(!isNaN(keyword)){
      let t=0;
      let p=0;
       t = keyword- ((keyword * 100) / (this.prd_tax + 100));
       p = keyword-t;
      this.sel_urates[id].sur_unit_rate=p;
    }
  }
  exl_tax(keyword,id){
    if(!isNaN(keyword)){
      let t=0;
      keyword = Number (keyword);
       t = keyword+ (keyword * this.prd_tax) / 100;
      this.sel_urates[id].sur_unit_rate_inp2=t;
    }
  }

  inc_tax1(keyword,id){
    if(!isNaN(keyword)){
      let t=0;
      let p=0;
       t = keyword- ((keyword * 100) / (this.prd_tax + 100));
        p = keyword-t;
      this.sel_urates[id].sur_unit_rate2=p;
    }
  }
   exl_tax1(keyword,id){
    if(!isNaN(keyword)){
      let t=0;
      keyword = Number (keyword);
    t = keyword+ (keyword * this.prd_tax) / 100;
    this.sel_urates[id].sur_unit_rate2_inp2=t;
    }
  }


  toggleEditable(event) {
    const isChecked = event.target.checked;

    this.sel_urates.forEach((element) => {
        if (element.unit_type == 1) {
            element.unitratechk =1;
        } else {
            element.unitratechk = isChecked ? 1 : 0;
        }
    });
}

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {
  
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });
  
  }
}
