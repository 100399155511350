import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-database-closing',
  templateUrl: './database-closing.component.html',
  styleUrls: ['./database-closing.component.css']
})

export class DatabaseClosingComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService, public router: Router) { 

  }
  cbranches = [];
  obranches = [];
  vans = [];
  branch_id = 0;
  isLoading = true;
  newDbDtnActive = false;
  isAllDBClosed = 0;
  isDbCreatdLoading = false;
  isFinClosLoading = false;
  dbclosSett = {
    sales : {opt : '1', cust_val : ''},
    sales_order : {opt : '1', cust_val : ''},
    sales_quotation : {opt : '1', cust_val : ''},
    delivery_note : {opt : '1', cust_val : ''},
  }

  // 1 => zero stock or zero blance , 2 => closing stock or closing balance
  finClosSett = {
    // 1 => zero , 2 => closing value 
    stock : '1',
    customer : '1',
    supplier : '1',
    assets : '1',
    liability : '1',
    pandl : '1',
  }

  branch_code = '';
  is_new_db_active = 0;
  van_db_clossing_btn_enable = 0;
  van_rate = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  updateSettLoadingImg = false;
  accClosLog: any;
  isSetLoaded = false
  ngOnInit() {
    this.loadData();
    this.getSalesSetting();
    this.branch_code = this.coreService.getUserData('branch_code');
  }

  refresh(){ 
    this.isLoading = true;
    this.loadData();
  }

  loadData(){ 
    this.branch_id = this.coreService.getUserData('branch_id');
    this.listBranchs();
    this.checkAllBranchDBClosed();
  }

  listBranchs() {
    this.isSetLoaded = false;
    this.apiService.getAllBranchList().subscribe((res) => {
      this.cbranches = res['data']['current'];
      this.obranches = res['data']['other'];
      console.log(this.cbranches[0].branch_acc_closed_log);
      this.accClosLog = (this.cbranches[0].branch_acc_closed_log && this.cbranches[0].branch_acc_closed_log != '') ? JSON.parse(this.cbranches[0].branch_acc_closed_log): '{}';
      // console.log(this.accClosLog);
      this.isSetLoaded = true;
      this.ListAllVan();
    });
  }
  ListAllVan() {
    let searchval = new FormData();
    this.apiService.ListAllVanByBranch(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.checkIsClosedAllvanDesktop();
      this.isLoading = false;
    });
  }

  vanForceClose(event, vanInfo, index){
    // Shfit + v => 86 mean v 
    // console.log(event);
    if(event.shiftKey && event.keyCode == 86 && !vanInfo.van_is_db_closed && !this.vans[this.cbranches[0].branch_id][index].loadingImg){
      // console.log(event);
      // console.log(vanInfo.van_id);
      if(confirm("Do You Wish to Force Close Van ( " + vanInfo.van_name + " ) db, Warning : Once closed You Can't sync anymore.")){
        this.vans[this.cbranches[0].branch_id][index].loadingImg  = true;
        this.apiService.vanDbForceClose(vanInfo).subscribe((res) => {
          this.ListAllVan();
        });
      }
    }
  }

  deskForceClose(event, info){
    // Shfit + d => 68 mean d 
    // console.log(event);
    if(event.shiftKey && event.keyCode == 68 && !info.branch_is_desk_db_closed && !this.cbranches[0].loadingImg){
      // console.log(event);
      // console.log(vanInfo.van_id);
      if(confirm("Do You Wish to Force Close Desktop db, Warning : Once closed You Can't sync anymore.")){
        this.cbranches[0].loadingImg  = true;
        this.apiService.desktopDbForceClose().subscribe((res) => {
          this.refresh();
        });
      }
    }
  }

  checkIsClosedAllvanDesktop(){
    var isBtnActive = true;
    if(this.cbranches.length > 0){
      if(!this.cbranches[0].branch_desktop_app || this.cbranches[0].branch_is_desk_db_closed){
        if(this.vans[this.cbranches[0].branch_id]){
          this.vans[this.cbranches[0].branch_id].forEach(element => {
            if(!element.van_is_db_closed){
              isBtnActive = false;
            }
          });
        }
      } else{
        isBtnActive = false;
      }
    } 
    this.newDbDtnActive = isBtnActive;
  }

  closeCurrentDb(){
    if(confirm("Do You Wish to continue ?")){
      this.apiService.closeCurrentDb(this.dbclosSett).subscribe((res) => {
        // this.vans = res['data'];
        $('.dbpopupclosbtn').click();
        this.checkIsClosedAllvanDesktop();
        this.isLoading = false;
        this.coreService.showMessage(res.message,3000);
        this.refresh();
      });
    }
  }

  checkAllBranchDBClosed(){
    this.apiService.checkAllBranchDBClosed().subscribe((res) => {
      this.isAllDBClosed = res.is_all_close
      this.is_new_db_active = res.is_new_db_active
    });
  }
  createNewDatabase(){
    if(confirm("Do You Wish to continue ?")){
      this.isDbCreatdLoading = true;
      this.apiService.createNewDbByClient({}).subscribe((res) => {
        // this.vans = res['data'];
        this.coreService.showMessage(res.message,4000);
        if(!res.status){
          this.isDbCreatdLoading = false;
          this.checkIsClosedAllvanDesktop();
          this.isLoading = false;
          this.refresh();
        } else {
          // logged out code here
          setTimeout(() => {
            this.router.navigate(['/logout']);
          }, 3000);
          // 
        }
        
      });
    }
  }
  financialClosing(){
    if(confirm("Do You Wish to continue ?")){
      this.isFinClosLoading = true;
      this.apiService.financialClosing(this.finClosSett).subscribe((res) => {

        this.coreService.showMessage(res.message,4000);
        if(!res.status){
          this.isFinClosLoading = false;
          this.checkIsClosedAllvanDesktop();
          this.isLoading = false;
          this.refresh();
        } else {
          // logged out code here
          // this.closeModal.nativeElement.click();
          this.isFinClosLoading = false;
          $('.finpopupclosbtn').click();
          this.refresh();
        }

        // this.vans = res['data'];
      //   this.coreService.showMessage(res.message,4000);
      //   if(!res.status){
      //     this.isDbCreatdLoading = false;
      //     this.checkIsClosedAllvanDesktop();
      //     this.isLoading = false;
      //     this.refresh();
      //   } else {
      //     // logged out code here
      //     // this.closeModal.nativeElement.click();
          // $('.finpopupclosbtn').click();
          // setTimeout(() => {
          //   this.router.navigate(['/logout']);
          // }, 3000);
      //     // 
      //   }
        
      },err => {
        this.isFinClosLoading = false;
        this.coreService.showMessage("Somthing went wrong, Please Contact service provider",4000);
      });
    }
  }

  formatPath(data: string, limit = 6): string {
    return (data.replace(/[^0-9]*/g,'')).padStart(limit, "0");
  }


  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.van_db_clossing_btn_enable = res['data'].ss_van_db_clossing_btn_enable;
      } 
    });

    // console.log("shameer");
    // console.log(this.ss_van_default_rate);

  }

  updateSettings(){
    this.updateSettLoadingImg = true;

    this.apiService.updateSingleSettings({van_db_clossing_btn_enable : this.van_db_clossing_btn_enable}).subscribe((res) => {
      this.updateSettLoadingImg = false;
        if (res.data) {
          this.coreService.showMessage('Settings Update');
        } else{
          this.coreService.showMessage('Something Went Wrong');  
          this.getSalesSetting();
        }
    }, (error) => {
        this.updateSettLoadingImg = false;
        this.getSalesSetting();
      });

  }

}
