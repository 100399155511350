import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ActivatedRoute } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs; 
pdfMake.fonts = { 'Roboto': { normal: 'Roboto-Regular.ttf', bold: 'Roboto-Medium.ttf', italics: 'Roboto-Italic.ttf', bolditalics: 'Roboto-Italic.ttf' },
 'THSarabunNew': { normal: 'THSarabunNew.ttf' }, 'code128': { normal: 'code128.ttf' } }
 pdfMake.vfs = pdfFonts.pdfMake.vfs;
 import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
 
@Component({
  selector: 'app-purch-invoice-due',
  templateUrl: './purch-invoice-due.component.html',
  styleUrls: ['./purch-invoice-due.component.css']
})
export class PurchInvoiceDueComponent implements OnInit {

  fileName = 'purchase_invoice_due_summary.xlsx';
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  users: any;
  supplierAll: any;
  pageLoadingImg: boolean;
  summary: any=[];
  total_bal_amnt: any;
  total_inv_amnt: any;
  total_recieved_amnt: any;
  repDate: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  totalData: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  Expsummary: any;
  ExprepDate: any;
  Exptotal_bal_amnt: any;
  Exptotal_inv_amnt: any;
  Exptotal_recievd_amnt: any;
  totalcount: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  pdf: any;
  supp_id: any;
  aging: any;
  country_dec: number=2;
  cur_lang: string;
  filter_branch_id: number;
  branch_all: any;
  userType: any;
  pdfalias: any;
  suppName: any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private activatedRoute: ActivatedRoute,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    // this.filter_branch_id = 1;
    this.getAllBranch();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    let searchval = new FormData();

    this.activatedRoute.params.subscribe(params => {
      this.supp_id = params['id'];
      this.aging = params['aging'];
      if (this.supp_id && this.aging) {
        searchval.append("supp_id", this.supp_id);
        searchval.append("aging", this.aging);
      }
      searchval.append("supp_id", this.supp_id);
      searchval.append("aging", this.aging);
    
    });
    this.pageLoadingImg = true;
    this.apiService.purchaseInvDueummary(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      this.totalcount=this.summary.length;
      // console.log(this.totalData);
      this.pdf = res['data']['pdf'];
      this.pdfalias = res['data']['pdfalias'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });
    this.searchUser('');
    this.userType = this.coreService.getUserData('user_type');
 
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any){

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;
    this.apiService.purchaseInvDueummary(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
    
      this.summary = res['data']['data'];
      this.repDate = res['data'].Date;
      this.total_bal_amnt = res['data']['total_bal_amnt'];
      this.total_inv_amnt = res['data']['total_inv_amnt'];
      this.total_recieved_amnt = res['data']['total_recieved_amnt'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      this.totalcount=this.summary.length;
      this.pdf = res['data']['pdf'];
      this.pdfalias = res['data']['pdfalias'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      
    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

    // form search supplier
    searchSupplier(search: string) {
      const searchval = new FormData();
      searchval.append('supp_name', search);
       if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append("with-alias", "1");
      }
      this.apiService.searchSupplier(searchval).subscribe((res) => {
        this.supplierAll = res.data;
      });
    }

    exportexcel(): void {
      /* table id is passed over here */
      let element = document.getElementById('export-group');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.exportLoader = false;
  
    }
  
  
    exportEXCL(Expformdata: { value: any; }) {
  
      // debugger;
      Expformdata.value.datewise = true;
      Expformdata.value.export = 'export';
      if (Expformdata.value.period_type === 'c') {
        Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
        Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
        Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
        Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
      }
      this.exportLoader = true;
    
      this.apiService.purchaseInvDueummary(Expformdata.value, 1).subscribe((res: any) => {
  
        // if(res['data']['data']){
  
        this.Expsummary = res['data']['data'];
        this.ExprepDate = res['data'].Date;
        this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
        this.Exptotal_inv_amnt = res['data']['total_inv_amnt'];
        this.Exptotal_recievd_amnt = res['data']['total_recieved_amnt'];
  
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
  
      });
      Expformdata.value.export = '';
  
    }

      getAllBranch() {
        this.apiService.readAllBranch().subscribe((res:any) => {
          this.branch_all = res.data;
          this.filter_branch_id = res.selected;
      
          // console.log(  this.branch_all );
        //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
        });
      }
      
      searchBranch(search: string) {
        let searchval = new FormData();
        searchval.append("branch_name", search);
        this.apiService.getBranchByName(searchval).subscribe((res:any) => {
          this.branch_all = res.data;
          // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
        });
      }

// pdf download
generatePdf(Expformdata: { value: any; }) {

  // debugger;
  Expformdata.value.datewise = true;
  Expformdata.value.export = 'export';
  if (Expformdata.value.period_type === 'c') {
    Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
    Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    Expformdata.value.time1 = (Expformdata.value.time1) ? this.apiService.formatTime(Expformdata.value.time1) : Expformdata.value.time1;
    Expformdata.value.time2 = (Expformdata.value.time2) ? this.apiService.formatTime(Expformdata.value.time2) : Expformdata.value.time2;
  }
  this.exportLoader = true;

  this.apiService.purchaseInvDueummary(Expformdata.value, 1).subscribe((res: any) => {

    // if(res['data']['data']){

    this.Expsummary = res['data']['data'];
    this.ExprepDate = res['data'].Date;
    this.Exptotal_bal_amnt = res['data']['total_bal_amnt'];
    this.Exptotal_inv_amnt = res['data']['total_inv_amnt'];

    if (res.data.company){
      this.branch_display_name=res.data.company.branch;

    }
    this.Exptotal_recievd_amnt = res['data']['total_recieved_amnt'];

    const heads = [
      this.translate.instant('Table.sl_no'),
      this.translate.instant('Purchase.ref_no'),
      this.translate.instant('Purchase.supplier_inv'),
      this.translate.instant('transaction.Branch_Inv_No'),
      this.translate.instant('HOME.Suppliers'),
      this.translate.instant('Common.notes'),
      this.translate.instant('Purchase.purchases_date'),
      this.translate.instant('Common.Pay_date'),
      this.translate.instant('Sales.Inv_Amount'),
      this.translate.instant('Table.recieved_amnt'),
      this.translate.instant('Common.balance_Amount')
    ];
  
      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];
  
      this.Expsummary.forEach((item,i)=>{
        const arr=[
          i+1,
          item.purch_due_purch_inv_no,
          item.purch_inv_no,
          item.branch_inv_no,
          item.pay_note,
          this.datePipe.transform(item.purch_date, 'dd/MM/yyyy'),
          this.datePipe.transform(item.pay_date, 'dd/MM/yyyy'),
          Number(item.purch_amount).toFixed(this.country_dec),
          (item.recieved_amnt).toFixed(this.country_dec),
          (item.purch_due_inv_balance).toFixed(this.country_dec)
        ]

        if(this.translate.currentLang == 'English'){
          arr.splice(4,0, item.supp_name)
        } else if(this.translate.currentLang == 'Arabic'){
          arr.splice(4,0, item.supp_alias)
        }

        data.push(arr)
      })
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      // doc.setFont('Amiri');
      // doc.setFontSize(12);
      // doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`Branch`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + this.branch_display_name ,  valueXPos, headerHeight + 10);


  
      if(this.ExprepDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 15);
      }

      
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.purch_invoice_due_summary')}`,  valueXPos, headerHeight + 20);
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_inv_amnt')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_inv_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_recieved_amnt')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_recieved_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.total_bal_amnt')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_bal_amnt).toFixed(this.country_dec),  valueXPos, headerHeight + 35);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
  
     
      doc.save(`Purchase Invoice Due Summary.pdf`);

      this.exportLoader = false;

  });
  Expformdata.value.export = '';
  
}


}
