import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../service/core.service';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-importdash',
  templateUrl: './importdash.component.html',
  styleUrls: ['./importdash.component.css']
})
export class ImportdashComponent implements OnInit {
  userType: any;
  up: any;
  cmpny: any;
  constructor(private apiService: ApiService, private coreService: CoreService) { }
  isbkpEnabled = false;
  ngOnInit() {
    this.userType =  this.coreService.getUserData('user_type');
    this.cmpny = this.coreService.getUserData('cmpny');
    this.up = this.coreService.getUserPrivilage();
    this.apiService.getClientSettingBykey({ key: 'db_bkp' }).subscribe((res) => {
      if (res['data']) {
        this.isbkpEnabled = (res['data']['cs_value']) ? true : false;
      }
    });
  }

}
