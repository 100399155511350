import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ext-transfer-det',
  templateUrl: './ext-transfer-det.component.html',
  styleUrls: ['./ext-transfer-det.component.css']
})
export class ExtTransferDetComponent implements OnInit {

  
  fileName= 'ext_transfer_detail.xlsx';

  pageLoadingImg: boolean;
  up: any;
  userType: any;
  salesDetSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  total_amount: any;
  total_discount_amount: any;
  pgend: boolean;
  pgstart: boolean;
  filter_branch_id: number;
  branch_all: any;
  sunDrcr = []; 
  users: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  sortType = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  customer: any;
  total_res: any;
  period_type: string;
  repDate: any;
  exportLoader: boolean;
  ExpsalesDetSum: any;
  Exptotal_res: any;
  ExprepDate: any;
  godowns: any;
  vans: any;
  country_dec: string;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getAllSundryCrDrLedgers();
    this.country_dec=this.coreService.setDecimalLength();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
      //  this.filter_branch_id = 1;
    this.getAllBranch();
 
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("period_type", 't');

    this.apiService.extTranDet(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.salesDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;

      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  this.searchUser('');


  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }
  ngAfterContentInit(){
    
    this.searchVans('');
    this.getGodownList();

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    this.vans.push({ "van_id": 'All', 'van_name': 'All' });
    });

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }

  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';
    this.salesDetSum = [];
    this.apiService.extTranDet(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.salesDetSum = res.data.data;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.total_res =  res.data.totalRes;
      this.repDate = res.data.Date;

 

      
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  getAllSundryCrDrLedgers() {
    this.apiService.getAllSundryCrDrLedgers().subscribe((res) => {
      this.sunDrcr = res['data'];
    });
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      // if(this.userType=='ADMIN')
      // {
      //   this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      // }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

  searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.users = res['data'];                  
        });
 }

 searchCustomer(search: string) {
  const searchval = new FormData();
  searchval.append('cust_name', search);
  this.apiService.searchCustomer(searchval).subscribe((res) => {
    this.customer = res['data'];
  });

}

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.extTranDet(expformdata.value, 1).subscribe((res: any) => {

      this.ExpsalesDetSum = res.data.data;
      
      this.Exptotal_res =  res.data.totalRes;
      this.ExprepDate = res.data.Date;

 
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);


    });



    }

    // pdf download
    generatePdf(expformdata: { value: any; }) {

      this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      

    this.apiService.extTranDet(expformdata.value, 1).subscribe((res: any) => {

      this.ExpsalesDetSum = res.data.data;
      
      this.Exptotal_res =  res.data.totalRes;
      this.ExprepDate = res.data.Date;

 
      const heads = [
        this.translate.instant('HOME.Invoice_no'),
        this.translate.instant('Common.date'),
        this.translate.instant('Table.description'),
        this.translate.instant('Table.rate'),
        this.translate.instant('Table.qty'),
        this.translate.instant('Common.Total_Amount')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        this.ExpsalesDetSum.forEach(item=>{
          data.push([
            item.extstktr_id + '[' + item.extstktr_branch_ref_no + ']',
            this.datePipe.transform(item.extstktr_date, 'dd/MM/yyyy'),
            item.ledger_name,
            '',
            '',
            ''
          ])

          item.items.forEach(item1=>{
            data.push([
              '',
              item1.ean,
              item1.prd_name,
              (item1.extstktrsub_rate).toFixed(this.country_dec),
              this.currencyPipe.transform(item1.extstktrsub_qty, '', '', '1.1-1'),
              (item1.sale_total).toFixed(this.country_dec)
            ])
          })

          data.push([
            '',
            '',
            this.translate.instant('HOME.total'),
            '',
            this.currencyPipe.transform(item.totqty, '', '', '1.1-1'),
            (item.totamt).toFixed(this.country_dec)
          ])
        })
    
        let doc = this.excelService.getPdfObj();

        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('transaction.ext_transfer_det')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_res.inv_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_trans_qty')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_res.tot_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
    
       
        doc.save(`External Transfer Details.pdf`);

        this.exportLoader = false;

    });

    }

}
