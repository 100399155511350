import { Component, OnInit, ViewChild,Input,ElementRef, HostListener } from '@angular/core';
import { MatHorizontalStepper, MatStepper } from '@angular/material/stepper';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { DataTransferService } from '../../../../../service/data-transfer.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as html2pdf from "html2pdf.js";
import { environment } from '../../../../../../environments/environment';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { formatDate } from '@angular/common';
// import { QRCodeModule } from 'angularx-qrcode';
@Component({
  selector: 'app-sales-invoice',
  templateUrl: './sales-invoice.component.html',
  styleUrls: ['./sales-invoice.component.css']
})



export class SalesInvoiceComponent implements OnInit {

  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  @ViewChild('selectQty', { static: false }) selectQty: ElementRef;
  @ViewChild('selctbarcode', { static: false }) selctbarcode: ElementRef;
  @ViewChild('selctRecAmnt', { static: false }) selctRecAmnt: ElementRef;
  @ViewChild('cashamnt', { static: false }) cashamnt: ElementRef;
  @ViewChild('step1', { static: false }) formStep1: NgForm;
  @ViewChild('stepper', { static: false }) mainStepper: MatStepper;
  @ViewChild('firstnextButton', { static: false }) firstnextButton: ElementRef;
  @ViewChild('secndnextButton', { static: false }) secndnextButton: ElementRef;
  @ViewChild('secndPayButton', { static: false }) secndPayButton: ElementRef;
  @ViewChild('prdSelecter', { static: false }) myselect: NgSelectComponent;
  @ViewChild('closeModalNew', { static: false }) closeModalNew: ElementRef;
 
  // @ViewChild('stepper',{ static: false }) stepper: MatHorizontalStepper;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    minHeight: '350px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  no_transfr: string='';
  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id: 0,
    usr_type: 0,
    is_supplier: false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  resultobj: any = {};
  isSubmitInProg = false;
  userType: any;
  terms = '';
  doneMessage=false;
  additional_charges:any=[];
  additional_total_amount = 0;

  godown_all: any;
  stkprd_all: any;
  packingListSub:any=[]
  packingList:any=[]

  hide_print_header_footer: any;
  hide_print_header_footer_value = [
    { label: 'Show', value: true },
    { label: 'Hide', value: false }
  ];

  parents_products = [
    { id: 0, parent_product: 'None',prd_id :0 },
   
  ];

  service_additional_charges: any[];

  cust_types = [
    { id: 1, name: 'New', disabled: true },
    { id: 2, name: 'Registered' }
  ];
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];

  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' },
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];

  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: 'Percentage' }
  ];
  minDate:Date;
  customer: any;
  cust_type: any;
  inv_type: any;
  print_style: any;
  disable_sales_without_stock: any;
  enable_sale_below_mrp:any
  rate_org:any;
  sales_print_name: any;
  cust_code: any;
  cust_enable_sale_below_mrp: any=1;
  show_base_unit_in_print:any;
  cust_addr: any;
  cntct_num: any;
  reference: any;
  commAll = 2;
  vat_no: any;
  price_group_id: any = 1;
  godownStocks: any;
  branchStocks: any;
  selctedProd: any;
  excl_vat: number;
  item_disc_type: any;
  taxProdCategories: any;
  taxpercentage: any;
  rate: any;
  prd_tax_cat_id: any;
  unit: any;
  selectedProdDetails: any;
  qty: any;
  itemDisc: any;
  purchAmnt: any;
  tax_amnt: any;
  taxprice: number;
  grnd_totl: any;
  disc_amnt: any;
  itemTotal: any;
  printError: any;
  gd_id: any;
  stkprdsel: any;
  base_qty: any;
  unit_name: any;
  salesItems = [];
  item_dicrptn: any;
  resDup: any;
  taxvaltotqty: number;
  totExcsAmnt= 0 ;
  totItemprice = 0;
  totItemDisc = 0;
  totVatAmnt = 0;
  net_disc = 0;
  lastInvoice: any;
  cust_id: any;
  barcode: string;
  cust_name: string;
  saleqt_date: any;
  selectError: boolean;
  qt_no: string | Blob;
  saleRefNo: any;
  cmpny: any;
  checkBatch =false;
  checkParentProduct =false;

  headerheightInmm = 34.4;
  footerheightInmm = 31.75;
  totalAmntDivInmm = 30;

  pagelabelBottomInmm = 26.46; 
  pagelabelLeftInmm = 92.60;
  dispalyPageNo = true;
  pageLabel = "Page [page_no] of [page_total_no]";
  stylecssheaderfooter : any;

  savedTemplate = {
    title_text: 'TAX INVOICE  فاتورة ضريبية فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    prnt_sign_section_display:0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  water_mark : string;
  hide_item_discount: any;
  hide_vat: any;
  hide_srvc_type_qty_rate: any;
  hide_prt_salesman:any;
  saleData: any;
  itemRate: number;

  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  purch_type_taxcat_id: any;
  taxListCategories: any;
  purchtypesel: any;
  zeroType: boolean;
  godownStocksTotal = 0;
  branchStocksTotal = 0;
  lastCust = 0;
  draftLoader: boolean;
  draftList: any;
  due_date: any;
  sale_agent: any;
  sqdrf_id: any;
  listing: boolean;
  list_sales: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  from: any;

  log_pgend: boolean;
  log_pgstart: boolean;
  log_curpage: any;
  log_lastpage: any;
  log_from: any;
  
  importLoader: boolean;
  imporDrafttLoader: boolean;
  sq_inv_no: any;
  isEdit: boolean;
  sl_no: any;
  saleQtnData: any;
  previewLoader: boolean;
  sales_note: any;
  sales_note2: any;
  sales_note3: any;
  parent_product:any;

  salesPayType = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank / Card' },
    { id: 4, name: 'Both' },
  ];
  payTypes = ['Cash', 'Credit', 'Bank / Card','Both'];
  defLedger: any;
  sale_pay_type: number;
  sale_acc_ledger_id: string;
  delivry_addr: any;
  valid_till_date: any;
  inv_amnt: any;
  recivd_amnt: any;
  bal_amnt: number;
  cash_amnt: number;
  card_amnt: number;
  sales_agents: any;
  is_description: any[];
  is_prew_description: any[];
  is_prew_item_disc: any[];
  pageLoadingImg: boolean;
  editLogpreviewLoader: boolean;
  saleEditLog: any[];
  saleEditLogLoaded : boolean=false;
  allEditLog: any;
  editMessage: boolean;
  editAdditionalServ: boolean=false;
  private modalRef: NgbModalRef;
  closeResult: string;
  noCustomer: boolean;
  edit_sales_inv_no_from_ds: any;
  qr_inv: any;
  sales_settings: any;
  userdefltGwn: any;
  prodUnitLoading = false;
  validationMsg = '';

  cust_balance:any = 0;
  cust_bal_sign: '';
  up: any;
  showSqmCalc = false;
  sqmCalc = [
    {
      height: 0,
      width: 0,
      qty: 1,
      sqm: 0
    }
  ]
  readings = [];
  ttlSqm = 0;
  showFileImport = false;
  fileImpBtnShow = false;
  fileImpErr = '';
  fileImpWar = '';
  fileImpinfo: any;
  srch_count: any;
  search_type: any;
  // lang = sessionStorage.getItem("baseLang");
  qtn_id: any;
  del_chellan_inv_no: any;
  showQtnImpLoader = false;
  searchLoadingImg = false;
  logLoadingImg = false;
  base_url: string;
  whatsapplink: any;
  print_types = [
    { id: 1, name: 'General' },
    { id: 2, name: 'Template1' },
    { id: 24, name: 'Template 1 With Fixed Header Footer'},
    { id: 3, name: 'Thermal' },
    { id: 5, name: 'Without Header & Footer' },
    { id: 6, name: 'With Header & Footer' },
    { id: 9, name: 'With fixed Header & Footer' },
    { id: 10, name: 'Template Four'},
    { id: 11, name: 'Template Zatca'},
    { id: 22, name: 'Pre-print Template1'},
    { id: 25, name: 'Pre-print Template2'},
    { id: 12, name: 'Dot Matrix'},
    { id: 13, name: 'Without Header & Footer Template 2'},
    { id: 14, name: 'With Header & Footer Template 2'},
    { id: 21, name: 'With Header & Footer Template 2 Without VAT'},
    { id: 15, name: 'With Header & Footer Template 3'},
    { id: 17, name: 'Without Header & Footer Template 3'},
    { id: 18, name: 'Template 5'},
    { id: 19, name: 'With Header & Footer Template 4'},
    // { id: 1000, name: 'Tax E-invoice'},
    // { id: 1001, name: 'Simplified Tax E-invoice - General'},
    // { id: 1002, name: 'Simplified Tax E-invoice - Thermal'},
    { id: 20, name: 'Template 6'},
    { id: 23, name: 'Template 7'},
    { id: 28, name: 'Logistic Template'},
    { id: 29, name: 'Template 8'},
    // { id: 26, name: 'Zetca Template 1'},
  ];
  printer_style: any;
  usr_hide_purch_cost: any;
  available_stock: any;
  batch_available_stock: any;
  unit_available_stock: any;
  unit_bs_prate :any;
  bs_prate :any;

  del_chellan_inv_number: any;
  challan: any;
  mul_del_chellan_inv_number: any;
  rate_histry: any;
  product_name: any;
  noPage = [];
  downloadingIndex=-1;
  lastpageIteemCount = 0;
  noRowPerPageInv = 14;
  noRowPerPage = 12;
  cffnoRowPerPage = 12;
  ttlPage = 1;
  data = [];
  dflt_search_methd: any;
  matindex: any;
  show_prd_code: any;
  sales_sub_description: any;
  prdcts= <any>[{}];
  srch_length: any;
  only_brcd: any;
  agent_check: any;
  hist_prd_id: any;
  his_cus_name: string;
  desktoken: any;
  branch_name: any;
  branch_id: any;
  country_dec: number=2;
  rate_type = [
    { id: 1, name: 'Rate1' },
    { id: 2, name: 'Rate2' },
  ];
  sel_rate_type = 1;
  is_sale_edit = false;

  inv_limit: any;
  inv_limit_no: any;
  current_dues: any;
  inv_due_msg: boolean;
  isSecondOptionDisabled: boolean = true;
  display_qty: any;
  prd_location: any;
  usr_hide_others_sales_data: any;
  user_id: any;
  show_default_unit_in_barcode_search:any;
  sales_serials = [];
  serialsErrors = <any>{};
  enable_serial_no : boolean = false;
  sale_vat_type: any;
  sales_is_del_chellan = 0;
  promo_disc = 0;
  promo_per = 0;
  promo: any;
  // lang = sessionStorage.getItem("baseLang");
  totPromoDisc = 0;
  isOffer = true;
  crNoColor: string;
  defLedgerCash: any;
  defLedgerBank: any;
  sale_acc_ledger_id_bank: any;
  sale_acc_ledger_id_cash: any;
  balance: any;
  balancErr: boolean=false;
  cnvert_ref_no: any;
  cntctPerson:any;
  multipleCurrency: boolean=false;
  cust_default_currency_id: any;
  currencies: any;
  exchange_rate = 1;
  selcurrency: any;
  rate_currency: any;
  usr_disply_others_branch_data: any;
  cashaAmValidation= false ;
  cardaAmValidation= false ;
  usr_block_pre_date_select: any;
  cust_bal: any;
  cust_credit_limit: any=0;
  avg_prate: any;
  unit_avg_prate: number;
  selctedBatchStock = 0;
  increase_repeated_item: any;
  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  checkIsExciseEnble: boolean;
  excstaxCategories: any;
  excs_tax_cat_id: any;
  excs_tax_type: any;
  excs_tax_amnt: any;
  excstaxpercentage: any;
 
  invoiceId: any;
  invoice_type: any;
  isExcsEdit: any=false;
  shop_qty: any;
  van_qty: any;
  gd_qty: any;
  is_addon: any;
  is_parent_Prod  :any;
  addon_prd_id: any;
  parents: any[];
  addon_prd: any;
  as_child: any;
  edit_as_child: any;
  batchs: any;
  batch_code: any;
  servicetypes: any;
  ServiceAdditionaltotalAmount: any;
  edit_service_additional_charges: any;
  branch_img_url: any;
  branchLogo: string;
  baseUrl: string;
  enable_sale_below_purch: any;
  g_settings: any;
  generalSearch_type: any;
  headerSalesEinvoiceLink: any;
  hide_prnt_alias: any;
  hide_prnt_time: any;
  hide_prnt_cust_bal: any;
  hide_prnt_pay_type: any;
  srchmsg: any;
  readerCalc: boolean;
  cls_rd: any;
  op_rd: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  converts_type = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Delivered' },
    { id: 2, name: 'Not Delivered' }
  ];
  convert_type:any;
  period_type:any='';
  users: any;
  publicPath = '';
  tableSettings = {
    ref_no: true,
    inv_no: true,
    date: true,
    cus_name: true,
    paid_sts: true,
    amnt: true,
    balnc_amnt: true,
    e_invoice: true,
  };
  disp_col = [
    { id: "ref_no", name: "Ref No", show: true },
    { id: "inv_no", name: "Invoice No", show: true },
    { id: "date", name: "Date", show: true },
    { id: "cus_name", name: "Customer Name", show: true },
    { id: "sale_agent", name: "Sales Agent Name", show: false },
    { id: "pay_typ", name: "Pay Type", show: false },
    { id: "paid_sts", name: "Paid Status", show: true },
    { id: "amnt", name: "Amount", show: true },
    { id: "balnc_amnt", name: "Balance Amount", show: true },
    { id: "note", name: "Note", show: false },
    { id: "location", name: "Location", show: false },
    { id: "delivery_sts", name: "Delivery Status", show: false },
    { id: "convrt_no", name: "Convert No", show: false },
    { id: "e_invoice", name: "E-invoice", show: true },
  ];
  ledgpop: any;
  chngecheck: number;
  hide_prt_eanbarcode: any;
  hide_prnt_preview_h_f_show: any;
  customercode: any;
  filter_paytype = [
    { id: 1, name: 'Credit' },
    { id: 4, name: 'Cash' },
    { id: 2, name: 'Bank' },
    { id: 3, name: 'Multi' }

  ];
  pay_type: string | Blob;
  selected_tax_type: any;
  single_weight: any;
  weight: any;
  emptyRow: any = 0;
  
  loadCustomerPopup = false;
  loadSalesDueRecpPopup = false;
  loadNewProductPopup = false;
  prdWeightEnable: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal, private dataTransferService: DataTransferService, private ActiveRoute: ActivatedRoute,private translate: TranslateService) { }
  batches = [];
  selBatch:any;
  totalBatch =0;
  stock_qty:any;
  batchLoading = false;
  manufacture_date:any;
  expiry_date:any;
  

  roundoff_sign = [
    { value: 1, name: '+' },
    { value: -1, name: '-' }

  ];
  round_sign = 1;
  roundoff = 0;
 cur_lang: string;

  
  custSearchLaoding = false;
  branch_e_invoice_enabled = 0;
  einv_simplfied_print_style = 1;
  print_style_tmp: any;
  ngOnInit() {
    // this.hide_print_header_footer = '0';
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.branch_img_url = this.coreService.getUserData('img_url');
    this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    
      
      this.aliasChecked()
    })
    this.getAdditonalCharge();
    this.apiService.getAllTaxCategory().subscribe((resp) => {

      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.apiService.getAllClientSettings().subscribe((res) => {
      if (res['data']) {
        this.prdWeightEnable = (res['data']['enable_product_weight']['cs_value']) ? true : false;
        this.checkBatch = (res['data']['Batch Support']['cs_value']) ? true : false;
        this.readerCalc = (res['data']['reader_calculator']['cs_value']) ? true : false;
        this.multipleCurrency = (res['data']['multiple_currency_support']['cs_value']) ? true : false;
        this.checkParentProduct = (res['data']['parent_product_enable']['cs_value']) ? true : false;
        this.checkIsExciseEnble = res["data"]['excise_duty_enable']["cs_value"] ? true : false;
        if(this.checkIsExciseEnble){
          let searchval=new FormData;
          this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
            this.excstaxCategories = res.data;
          });
        }

        this.eInvoiceSupport = ( (res['data']['enable_e_invoice']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
        if(!this.eInvoiceSupport){
          this.inv_types.push({ id: 3, name:  this._invoice });
        }

        // show only when e-invoice enabled
        if (this.eInvoiceSupport) {
          this.print_types.push(
            { id: 1000, name: 'Tax E-invoice'},
            { id: 1001, name: 'Simplified Tax E-invoice - General'},
            { id: 1002, name: 'Simplified Tax E-invoice - Thermal'},
            { id: 26, name: 'Zetca Template 1'},
            { id: 27, name: 'Zetca Template 2'})
          
        }

        this.showSqmCalc = (res['data']['show_sales_sqm_calc']['cs_value']) ? true : false;
        this.showFileImport = (res['data']['show_sales_file_import']['cs_value']) ? true : false;

      }

      if (this.headerSalesEinvoiceLink &&this.headerSalesEinvoiceLink==1){
        this.listing = true
        this.invoice_type=2
        this.listQtn(1);
      }else{
        this.invoice_type=3;
        this.listQtn(1);
      }

    });
    // this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
    //   if (res['data']) {
    //     this.checkBatch = (res['data']['cs_value']) ? true : false;
    //   }
    // });
    // this.apiService.getClientSettingBykey({ key: 'reader_calculator' }).subscribe((res) => {
    //   if (res['data']) {
    //     this.readerCalc = (res['data']['cs_value']) ? true : false;
    //   }
    // });
    // this.apiService.getClientSettingBykey({ key: 'multiple_currency_support' }).subscribe((res) => {
    //   if (res['data']) {
    //     this.multipleCurrency = (res['data']['cs_value']) ? true : false;
    //   }
    // });

    // this.apiService.getClientSettingBykey({ key: 'parent_product_enable' }).subscribe((res) => {
    //   if (res['data']) {
    //     this.checkParentProduct = (res['data']['cs_value']) ? true : false;
    //   }
    // });

    // this.apiService.getClientSettingBykey({ key: "excise_duty_enable" }).subscribe((res) => {
    //   if (res["data"]) {
    //     this.checkIsExciseEnble = res["data"]["cs_value"] ? true : false;
        
    //     let searchval=new FormData;
    //     this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
    //       this.excstaxCategories = res.data;
    //     });

    //   }
    // });
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getTemplete();
    this.updateSalesTableSettigns();
    this.readings=[];
    this.matindex==0
    this.period_type='';
    this.convert_type=0;
    this.searchUser('');
    this.additional_total_amount=0;
    this.base_url = this.apiService.PHP_API_SERVER;
    this.headerSalesEinvoiceLink = this.ActiveRoute.snapshot.paramMap.get('einvoice_pending'); 

    this.qtn_id = this.ActiveRoute.snapshot.paramMap.get('qtn_id');
    this.del_chellan_inv_no = this.ActiveRoute.snapshot.paramMap.get('del_chellan_inv_no');

    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.usr_disply_others_branch_data = this.coreService.getUserData('usr_disply_othr_branch_data');
    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    // this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
    //   if (res['data']) {
    //     this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
    //     this.inv_types = [
    //       { id: 1, name: this._tax_invoice},
    //       { id: 2, name: this._simplified_tax_invoice},
    //     ];
    //     if(!this.eInvoiceSupport){
    //       this.inv_types.push({ id: 3, name:  this._invoice });
    //     }

    //     // show only when e-invoice enabled
    //     if (this.eInvoiceSupport) {
    //       this.print_types.push(
    //         { id: 1000, name: 'Tax E-invoice'},
    //         { id: 1001, name: 'Simplified Tax E-invoice - General'},
    //         { id: 1002, name: 'Simplified Tax E-invoice - Thermal'},
    //         { id: 26, name: 'Zetca Template 1'},
    //         { id: 27, name: 'Zetca Template 2'})
    //     }
    //   }
    // if (this.headerSalesEinvoiceLink &&this.headerSalesEinvoiceLink==1){
    //   this.listing = true
    //   this.invoice_type=2
    //   this.listQtn(1);
    // }else{
    //   this.invoice_type=3;
    //   this.listQtn(1);
    // }


    // });
    this.user_id = this.coreService.getUserData('usr_id');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }
    this.country_dec=this.coreService.setDecimalLength();
      console.log(this.country_dec);
    

this.ServiceAdditionaltotalAmount=0;
    this.up = this.coreService.getUserPrivilage();
    // this.checkDisplaySalesImport(); 
    this.getGodownList();
    this.nextRefNo();
    this.cust_type = 2;
    this.inv_type = 1;
    this.print_style = 1;
    this.printer_style = 1;
    this.search_type = 1;
    this.disable_sales_without_stock = 0;
    this.show_base_unit_in_print = 0;
    this.sales_print_name = 1;
    this.purchtypesel = 1;
    this.getSalesSetting();
    this.excl_vat = this.sale_vat_type?2:1;
    this.item_disc_type = 1;
    this.selectError = false;
    this.listing = false;
    this.importLoader = false;
    this.imporDrafttLoader = false;
    this.isEdit = false;
    // this.checkDisplaySQMCalc();
    this.disc_amnt = 0;
   

    this.apiService.getAllTaxCategory().subscribe((resp) => {

      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    this.xmlPath = this.apiService.PHP_API_SERVER + this.publicPath + '/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    if (this.cmpny == 'FGAT') {
      this.print_types.push({ id: 8, name: 'Falcon Multipage' });
      this.noRowPerPage = 11;
    }
    if (this.cmpny == 'AMST') {
      this.noRowPerPage = 10;
    }
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.gd_id = this.userdefltGwn ? Number(this.userdefltGwn) : 0;
    // this.gd_id = 0;

    
    this.getDraftList();


    
   
    this.saleqt_date = new Date();
    this.is_description = [];
    this.is_prew_description = [];
    this.is_prew_item_disc = [];
    this.item_dicrptn = '';
    this.editMessage = false;
    this.userType = this.coreService.getUserData('user_type');
    this.branch_name = this.coreService.getUserBranch();
    this.branch_id = this.coreService.getUserBranchId();
    this.edit_sales_inv_no_from_ds = this.dataTransferService.edit_sales_id;
    if (this.edit_sales_inv_no_from_ds) {
      this.getEditData(this.edit_sales_inv_no_from_ds);
      this.dataTransferService.edit_sales_id = '';
    } else {
      this.dataTransferService.edit_sales_id = '';
    }
    this.getAllAgents();
    // if (this.headerSalesEinvoiceLink==1){
    //   this.listing = true
    //   this.invoice_type=2
    //   this.Select_invoiceType(this.invoice_type);
    // }

    if (this.del_chellan_inv_no && !isNaN(this.del_chellan_inv_no)) {
      this.showQtnImpLoader = true;
     
      let searchval = new FormData();
      searchval.append("del_chellan_inv_no", this.del_chellan_inv_no);
      this.apiService.getDelChallanDet(searchval).subscribe((res: any) => {
       
        // this.salesItems = res.data.delivery_challan_sub;

        // if (res.data.del_chellan_date) {
        //   this.valid_till_date = new Date(res.data.del_chellan_date);
        // } else {
        //   this.valid_till_date = '';
        // }
        // this.saleqt_date = new Date(res.data.del_chellan_date);
        this.valid_till_date = '';
        this.saleqt_date = new Date();
        // this.saleqt_date = new Date(res.data.sq_date);
        // this.cust_type = res.data.del_chellan_cust_type;
        this.cust_code = res.data.del_chellan_cust_code;
        this.cust_name = res.data.del_chellan_cust_name;
        this.cust_id = res.data.customer;
        this.cust_addr = res.data.del_chellan_cust_address;
        this.cntct_num = res.data.del_chellan_cust_ph;
        this.cntctPerson = res.data.del_chellan_contct_prsn;
        this.vat_no = res.data.del_chellan_cust_tin;
        this.net_disc = res.data.del_chellan_discount;
        this.round_sign = 1;
        this.roundoff = 0;
        this.sales_note = res.data.del_chellan_notes;
        this.sales_note2 = res.data.del_chellan_notes2;
        this.sales_note3 = res.data.del_chellan_notes3;
        // this.terms = res.data.del_chellan_terms;
        this.inv_type = this.sales_settings.ss_inv_type;
        this.cust_type = 2;
        this.gd_id =res.data.godown_id;
        this.del_chellan_inv_number = res.data.del_chellan_inv_no;
        this.cnvert_ref_no = res.data.del_chellan_branch_inv;
        this.reference = res.data.del_chellan_reference;
        this.sale_agent = res.data.del_chellan_agent_ledger_id;

        res.data.delivery_challan_sub.forEach((element, index) => {

          let tmp = <any>{};
          tmp.salesub_prd_id = element.del_chellan_sub_prd_id;
          tmp.sl_no = element.sl_no;
          tmp.prd_name = element.prd_name;
          tmp.ean = element.ean;
          tmp.salesub_stock_id = element.del_chellan_sub_stock_id;
          tmp.salesub_qty = element.del_chellan_sub_qty;
          tmp.excs_tax_amnt = element.excs_tax_amnt;
          tmp.salesub_rate = element.del_chellan_sub_rate;
          tmp.salesub_amnt = element.del_chellan_sub_rate * element.del_chellan_sub_qty;
          tmp.salesub_tax_amnt = element.del_chellan_sub_tax_amnt;
          tmp.salesub_tax = element.del_chellan_sub_tax;
          tmp.salesub_tax_per = element.del_chellan_sub_tax_per;
          tmp.salesub_price = element.del_chellan_sub_price;
          tmp.purchpricesingle = element.del_chellan_sub_rate / element.base_qty;
          tmp.salesub_gd_id = element.del_chellan_sub_gd_id;
          tmp.taxvalperqty = element.del_chellan_sub_tax / element.base_qty;
          tmp.base_qty = element.base_qty;
          tmp.prd_unit_name = element.prd_unit_name;
          tmp.salesub_unit_id = element.del_chellan_sub_unit_id;
          tmp.prd_barcode = element.prd_barcode;
          tmp.prd_tax_cat_id = element.prd_tax_cat_id;
          tmp.item_desc = element.item_desc;
          tmp.item_disc = element.item_disc;
          tmp.prd_type = element.prd_type;
          tmp.vat_type = element.vat_type;
          tmp.item_disc_type = element.item_disc_type;
          tmp.sale_total_amount = element.del_chellan_sub_rate * element.del_chellan_sub_qty + element.del_chellan_sub_tax_amnt - element.item_disc;
          this.salesItems.push(tmp);
        });

        // // this.sale_agent = res.data.sqdrf_sales_agent;
        this.sumSaleTotal();
        // this.importLoader = false;

        // this.listing = false;

        // this.is_description = this.salesItems.filter(x => x.item_desc !== '');
        this.showQtnImpLoader = false;




      });
    }
    if (this.qtn_id && !isNaN(this.qtn_id)) {
      this.showQtnImpLoader = true;
      // console.log('qtn_id ' + this.qtn_id);

      let searchval = new FormData();
      searchval.append("sq_inv_no", this.qtn_id);
      this.apiService.getSalesQtn(searchval).subscribe((res: any) => {
        this.salesItems = res.data.sales_qtn_sub;

        // if (res.data.sq_valid_till) {
        //   this.valid_till_date = new Date(res.data.sq_valid_till);
        // } else {
        //   this.valid_till_date = '';
        // }
        this.valid_till_date = '';
        this.saleqt_date = new Date();
        // this.saleqt_date = new Date(res.data.sq_date);
        this.cust_type = res.data.sq_cust_type;
        this.cust_code = res.data.sq_cust_code;
        this.cust_name = res.data.sq_cust_name;
        this.cust_id = res.data.customer;
        this.cust_addr = res.data.sq_cust_address;
        this.cntct_num = res.data.sq_cust_phone;
        this.cntctPerson = res.data.sq_contct_prsn;
        this.vat_no = res.data.sq_cust_tin;
        this.net_disc = res.data.sq_disc;
        this.round_sign = 1;
        this.roundoff = 0;
        this.sales_note = res.data.sq_notes;
        this.sales_note3 = res.data.sq_notes2;
        // this.terms = res.data.sq_terms;
        this.cnvert_ref_no=res.data.sq_branch_qt_no;
        this.sale_agent = res.data.sq_sales_agent;
        this.reference = res.data.sq_reference;
        // this.sales_serials=[];
        this.sumSaleTotal();
        
        // this.importLoader = false;

        // this.listing = false;

        // this.is_description = this.salesItems.filter(x => x.item_desc !== '');
        this.showQtnImpLoader = false;




      });
    }

    this.searchDeliverychellan('');
    this.searchCustomer('');
    this.searchStkPrd('');

    this.parent_product ={ id: 0, parent_product: 'None',prd_id :0 };

    
  }


  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.commAll =1;
     
    }else{
      this.commAll =2;
    
    }
    
  }
  prdLngchng(id){
    // this.commAll =id;
  }
  createRange(number, start = 0) {
    let noPage = [];
    for (var i = start; i < number; i++) {
      noPage.push(i);
    }
    return noPage;
  }
  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => { 
      if (res['data']) {
        this.sales_settings = res['data'];
        // for e-invoice must show branch ref no. 
        this.sales_settings.show_bill_no = (this.branch_e_invoice_enabled) ? 1 : this.sales_settings.show_bill_no;
        this.inv_type = this.sales_settings.ss_inv_type;
        this.print_style = this.sales_settings.print_style;
        this.printer_style = this.sales_settings.print_style;
        this.print_style_tmp = this.sales_settings.print_style;
        this.einv_simplfied_print_style = this.sales_settings.einv_simplfied_print_style;
        this.terms = this.sales_settings.sales_default_terms;
        this.search_type = this.sales_settings.search_type;
        this.dflt_search_methd = this.sales_settings.dflt_search_methd;
        this.show_prd_code = this.sales_settings.show_prd_code;
        this.only_brcd = this.sales_settings.search_with_only_barcod;
        this.sales_sub_description = this.sales_settings.show_sales_sub_des;
        this.hide_srvc_type_qty_rate = this.sales_settings.ss_hide_service_qty_rate;
        this.hide_prt_salesman = this.sales_settings.ss_hide_prt_salesman;
        this.hide_prt_eanbarcode = this.sales_settings.ss_hide_prt_eanbarcode;
        this.increase_repeated_item = this.sales_settings.qtn_repeated_item;
        
        console.log(this.sales_settings.show_prd_code);
        this.inv_limit=this.sales_settings.inv_due_limit;
        if (this.inv_type == 3) {
          this.purchtypesel = 2;
          this.prd_tax_cat_id = 3;
          this.selectTaxType(2);
        }
        this.disable_sales_without_stock = this.sales_settings.disable_sales_without_stock;
        this.show_base_unit_in_print = this.sales_settings.show_base_unit_in_print;
        
        this.enable_sale_below_mrp = this.sales_settings.enable_sale_below_mrp;
        this.enable_sale_below_purch = this.sales_settings.enable_sale_below_purch;
        this.sales_print_name = this.sales_settings.sales_print_name;
        this.purchtypesel = this.sales_settings.tax_type;
        this.show_default_unit_in_barcode_search = this.sales_settings.show_default_unit_in_barcode_search;
        this.sale_vat_type=this.sales_settings.vat_type;

         

        // font color for template 1
        if(this.sales_settings.text_color){
          this.crNoColor='black';
        }else{
          this.crNoColor='green';
        }

      } else {
        this.disable_sales_without_stock = 0;
        this.show_base_unit_in_print = 0;
        this.enable_sale_below_mrp = 0;
        // this.enable_sale_below_purch =0;
        this.inv_type = 1;
        this.sales_print_name = 1;
        this.print_style = 1;
        this.printer_style = 1;
        this.terms = '';
        this.search_type = 1;
        this.show_default_unit_in_barcode_search = 0;
      }

      // payment option by settings
      this.cust_type = 2;
      if(this.sales_settings.ss_default_pay_type==1){
        this.sale_pay_type=1;
        this.cust_type = 2;

       }else if(this.sales_settings.ss_default_pay_type==2){
        this.sale_pay_type=3;
       }else if(this.sales_settings.ss_default_pay_type==3){
        this.sale_pay_type=4;
       }else{
        this.sale_pay_type=2;
        this.cust_type = 1;
        
       }

      // style for print
      if(this.print_style == 13 || this.print_style==14){
        this.stylecssheaderfooter = "assets/fixed_print.css?v=3";

      }else{
        this.stylecssheaderfooter = "assets/falcon_print.css?v=3";
        
      }

      this.updatePayment();
    });

  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.taxpercentage = selTax[0].taxcat_tax_per;
    this.calcRates();

  }
  updateExcsTaxPerc(taxCatId) {
   
    
    if(taxCatId){
      const selTax = this.excstaxCategories.filter((taxCat) => taxCat.excs_cat_id === taxCatId);
      this.excstaxpercentage = selTax[0].excs_tax_percent;
      this.excs_tax_cat_id=taxCatId;
    }else{
      this.excstaxpercentage=0;
      this.excs_tax_cat_id=0;
    }
   
   
   
    
    this.calcRates();

  }

  getAdditonalCharge() {
    this.apiService.getAllServiceTypesDatas().subscribe((res) => {
      this.servicetypes = res.data;
    });

  }
  additionalServiceCharges(data){
//this.doneMessage=false;
    this.service_additional_charges = data;
    this.ServiceAdditionaltotalAmount = data.ServiceAdditionaltotalAmount;
    
  }
     

  showList(val) {
    this.balancErr =false;
    if (val) {
      this.listing = false; // Invoice Page
      this.is_sale_edit = false;
      // this.getAdditonalCharge();
      setTimeout(() => {
        this.newInvoice(this.listing);
      }, 500);

    } else {
      this.listing = true; // Listing Page
      this.is_sale_edit = true;
    }


  }
  ledgerpop(value:number){
    this.loadSalesDueRecpPopup = true;
    this.ledgpop=value;
    this.chngecheck=Math.floor(Math.random() * 101);;
    console.log('this.chngecheck',this.chngecheck);
  
    
  }
  listUpdated(){
    // console.log('customer created parent')
    this.listQtn()
    // this.list_sales;
  }

  pageNext(formdata: { value: any; }) {

     console.log(formdata.value);
     

      formdata.value.invoiceType_id=this.invoiceId;
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      }
      this.searchLoadingImg = true;
      this.apiService.getSalesInvList(formdata.value, 1).subscribe((res) => {
        this.list_sales = res.data.data;
        this.agent_check = res.agent_check;
        this.curpage = res.data['current_page'];
        this.lastpage = res.data['last_page'];
        this.from = res.data['from'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        this.searchLoadingImg = false;
      });

  }

  diffqty(op_rd=0,cls_rd=0){
   

    if(Number(cls_rd)&&Number(op_rd)){
      this.qty=Number(cls_rd)-Number(op_rd);
    }
    this.calcRates();
    this.closeModalNew.nativeElement.click();
  }

  getEditData(s_inv_no) {

    // if (data.sales_due_sub.length <= 0) {

    let searchval = new FormData();
    searchval.append("sales_inv_no", s_inv_no);
    this.importLoader = true;
    this.apiService.getSalesDet(searchval).subscribe((res: any) => {
      
     
      this.saleRefNo = res.data.sales_inv_no;
      this.qt_no = res.data.sales_branch_inv;
      this.sq_inv_no = res.data.sales_inv_no;
      this.purchtypesel = res.data.sales_tax_type + 1;
      this.cnvert_ref_no = res.data.sales_cnvert_ref_no;
      this.salesItems = res.data.sales_sub;
      if (res.data.sales_due_date) {
        this.valid_till_date = new Date(res.data.sales_due_date);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sales_date);

      if (res.data.sales_cust_type == 0) {
        this.cust_type = 1;
      } else if (res.data.sales_cust_type == 1) {
        this.cust_type = 2;
      }

      if (res.data.sales_pay_type == 0) {
        this.sale_pay_type = 2;
      } else if (res.data.sales_pay_type == 1) {
        this.sale_pay_type = 1;
      } else if (res.data.sales_pay_type == 2) {
        this.sale_pay_type = 3;
      }

      if (res.data.sales_acc_ledger_id > 0) {
        this.defLedger = res.data.acc_ledger;
        this.sale_acc_ledger_id = res.data.sales_acc_ledger_id;
      }

      this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
      if(res.data.customer && res.data.customer.price_group_id){
        this.price_group_id = res.data.customer.price_group_id;
      }
      this.cust_enable_sale_below_mrp = (res.data.customer ? res.data.customer.enable_sale_below_mrp : 1);
      this.cust_name = res.data.sales_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sales_cust_address;
      this.cntct_num = res.data.sales_cust_ph;
      this.reference = res.data.sales_reference;
      this.vat_no = res.data.sales_cust_tin;
      this.net_disc = res.data.sales_discount;

      this.round_sign = (res.data.sales_roundoff && res.data.sales_roundoff< 0) ? -1 : 1;
      this.roundoff = Math.abs(res.data.sales_roundoff);

      this.sales_note = res.data.sales_notes;
      // this.sale_agent = res.data.sqdrf_sales_agent;
      this.sumSaleTotal();
      this.changeAmnt();

      this.importLoader = false;

      this.listing = false;
      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.editMessage = true;

    });
    // }
  }

  updateTotal() {

  }

  clicknext() {

    setTimeout(() => {
      if (this.dflt_search_methd) {
        this.selectproductId.focus();
      } else {
        this.selctbarcode.nativeElement.focus();
      }
    }, 500);
  }

  searchItem() {
    setTimeout(() => {
      this.selctbarcode.nativeElement.focus();
    }, 500);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goNext(stepper: MatStepper) {
    stepper.next();
  }

  newQtn(stepper: MatStepper, formdata, type) {
    this.getAdditonalCharge();
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.net_disc = 0;
    this.round_sign = 1;
    this.roundoff = 0;
    this.resetProdForm();
    formdata.reset();
    this.nextRefNo();
    this.readings=[];
    this.sqdrf_id = '';
    this.sq_inv_no = '';
    this.sales_note='';
    this.sales_note2='';
    this.sales_note3='';
    this.no_transfr='';
    this.editMessage = false;
    this.addOnProdProcess();
    this.service_additional_charges=[];
    this.ServiceAdditionaltotalAmount=0;
    this.additional_charges=[];
    this.additional_total_amount=0;
    this.doneMessage=false;
    this.editAdditionalServ = false;


    if (type == 'b')
      stepper.reset();

    this.saleqt_date = new Date();
    setTimeout(() => {
    
      // payment option by settings
      this.cust_type = 2;
      if(this.sales_settings.ss_default_pay_type==1){
        this.sale_pay_type=1;
        this.cust_type = 2;

       }else if(this.sales_settings.ss_default_pay_type==2){
        this.sale_pay_type=3;
       }else if(this.sales_settings.ss_default_pay_type==3){
        this.sale_pay_type=4;
       }else{
        this.sale_pay_type=2;
        this.cust_type = 1;
       }

      this.inv_type = this.sales_settings?this.sales_settings.ss_inv_type:1;

      if (this.inv_type == 2) {
        this.cust_type=1;
        if (this.sale_pay_type === 1){
          this.cust_type=2;
        }
          }else{
        this.cust_type=2;
          }
          
      this.inv_sub_type = '00000';
    }, 500);

 

  }

  newInvoice(listing) {
    this.getAdditonalCharge();
    this.doneMessage=false;
    this.editAdditionalServ = false;
    this.service_additional_charges=[];
    this.ServiceAdditionaltotalAmount=0;
    this.additional_charges=[];
    this.additional_total_amount=0;
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.net_disc = 0;
    this.round_sign = 1;
    this.roundoff = 0;
    this.resetProdForm();
    this.formStep1.reset();
    this.nextRefNo();
    this.sqdrf_id = '';
    this.sq_inv_no = '';
    this.sales_note='';
    this.sales_note2='';
    this.sales_note3='';
    this.mainStepper.reset();
    this.editMessage = false;
    this.price_group_id = 1;
    this.saleqt_date = new Date();
    setTimeout(() => {
      this.sale_pay_type = 1;
      this.cust_type = 2;
      // this.inv_type = 1;
      this.inv_sub_type = '00000';
      this.getSalesSetting();
    }, 500);
  }



  resetProds(stepper: MatStepper) {
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.net_disc = 0;
    this.round_sign = 1;
    this.roundoff = 0;
    stepper.previous();
  }

  proceedAddProd(stepper: MatStepper) {
    this.validateForm1();
    this.clicknext();
    if (this.printError.length <= 0) {
      stepper.next();

    }

  }

  validateForm2() {
    this.validationMsg = '';
    this.printError = [];
    this.cardaAmValidation =false;
    this.cashaAmValidation =false;
  
    let retVal = true;
    
    if(this.cash_amnt == 0){

      if (this.cash_amnt == 0) {
        this.printError.push({ 'cash_amnt': 'Required' });
        $('#cashamnt').css("border", "1px solid red");
       
        retVal =  false;
        this.cashaAmValidation =true;
      }
      else {
        $('#cashamnt').css("border", "1px solid #dedede");
        
        retVal =  true;
      } 

    }
    else if(this.card_amnt == 0){

      if (this.card_amnt == 0) {
        this.printError.push({ 'card_amnt': 'Required' });
        $('#card_amnt').css("border", "1px solid red");
       
        retVal =  false;
        this.cardaAmValidation =true;
      }
      else {
        $('#card_amnt').css("border", "1px solid #dedede");
        
        retVal =  true;
      } 

    }

    

    return retVal;

   
  }

  proceedToTerms(stepper: MatStepper) {

 
    
    if(this.sale_pay_type==4){

    
     
      
      if(this.validateForm2()){
    
     
      
      if (this.bal_amnt!=0) {
      
        this.balancErr =true;
     
      } 
      else {
        this.balancErr =false;
        stepper.next();
        $('.close').trigger('click');
      }
    }
    }
    else{
      this.balancErr =false;
      if (this.salesItems.length > 0) {
        this.selectError = false;
        stepper.next();
      } else {
        this.selectError = true;
      }
      $('.close').trigger('click');
    }
   
    
   
  }

  checkAmount() {
    this.inv_amnt = (this.totItemprice - this.totItemDisc +this.totExcsAmnt+ this.ServiceAdditionaltotalAmount+ this.totVatAmnt - this.net_disc - this.totPromoDisc + (this.round_sign * this.roundoff)).toFixed(4);
    // this.inv_amnt = Math.round(this.inv_amnt * 1000) / 1000;
    this.recivd_amnt = (this.totItemprice - this.totItemDisc +this.totExcsAmnt+this.ServiceAdditionaltotalAmount+ this.totVatAmnt - this.net_disc - this.totPromoDisc + (this.round_sign * this.roundoff)).toFixed(4);
    // this.recivd_amnt = Math.round(this.recivd_amnt * 1000) / 1000;

    this.bal_amnt = this.inv_amnt - this.recivd_amnt;
    if(this.sale_pay_type==4){
      //if statment for in case of edit the bill 
      if((this.cash_amnt)||(this.card_amnt)){
        this.cash_amnt=this.cash_amnt;
        this.card_amnt=this.card_amnt;
        this.bal_amnt = Number(this.cash_amnt)+ Number(this.card_amnt)-this.inv_amnt;  
      }else{
        this.cash_amnt=this.inv_amnt;
        this.card_amnt=0;
      } 

    }


    // this.bal_amnt = Math.round(this.bal_amnt * 1000) / 1000;

    // both payment changs
   if(this.sale_pay_type==4){
    setTimeout(() => {
      this.cashamnt.nativeElement.focus();
      this.cashamnt.nativeElement.select();
    }, 1000);
   }else{
    setTimeout(() => {
      this.selctRecAmnt.nativeElement.focus();
      this.selctRecAmnt.nativeElement.select();
    }, 1000);
   }

  }


  changeAmnt() {
    this.bal_amnt = this.recivd_amnt - this.inv_amnt;
    this.bal_amnt = Math.round(this.bal_amnt * 100) / 100;

  }
  changePayAmnt(){
    this.balancErr=true;
    this.bal_amnt=0;
    // if(this.cash_amnt<=this.inv_amnt){
    //   this.card_amnt=this.inv_amnt-this.cash_amnt;
    // }else if(this.cash_amnt>this.inv_amnt){
    //   this.card_amnt=0;
    // }
    
   
    if(this.cash_amnt > Number(this.inv_amnt)){
      this.card_amnt=0;
    }
    else{
      this.card_amnt=this.inv_amnt-this.cash_amnt;
    }
    if(this.card_amnt > 0){
      this.cash_amnt=this.inv_amnt-this.card_amnt;
   
    }

    if(this.cash_amnt > Number(this.inv_amnt)){
     
    }
   
   
    this.bal_amnt = Number(this.cash_amnt)+ Number(this.card_amnt)-this.inv_amnt;    
    this.bal_amnt = Math.round(this.bal_amnt * 100) / 100;
    if(this.bal_amnt!=0){
      this.balancErr=true;
    }else{
      this.balancErr=false;
    }
  }

  changePayCard(){
    this.balancErr=true;
    // if(this.cash_amnt<=this.inv_amnt){
    //   this.balance=0;
    //   this.balance = Number(this.cash_amnt)+ Number(this.card_amnt)-this.inv_amnt; 
      
    //   this.bal_amnt = Math.round(this.balance * 100) / 100;
    // }
    
    // if(this.bal_amnt!=0){
    //   this.balancErr=true;
    // }else{
    //   this.balancErr=false;
    // }

    if(this.card_amnt > Number(this.inv_amnt)){
     
      this.cash_amnt=0;
    }

    else{
      this.cash_amnt=this.inv_amnt-this.card_amnt;
    }

    this.bal_amnt = Number(this.cash_amnt)+ Number(this.card_amnt)-this.inv_amnt;    
    this.bal_amnt = Math.round(this.bal_amnt * 100) / 100;
    if(this.bal_amnt!=0){
      this.balancErr=true;
    }else{
      this.balancErr=false;
    }
  }

  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if (this.sale_pay_type === 2)
      searchval.append('ledger_grp', '3');
    else if (this.sale_pay_type === 3)
      searchval.append('ledger_grp', '4');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }

  searchDefaultLedgerCash(search: string){
    const searchval = new FormData();
    searchval.append('ledger_name', search); 
    searchval.append('ledger_grp', '3');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedgerCash = res['data'];
    });
    
  }
  searchDefaultLedgerBank(search: string){
    const searchval = new FormData();
    searchval.append('ledger_name', search); 
    searchval.append('ledger_grp', '4');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedgerBank = res['data'];
    });
    
  }
  changePayType() {
    this.defLedger = [];
    if (this.sale_pay_type == 1) {
      this.sale_acc_ledger_id = '';
      this.sale_acc_ledger_id_bank = '';
      this.sale_acc_ledger_id_cash = '';
    } else if (this.sale_pay_type === 2) {
      if (this.sale_acc_ledger_id != '0') {
        this.defLedger = this.coreService.getUserData('cash_ledger');
        this.sale_acc_ledger_id = this.coreService.getUserData('usr_cash_ledger_id');
        if (!this.sale_acc_ledger_id) {

          this.apiService.getBranchCashAccount().subscribe((led_res) => {
            this.defLedger = led_res.data.ledger;
            this.sale_acc_ledger_id = led_res.data.taxled_ledger_id;
          });
        }
      }
      this.sale_acc_ledger_id_bank = '';
      this.sale_acc_ledger_id_cash = '';
    } else if (this.sale_pay_type === 3) {
      if (this.sale_acc_ledger_id != '0') {
        this.defLedger = this.coreService.getUserData('bank_ledger');
        this.sale_acc_ledger_id = this.coreService.getUserData('usr_bank_ledger_id');
      }
      this.sale_acc_ledger_id_bank = '';
      this.sale_acc_ledger_id_cash = '';
    }else if(this.sale_pay_type === 4){
      this.cash_amnt=0;
      this.card_amnt=0;
      this.defLedgerCash = this.coreService.getUserData('cash_ledger');
      this.sale_acc_ledger_id_cash = this.coreService.getUserData('usr_cash_ledger_id');

      this.defLedgerBank = this.coreService.getUserData('bank_ledger');
      this.sale_acc_ledger_id_bank = this.coreService.getUserData('usr_bank_ledger_id');
    }
    this.cust_types = [
      { id: 1, name: 'New', disabled: false },
      { id: 2, name: 'Registered' }
    ];

    if (this.sale_pay_type === 1) {
      this.cust_type = 2;
    }

    
    if(!this.is_sale_edit && !this.qtn_id && !this.del_chellan_inv_no){
      if (this.sale_pay_type === 1) {
        this.cust_type = 2;
        this.cust_types = [
          { id: 1, name: 'New', disabled: true },
          { id: 2, name: 'Registered' }
        ];
      } else {
        this.cust_type = 1;
        this.cust_name = 'Cash Customer';
      }
      // payment option by settings
      // this.cust_type = 2;
      // if(this.sales_settings.ss_default_pay_type==1){
      //   this.sale_pay_type=1;
      //   this.cust_types = [
      //     { id: 1, name: 'New', disabled: true },
      //     { id: 2, name: 'Registered' }
      //   ];
      //   this.cust_type = 2;

      //  }else if(this.sales_settings.ss_default_pay_type==2){
      //   this.sale_pay_type=3;
      //  }else if(this.sales_settings.ss_default_pay_type==3){
      //   this.sale_pay_type=4;
      //  }else{
      //   this.sale_pay_type=2;
      //   this.cust_type = 1;
      //   this.cust_name = 'Cash Customer';
        
      //  }

       if (this.inv_type == 2) {
        this.cust_type=1;
        if (this.sale_pay_type === 1){
          this.cust_type=2;
        }
        // this.cust_type=1;
          }else{
        this.cust_type=2;
          }


    }
    console.log('this.sale_pay_type'+this.sale_pay_type);
    
  }

  checkAddrs(val) {
    if (val) {
      this.delivry_addr = this.cust_addr;
    } else {
      this.delivry_addr = '';
    }
  }

  validateForm1() {
    this.validationMsg = '';
    this.printError = [];
    if (this.cust_type === "" || this.cust_type === undefined || this.cust_type === null) {
      this.printError.push({ 'cust_type': 'Required' });
      $('#cust_type').css("border", "1px solid red");
    }
    else {
      $('#cust_type').css("border", "1px solid #dedede");
    }
    if (this.sale_pay_type !== 1) {
      //both payment  id is 4
   if(this.sale_pay_type ==4){
    console.log(this.sale_acc_ledger_id_bank);
    
    if((this.sale_acc_ledger_id_bank<=0||this.sale_acc_ledger_id_bank===undefined ||this.sale_acc_ledger_id_bank===null)){
      this.printError.push({ 'sale_acc_ledger_id_bank': 'Required' });
      $('#b_bank').css("border", "1px solid red");
    }else if((this.sale_acc_ledger_id_cash===""||this.sale_acc_ledger_id_cash===undefined||this.sale_acc_ledger_id_cash===null)){
      this.printError.push({ 'sale_acc_ledger_id_cash': 'Required' });
      $('#b_cash').css("border", "1px solid red");
    }

   }else{
    console.log('Suii');
    
    if (this.sale_acc_ledger_id === "" || this.sale_acc_ledger_id === undefined || this.sale_acc_ledger_id === null || this.sale_acc_ledger_id == "0") {
      this.printError.push({ 'sale_acc_ledger_id': 'Required' });
      $('#sale_acc_ledger_id').css("border", "1px solid red");
    }
    else {
      $('#sale_acc_ledger_id').css("border", "1px solid #dedede");
    }
   }
    } else {
      $('#sale_acc_ledger_id').css("border", "1px solid #dedede");
    }

    if (this.inv_type == 1) {
      if (this.vat_no === "" || this.vat_no === undefined || this.vat_no === null) {
        this.printError.push({ 'vat_no': 'Required' });
        $('#vat_no').css("border", "1px solid red");
      }
      else {
        $('#vat_no').css("border", "1px solid #dedede");
      }
    } else {
      $('#vat_no').css("border", "1px solid #dedede");
    }

    if (this.cust_type == 2 && (this.inv_type == 1 || this.inv_type == 3)) {
      if (this.cust_id === "" || this.cust_id === undefined || this.cust_id === null) {
        this.printError.push({ 'cust_id': 'Required' });
        $('#cust_id').css("border", "1px solid red");
      }
      else {
        $('#cust_id').css("border", "1px solid #dedede");
      }
    } else {
      if ((this.inv_type == 1 || this.inv_type == 3) && (this.cust_name === "" || this.cust_name === undefined || this.cust_name === null)) {
        this.printError.push({ 'cust_name': 'Required' });
        $('#cust_name').css("border", "1px solid red");
      }
      else {
        $('#cust_name').css("border", "1px solid #dedede");
      }
    }

    if (this.cust_type == 2 && this.inv_type == 2 && this.sale_pay_type == 1) {
      if (this.cust_id === "" || this.cust_id === undefined || this.cust_id === null) {
        this.printError.push({ 'cust_id': 'Required' });
        $('#cust_id').css("border", "1px solid red");
      }
      else {
        $('#cust_id').css("border", "1px solid #dedede");
      }
    }

    if (this.saleqt_date === "" || this.saleqt_date === undefined || this.saleqt_date === null) {
      this.printError.push({ 'saleqt_date': 'Required' });
      $('#saleqt_date').css("border", "1px solid red");
    }
    else {
      $('#saleqt_date').css("border", "1px solid #dedede");
    }


  }

  addSales(stepper: MatStepper, formdata: { value: any; }) {
    this.validationMsg = '';
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = (this.checkIsExciseEnble)?this.totItemprice - this.totItemDisc - this.totPromoDisc +this.totExcsAmnt+  this.ServiceAdditionaltotalAmount+ this.totVatAmnt - this.net_disc + (this.round_sign * this.roundoff):this.totItemprice - this.totItemDisc - this.totPromoDisc + this.ServiceAdditionaltotalAmount + this.totVatAmnt - this.net_disc + (this.round_sign * this.roundoff);
    formdata.value.sales_roundoff = this.round_sign * this.roundoff;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.service_additional_charges = this.service_additional_charges;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.promo_disc = this.totPromoDisc;
    if(this.checkIsExciseEnble){
      formdata.value.excs_tax_amnt = this.totExcsAmnt;
    }
    
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.sales_note = this.sales_note;
    formdata.value.sales_note2 = this.sales_note2;
    formdata.value.sales_note3 = this.sales_note3;
    formdata.value.sales_exchange_rate = this.exchange_rate;
    formdata.value.sales_currency_id = (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0;
    formdata.value.sales_tax_type = this.purchtypesel - 1;
    formdata.value.del_chellan_inv_number = this.del_chellan_inv_number;
    if(this.sale_pay_type==4){
      formdata.value.cash_amnt = this.cash_amnt;
      formdata.value.card_amnt = this.card_amnt;
    }
    if (this.mul_del_chellan_inv_number != null) {
      formdata.value.mul_del_chellan_inv_number = this.mul_del_chellan_inv_number;
    }

    // console.log('formdata.value.inv_type :' + formdata.value.inv_type);
    if(this.eInvoiceSupport && formdata.value.inv_type == 2 && this.print_style_tmp == 1000){
      if(this.einv_simplfied_print_style == 1){
        this.printer_style = 1001;
      } else {
        this.printer_style = 1002;
      }
    } else{
      this.printer_style = this.print_style_tmp;
    }
    // console.log('printer_style :' + this.printer_style);

    this.pageLoadingImg = true;
    this.apiService.addSales(formdata.value).subscribe((res) => {
      if (res.error != null) {
        this.resultobj = res.error;
        this.pageLoadingImg = false;
        for (var value in this.resultobj) {
          this.validationMsg += this.resultobj[value].toString() + '<br>';
        }
      }
      if (res.message) {
        this.coreService.showMessage(res.message['msg'], (this.eInvoiceSupport ? 4000: 1000) );
        this.saleData = res.message['preview'];
        this.data = this.saleData.sales_sub;
        this.ttlPage = Math.ceil(this.saleData.sales_sub.length / this.noRowPerPage);
        this.noPage = this.createRange(this.ttlPage);
        this.emptyRow = this.ttlPage * this.noRowPerPage - this.saleData.sales_sub.length - 2;
        // let link = 'http://127.0.0.1:8000/';
        // if(this.apiService.PHP_API_SERVER == "https://bquickerp.com/backend"){
        //   link = 'https://bquickerp.com/';
        // }
        // if(this.apiService.PHP_API_SERVER == "https://alhazmicloud.com/backend"){
        //   link = 'https://alhazmicloud.com/';
        // }
        // if(this.apiService.PHP_API_SERVER == "https://fujishka.vip//backend" || this.apiService.PHP_API_SERVER == "https://fujishka.vip/backend"){
        //   link = 'https://fujishka.vip/';
        // }
        // if(this.apiService.PHP_API_SERVER == "http://bquickerp.in/backend"){
        //   link = 'https://bquickerp.in/';
        // }

        // this.qr_inv = link + this.saleData['qr_link'];
        this.qr_inv = this.saleData['qr_link'];

        this.is_prew_description = this.saleData['sales_sub'].filter(x => x.item_descrp);
        this.is_prew_item_disc = this.saleData['sales_sub'].filter(x => x.sqs_discount);
        this.pageLoadingImg = false;
        this.doneMessage=false;

        

        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';
        // for whatsapp link
        this.whatsapplink = 'Dear Customer ,' + '%0A%0A'
        + 'Here is your invoice bill no' + '%20' + '%2A' + this.saleData.sq_inv_no + '%2A' + '%20' + 'amounting in' + '%20' + '%2A' + this.saleData.tot_amount + '%2A'+'%20'+this.saleData.tot_amount_prefix  + '.'

        + '%0A%0A' + '%2A' + '_Details :-_' + '%2A' + '%0A%0A' + ''
        + 'Name' + '%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_cust_name + '%2A' + '%0A'
        + 'Invoice No' + '%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_inv_no + '%2A' + '%0A'
        + 'Invoice Date' + '%3A' + '%20' + '%2A' + this.saleData.sq_date + '%2A' + '%0A'
        + 'Due Date' + '%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_valid_till + '%2A' + '%0A' +

        '______________________________' + '%0A%0A'
        + 'Total Bill Amount' + '%3A' + '%20' + '%2A' + this.saleData.tot_amount + '%2A' + '%2F-' + '%0A'
      if (this.cust_balance > 0) {
        this.whatsapplink += 'Total Due Amount' + '%20' + '%3A' + '%20' + '%2A' + this.cust_balance.toFixed(2) + '/-' + '%2A' + '%0A';
      }
      this.whatsapplink += 'Total Bill Amount In Words' + '%3A' + '%20' + '%2A' + this.saleData.total_amount_in_words + '%2A' + '%0A%0A';

      if (this.cust_balance > 0) { this.whatsapplink += 'Please remit payment at earliest convinience.' + '%0A%0A'; }
      this.whatsapplink += '%2A' + '_Thank you for shoping_' + '%2A' + '%20';

        this.getDraftList();
        this.listQtn(1);
        this.newQtn(stepper, formdata, '');
        this.addOnProdProcess();

      }
    });
    this.apiService.updatebatchExNotification();
    this.apiService.updateEinvoiceNotificationChange();
    this.mul_del_chellan_inv_number = null;
    this.del_chellan_inv_no = '';
    this.searchDeliverychellan('');

    this.getAdditonalCharge()
  }


  updateQtn(stepper: MatStepper, formdata: { value: any; }) {
    
    if(this.service_additional_charges !=undefined){
      if(this.service_additional_charges.length > 0){
        this.service_additional_charges =this.service_additional_charges;
      }
    }else{
      this.service_additional_charges=[{ additionalCharges: this.edit_service_additional_charges, ServiceAdditionaltotalAmount: this.ServiceAdditionaltotalAmount} ];
      // this.service_additional_charges =[ 
      //   { additionalCharges: this.edit_service_additional_charges, ServiceAdditionaltotalAmount: this.edit_service_additional_charges},
      // ];
  
    
    }

    this.validationMsg = '';

    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = (this.checkIsExciseEnble)?this.totItemprice - this.totItemDisc +this.totExcsAmnt+ this.ServiceAdditionaltotalAmount+ this.totVatAmnt - this.net_disc + (this.round_sign * this.roundoff):this.totItemprice - this.totItemDisc + this.ServiceAdditionaltotalAmount + this.totVatAmnt - this.net_disc + (this.round_sign * this.roundoff);
    formdata.value.sales_roundoff = this.round_sign * this.roundoff;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.service_additional_charges = this.service_additional_charges ;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sales_exchange_rate = this.exchange_rate;
    if(this.checkIsExciseEnble){
      formdata.value.excs_tax_amnt = this.totExcsAmnt;
    }
    formdata.value.sales_currency_id = (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0;
    this.salesItems.forEach((element, index) => {
      this.salesItems[index].salesub_gd_id = this.gd_id;
      this.salesItems[index].salesub_currency_id = (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0;
      this.salesItems[index].salesub_exchange_rate = this.exchange_rate;
    }); 
    if(this.sale_pay_type==4){
      formdata.value.cash_amnt = this.cash_amnt;
      formdata.value.card_amnt = this.card_amnt;
    }
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.sales_tax_type = this.purchtypesel - 1;
    formdata.value.sales_note = this.sales_note;
    formdata.value.sales_note2 = this.sales_note2;
    formdata.value.sales_note3 = this.sales_note3;
    this.pageLoadingImg = true;

    if(this.eInvoiceSupport && formdata.value.inv_type == 2 && this.print_style_tmp == 1000){
      if(this.einv_simplfied_print_style == 1){
        this.printer_style = 1001;
      } else {
        this.printer_style = 1002;
      }
    } else{
      this.printer_style = this.print_style_tmp;
    }

    this.apiService.editSales(formdata.value).subscribe((res) => {
      if (res.error != null) {
        this.resultobj = res.error;
        this.pageLoadingImg = false;
        for (var value in this.resultobj) {
          this.validationMsg += this.resultobj[value].toString() + '<br>';
        }
      } else if (res.message) {
        this.coreService.showMessage(res.message['msg'], (this.eInvoiceSupport ? 4000: 1000) );
        this.saleData = res.message['preview'];

        // for whatsapp link
        this.whatsapplink = 'Dear Customer ,' + '%0A%0A'
        + 'Here is your invoice bill no' + '%20' + '%2A' + this.saleData.sq_inv_no + '%2A' + '%20' + 'amounting in' + '%20' + '%2A' + this.saleData.tot_amount + '%2A'+'%20'+this.saleData.tot_amount_prefix  + '.'

        + '%0A%0A' + '%2A' + '_Details :-_' + '%2A' + '%0A%0A' + ''
        + 'Name' + '%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_cust_name + '%2A' + '%0A'
        + 'Invoice No' + '%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_inv_no + '%2A' + '%0A'
        + 'Invoice Date' + '%3A' + '%20' + '%2A' + this.saleData.sq_date + '%2A' + '%0A'
        + 'Due Date' + '%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleData.sq_valid_till + '%2A' + '%0A' +

        '______________________________' + '%0A%0A'
        + 'Total Bill Amount' + '%3A' + '%20' + '%2A' + this.saleData.tot_amount + '%2A' + '%2F-' + '%0A'
      if (this.cust_balance > 0) {
        this.whatsapplink += 'Total Due Amount' + '%20' + '%3A' + '%20' + '%2A' + this.cust_balance.toFixed(2) + '/-' + '%2A' + '%0A';
      }
      this.whatsapplink += 'Total Bill Amount In Words' + '%3A' + '%20' + '%2A' + this.saleData.total_amount_in_words + '%2A' + '%0A%0A';

      if (this.cust_balance > 0) { this.whatsapplink += 'Please remit payment at earliest convinience.' + '%0A%0A'; }
      this.whatsapplink += '%2A' + '_Thank you for shoping_' + '%2A' + '%20';

        this.data = this.saleData.sales_sub;
        this.ttlPage = Math.ceil(this.saleData.sales_sub.length / this.noRowPerPage);
        this.noPage = this.createRange(this.ttlPage);
        this.qr_inv = this.saleData['qr_link'];
        this.is_prew_description = this.saleData['sales_sub'].filter(x => x.item_descrp);
        this.is_prew_item_disc = this.saleData['sales_sub'].filter(x => x.sqs_discount);
        this.pageLoadingImg = false;
        this.emptyRow = this.ttlPage * this.noRowPerPage - this.saleData.sales_sub.length - 2;
        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';

        this.getDraftList();
        this.listQtn(1);
        this.getEditLogList(1);
        this.sales_note='';
        this.sales_note2='';
        this.sales_note3='';
        this.additional_total_amount=0;
        this.editAdditionalServ = false;

      }
    });
    this.apiService.updatebatchExNotification();
    this.apiService.updateEinvoiceNotificationChange();
    this.addOnProdProcess();
   
  }


  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];

    });
  }
  Select_invoiceType(id){

   
    if (id){
      this.invoiceId=id;
      let searchval = new FormData();
      searchval.append("invoiceType_id",this.invoiceId);
      this.searchLoadingImg = true;
      this.apiService.getSalesInvList(searchval, 1).subscribe((res) => {
        this.list_sales = res.data.data;
        this.agent_check = res.agent_check;
        this.curpage = res.data['current_page'];
        this.lastpage = res.data['last_page'];
        this.from = res.data['from'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        this.searchLoadingImg = false;
      });

    }
    
    
  
    

    
      
  }

  nextRefNo() {
    let searchval = new FormData();

    this.apiService.getSaleRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res['data'].sale_num;
      this.qt_no = res['data'].sq_branch_qt_no;

    });
  }

  // do not use this api other than sales bcz add some exeption in this (by ajmal)
  searchStkPrd(search: string) {
    // this.edit_as_child = 0;
    let searchval = new FormData();
    if(this.commAll==1){
      searchval.append('alias_search', '1');
    }
    searchval.append("keyword", search);
    searchval.append('stk_stat', '1');
    searchval.append('godown_id', this.gd_id);
    this.apiService.getSalesStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.quickCustomerSearch(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomerCode(search: string) {
    const searchval = new FormData();
    searchval.append('customer_code', search);
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.CustomerCustcodeSearch(searchval).subscribe((res) => {
      this.customercode = res['data'];
    });
    

  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];

    });

  }

  findCustomer(cust_id) {
    if(this.cust_type == 2){
    const searchval = new FormData();
    searchval.append('cust_code', cust_id);

    this.apiService.getCustomerByCode(searchval).subscribe((res) => {
      this.cust_id = res['data'];
      this.srchmsg= res['msg']
      if (res['data']) {
        this.srchmsg=null;
        this.noCustomer = false;
        this.selectCustomer(this.cust_id);
      }
      else {
        this.noCustomer = true;
        this.clearCustomer();
      }

    });

  }
}
  resetStep(step1) {
    step1.reset();
    this.nextRefNo();
    setTimeout(() => {

      this.cust_type = 2;
      if(this.sales_settings.ss_default_pay_type==1){
        this.sale_pay_type=1;
        this.cust_type = 2;

       }else if(this.sales_settings.ss_default_pay_type==2){
        this.sale_pay_type=3;
       }else if(this.sales_settings.ss_default_pay_type==3){
        this.sale_pay_type=4;
       }else{
        this.sale_pay_type=2;
        this.cust_type = 1;
       }

      this.saleqt_date = new Date();
      this.inv_type = 1;
    }, 500);

  }

  selectCustomer(cust, $exchngeRate = null) {

    this.cust_enable_sale_below_mrp = 1;
    this.cust_balance = '';
    this.cust_bal_sign = '';
    if (cust && cust.cust_id) {
      this.custSearchLaoding = true;
        this.getCustomerDetails(cust.cust_id);
      this.cust_enable_sale_below_mrp = cust.enable_sale_below_mrp;
      this.cust_code = cust.cust_code;
      this.cust_addr = cust.cust_home_addr;
      this.cntct_num = cust.mobile;
      this.price_group_id = cust.price_group_id;
      let date = new Date(this.saleqt_date);
      this.noCustomer = false;
      this.srchmsg=null;

      // this.cust_balance = cust.balance.bal_without_sign;
      // this.cust_bal_sign = cust.balance.bal_sign;
      this.inv_limit_no=cust.inv_due_limit_no;
      this.current_dues=cust.crd_limit;
      // this.cust_bal = cust.balance.bal_amt;
      this.cust_credit_limit = cust.cust_credit_limit;
      this.cust_default_currency_id = cust.default_currency_id;
      if (this.cust_default_currency_id && this.multipleCurrency) {
        this.selcurrency = this.currencies.find(x => x.cur_id == this.cust_default_currency_id);
        if ($exchngeRate == null) {
          this.exchange_rate = this.selcurrency.cur_exchange_rate;
        } else {
          this.exchange_rate = $exchngeRate;
        }
      } else {
        this.selcurrency = null;
        this.exchange_rate = 1;
      }
      
      
      // if(this.current_dues>=this.inv_limit_no && this.inv_limit==1 && this.inv_limit_no !=null){
      //   this.inv_due_msg=true;
      // }else{
      //   this.inv_due_msg=false;
      // }
      
      if (cust.default_sales_agent > 0) {
        this.sale_agent = cust.default_sales_agent;
        
      }
      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }
  getCustomerDetails(cust_id){
    console.log('cust_id');
    console.log(cust_id);
    
    this.vat_no = '';
    this.apiService.getCustomerDetails(cust_id).subscribe((res) => {
      let cust = res.data;
      this.vat_no = this.eInvoiceSupport &&  cust.einv_vat_no ?  cust.einv_vat_no : cust.vat_no ;
      this.cust_balance = cust.balance.bal_without_sign;
      this.cust_bal_sign = cust.balance.bal_sign;
      this.inv_limit_no=cust.inv_due_limit_no;
      this.current_dues=cust.crd_limit;
      this.custSearchLaoding = false;
      if(this.current_dues>=this.inv_limit_no && this.inv_limit==1 && this.inv_limit_no !=null){
        this.inv_due_msg=true;
      }else{
        this.inv_due_msg=false;
      }
    
      if(res.data.is_e_invoice==1){
        this.inv_type=1;
        this.updatePayment();
      }
    });
  }
  updateDate(cust) {
    if (cust) {

      let date = new Date(this.saleqt_date);

      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }

  updatePayment() {
    this.inv_sub_type='00000';
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
    if (this.inv_type == 2) {
      this.cust_type=1;
    }else{
      this.cust_type=2;
    }

    if(this.inv_type == 3){
      this.purchtypesel =2;
      this.selectTaxType(2);
    }
    this.changePayType();
  }


  clearCustomer() {
    this.cust_code = '';
    this.cust_addr = '';
    this.cntct_num = '';
    this.vat_no = '';
  }

  selectDiscType() {

    // if(itemDiscTyp == 1){

    // }
    this.calcRates();

  }

  searchprdBybarcode(search: any) {
    const searchval = new FormData();
    searchval.append('prd_barcd', search);
    searchval.append('godown_id', this.gd_id);
    searchval.append("hide_prd_by_cat_sale", '1');
    this.apiService.searchBarcode(searchval).subscribe((res) => {
      this.prdcts = res['data'];
      this.srch_length = this.prdcts.length;
      // console.log(this.prdcts);
     
    });

  }

  searchStkPrdBarcode(search: any) {
    if (!search) {
      $("#br_err").text("Enter Barcode");
      return false;
    } else {
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.prodUnitLoading = true;
    this.apiService.getStockbyBarcodeSales(searchval).subscribe((res) => {
      // this.stkprd_all = res['data'];
      if (res.data) {

        $("#br_err").text("");
        this.openPopUp(res.data);
        this.stkprdsel = res.data;
        this.selectedProduct(res.data.prd_id, this.gd_id, this.stkprdsel, true);
        this.selectQty.nativeElement.focus();
        this.selectQty.nativeElement.select();

      } else {
        $("#br_err").text("No product found");
        this.stkprdsel = {
          prd_name: null
        };
        this.stkprdsel = "";
        this.selctedProd = {};
        this.qty = '';
        this.rate = '';
        this.rate_org = '';
        this.unit = '';
        this.disc_amnt = 0;
        // this.prd_tax_cat_id = '';
        this.tax_amnt = '';
        this.grnd_totl = '';
        this.item_dicrptn = '';
        this.godownStocks = [];
        this.branchStocks = [];
        this.lastInvoice = [];
        this.isEdit = false;
        this.sl_no = '';
        this.base_qty = '';
        this.unit_name = '';
        this.promo_disc = 0;
        this.promo_per = 0;
      }

    });
  }

  selectedProduct(prd_id, gd_id, stkprdsel, isBarCodeSearch=false) {
    this.selectTaxType(this.prd_tax_cat_id)
    this.printError = [];
   this.edit_as_child =0;
    this.parent_product ={ id: 0, parent_product: 'None',prd_id :0 };

    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }



    if (this.printError.length <= 0) {
      this.prodUnitLoading = true;
      if (gd_id) {
        gd_id = gd_id;
      } else {
        gd_id = 0;
      }

      this.getGodownWiseStock(prd_id);
      this.getBranchWiseStock(prd_id);
      this.getStockDetails(prd_id, gd_id, isBarCodeSearch);
      if (this.cust_id) {
        this.getLastSaleToCust(prd_id, this.cust_id.cust_id);
      }
      if (this.purchtypesel == 1)
        this.prd_tax_cat_id = Number(stkprdsel.prd_tax_cat_id);

      this.calcRates();
      // setTimeout(() => {
      //   this.selectQty.nativeElement.focus();
      //   this.selectQty.nativeElement.select();

      // }, 1000);
    }
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res['data']['data'];
      this.godownStocksTotal = res['data']['total'];
    });

  }

  getLastSaleToCust(prd_id, cust_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('cust_id', cust_id);
    if(this.usr_hide_others_sales_data){
    searchval.append('user_id', '1');

    }
    this.apiService.getLastSaleToCust(searchval).subscribe((res) => {
      this.lastInvoice = res['data'];
      this.lastCust = 0;

      if (this.lastInvoice[0])
        this.lastCust = this.lastInvoice[0]['salesub_rate'];
      else
        this.lastCust = 0;

    });

  }


  getBranchWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.branchStocksTotal = 0;

    this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
      this.branchStocks = res['data']['data'];
      this.branchStocksTotal = res['data']['total'];

    });

  }

  getStockDetails(prd_id, gd_id, isBarCodeSearch=false) {
    
   

    this.addOnProdProcess();
  
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    searchval.append('price_group_id', this.price_group_id);
    if(this.is_sale_edit){
      searchval.append('sales_inv_no', this.sq_inv_no);
    }
    this.batches = [];
    searchval.append('date', this.saleqt_date);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
      this.stock_qty = res['data'].stock_qty; 
      this.promo = res['data'].promo;
      this.available_stock = this.selctedProd.stock_qty;
      this.single_weight =this.selctedProd.prd_weight;

      this.unit_available_stock = this.selctedProd.stock_qty;
      this.unit_bs_prate = this.selctedProd.bs_prate;
      this.bs_prate = this.selctedProd.bs_prate;
      this.avg_prate = this.selctedProd.bs_avg_prate;
      this.unit = (this.stkprdsel.produnit_unit_id ? this.stkprdsel.produnit_unit_id : this.selctedProd.prd_base_unit_id);
      if (this.selctedProd.prd_default_unit_id) {
        if(!isBarCodeSearch){
          this.unit = this.selctedProd.prd_default_unit_id;
        }
        if(isBarCodeSearch && this.show_default_unit_in_barcode_search){
          this.unit = this.selctedProd.prd_default_unit_id;
        }
      }
      this.shop_qty = this.selctedProd.bs_stock_quantity_shop;
      this.van_qty = this.selctedProd.bs_stock_quantity_van;
      this.gd_qty = this.selctedProd.bs_stock_quantity_gd;
      if(this.checkIsExciseEnble){
        this.excs_tax_cat_id = this.selctedProd.prd_excs_tax_cat_id;
        this.excs_tax_type = this.selctedProd.prd_excs_tax_type;
      }
      this.item_dicrptn=this.selctedProd.prd_desc;
      this.excl_vat=this.sale_vat_type?2:1;
      this.display_qty=this.selctedProd.bs_rate2_display_qty;
      this.prd_location=this.selctedProd.prd_location;
      this.barcode = this.selctedProd.prd_barcode;
      this.batches = this.selctedProd.batches;
      this.totalBatch =this.selctedProd.total_batch;

      //ob & cb reading display
      this.op_rd=0;
      this.cls_rd=0;
      if(this.selctedProd.fuel_reading){
        this.op_rd=this.selctedProd.fuel_reading.cb_reading;
        
      }
      
      if(this.selctedProd.batches.length > 0 && this.checkBatch){

     
        this.selBatch =this.selctedProd.batches[0].sb_id;
        this.batchs=this.selctedProd.batches;

        const searchval = new FormData();
      searchval.append('prd_id', this.selctedProd.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id', this.selBatch);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

       // this.selctedProd.stock_qty = resp['data'].gbs_qty || 0;
      //  this.selctedProd.stock_qty = resp['data']['gdStock'].gbs_qty || 0;
      //  this.selctedProd.prod_units =resp['data']['prod_units'];
       this.selctedBatchStock = resp['data']['gdStock'].gbs_qty || 0;
       this.batch_available_stock = resp['data']['gdStock'].gbs_qty || 0;
      //  this.calcRates();
      //  this.selectedUnit(this.unit);

      });
      }
      this.selectedUnit(this.unit);
      this.calcRates();
      if (this.purchtypesel == 1)
        this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);

      if(this.checkIsExciseEnble){
        this.updateExcsTaxPerc(this.selctedProd.prd_excs_tax_cat_id);

      }

    });

  }

  resetProdForm() {
    this.barcode = '';
    this.stkprdsel = "";
    this.selctedProd = {};
    this.qty = '';
    this.rate = '';
    this.rate_org = '';
    this.unit = '';
    this.disc_amnt = 0;
    // this.prd_tax_cat_id = '';
    this.selBatch = undefined;
    this.batch_code=null;
    this.tax_amnt = '';
    this.grnd_totl = '';
    this.item_dicrptn = '';
    this.sales_serials = [];
    this.enable_serial_no = false;
    this.godownStocks = [];
    this.godownStocksTotal = 0;
    this.branchStocks = [];
    this.branchStocksTotal = 0;
    this.lastInvoice = [];
    this.lastCust = 0;
    this.isEdit = false;
    this.sl_no = '';
    this.base_qty = '';
    this.unit_name = '';
this.excl_vat =this.sale_vat_type ? 2 : 1;
    this.promo_disc = 0;
    this.promo_per = 0;
    this.selectproductId.focus();

  }

  selectedUnit(unit) {
    const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === unit);
    var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
    
  // in case of excs tax item edit grid makes excs tax wrong after change rate
    if(!this.isExcsEdit){
        this.rate = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    }
    this.isExcsEdit=false;
    this.rate_org = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    this.rate_currency = (unit_rate ? (unit_rate/this.exchange_rate) : (prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate)/this.exchange_rate);

    this.base_qty = prdUnts[0].unit_base_qty;
    console.log('this.base_qty ',this.base_qty );
    
    this.unit_available_stock = this.available_stock / this.base_qty;
    this.selctedBatchStock = this.batch_available_stock/this.base_qty;
    this.unit_bs_prate = this.bs_prate * this.base_qty;
    this.unit_avg_prate = this.avg_prate * this.base_qty;
    this.unit_name = prdUnts[0].unit_display;
    this.prodUnitLoading = false;
    
    setTimeout(() => {

      this.selectQty.nativeElement.focus();
      this.selectQty.nativeElement.select();

    }, 1000);
    
    this.calcRates();
  }
  // exchngChange(rateChhngs){
  //   this.exchange_rate=rateChhngs;
  //   this.rate_currency=this.rate_currency/rateChhngs;
  // }

  calcRates() {


    console.log("shameertrr");
    console.log("unit");
    console.log(this.unit);

    console.log("single_weight");
    console.log(this.single_weight);


   

  
    
    this.qty = this.qty ? this.qty : '';
    let qty1 = this.qty*this.base_qty;

    console.log("qty1");
    console.log(qty1);
    this.rate = this.rate ? this.rate : 0;
    this.itemDisc = this.disc_amnt ? this.disc_amnt : 0;
    this.weight = this.single_weight * qty1;  

    this.purchAmnt = this.qty * this.rate;
if(qty1>=this.display_qty){
  this.isSecondOptionDisabled =false;
}else{
  this.sel_rate_type=1;
  this.isSecondOptionDisabled =true;
  
}
if(this.checkIsExciseEnble ){
  // in case of exepmt sale & zero rated 
  if (this.purchtypesel != 1){
    this.tax_amnt = 0;
    this.taxpercentage=0;
  }
 
}

    this.isOffer = false;
    this.promo_disc = 0;
    this.promo_per = 0;
    this.excs_tax_amnt = 0;
    if(this.promo){
      this.isOffer = true;
      // check promo/offer qty limit
      if(this.promo.promosub_qty != 0){
        let avlQty = this.promo.promosub_qty- this.promo.sold_qty
        if(avlQty <= 0){
          this.isOffer = false;
        }
      }

      if(this.isOffer){
        if(this.promo.promosub_disc_type == 1){
          this.promo_disc = this.promo.promosub_discount * this.qty;
          this.promo_per = this.promo_disc * 100/ this.purchAmnt;
        }
        if(this.promo.promosub_disc_type == 2){
          this.promo_per = this.promo.promosub_percentage;
          this.promo_disc = (this.purchAmnt * this.promo_per/ 100);
        }

        this.purchAmnt  -= this.promo_disc;
      }
    }  

    if (this.item_disc_type == 1)
      this.itemDisc = this.itemDisc;
    else
      this.itemDisc = (this.purchAmnt * this.itemDisc) / 100;

      if(this.checkIsExciseEnble && this.excs_tax_cat_id){
        // excise befor vat
            
        if(this.excs_tax_type==1){
          if(this.cmpny=='SAAD'||this.cmpny=='FJK'){
            let purch_rate=this.purchAmnt/this.qty;
            this.excs_tax_amnt=Number(Math.round(((purch_rate * this.excstaxpercentage) / 100) * 1000) / 1000) *this.qty;
           }else{
            this.excs_tax_amnt=(this.purchAmnt/100)*this.excstaxpercentage;
           }
        
        this.purchAmnt = this.purchAmnt + this.excs_tax_amnt;
        }
      }
    if (this.excl_vat == 1) {
      // code to get rounded value for SAAD client by ajml
      if(this.cmpny=='SAAD'||this.cmpny=='FJK'){
        this.tax_amnt=Number((((this.purchAmnt - (this.itemDisc)) * this.taxpercentage) / 100).toFixed(3)) ;
       }else{
        this.tax_amnt = ((this.purchAmnt - (this.itemDisc)) * this.taxpercentage) / 100;
       }
     
      
      this.itemRate = this.rate;
    } else {
      this.tax_amnt = (this.purchAmnt - (this.itemDisc)) - (((this.purchAmnt - (this.itemDisc)) * 100) / (this.taxpercentage + 100));
      this.taxprice = (this.rate - this.itemDisc) - (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
      // this.itemRate = (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
      this.itemRate = (this.purchAmnt - this.tax_amnt + Number(this.promo_disc)) / this.qty;
    }

    if(this.checkIsExciseEnble && this.excs_tax_cat_id){
      // excise after vat is 2
      if(this.excs_tax_type==2){
        this.excs_tax_amnt=((this.purchAmnt + this.tax_amnt)/100)*this.excstaxpercentage;
        this.purchAmnt = this.purchAmnt + this.tax_amnt + this.excs_tax_amnt;
        if (this.excl_vat == 1) {
          this.tax_amnt= ((this.purchAmnt - (this.itemDisc)) * this.taxpercentage) / 100;
        }else{
          this.tax_amnt = (this.purchAmnt - (this.itemDisc)) - (((this.purchAmnt - (this.itemDisc)) * 100) / (this.taxpercentage + 100));
        }
    
        
      }
    }
      if (this.excl_vat == 1)
        this.itemTotal = this.purchAmnt + this.tax_amnt;
      else
        this.itemTotal = this.purchAmnt;
  
    

    this.grnd_totl = this.itemTotal - this.itemDisc;
    this.grnd_totl = this.itemTotal - this.itemDisc;

    // this.grnd_totl = this.itemTotal - this.promo_disc - this.itemDisc;
    // this.grnd_totl = this.itemTotal - this.promo_disc - this.itemDisc;

  }


  addSerials(){
    let qty_num = this.qty ? this.qty : 0;
    qty_num = parseInt(qty_num);
    if(this.sales_serials.length>=qty_num){
      this.coreService.showMessage("Only "+qty_num+" Serial Numbers Are Allowed");
      return false;
    }
    this.sales_serials.push({
      serial_no:''
    });
  }
  removeSerials(index) {
    this.sales_serials.splice(index, 1);
    delete this.serialsErrors['serial_no_' + index]
  }


  addSaleItem(selected: { value: any; }, close) {
    
   

    this.validationMsg = '';
    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }

    if (this.qty == "" || this.qty <= 0||isNaN(this.qty)) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }
    if ((this.unit_available_stock <= 0 && this.disable_sales_without_stock) || (this.disable_sales_without_stock && (this.unit_available_stock.toFixed(3) < parseFloat(this.qty)))) {
      this.printError.push({ 'without_stock': 'Disabled' });
      alert("Sales without stock is disabled");
      return false;
    }
    console.log(this.rate+'--'+this.rate_org);
    console.log('this.enable_sale_below_mrp',this.enable_sale_below_mrp);
    console.log('this.cust_enable_sale_below_mrp',this.cust_enable_sale_below_mrp);
    if ((((!this.enable_sale_below_mrp && (this.rate < this.rate_org)) && !this.cust_id) || ((this.rate < this.rate_org)&&!this.cust_enable_sale_below_mrp &&!this.enable_sale_below_mrp))) {
      this.printError.push({ 'below_mrp': 'Disabled' });
      alert("Sales below MRP is disabled");
      return false;
    }

    console.log('this.bs_prate',this.bs_prate);
    console.log('this.rate',this.rate);
    console.log('this.rate_org',this.rate_org);
    console.log('this.base_qty',this.base_qty);
    
    if (this.enable_sale_below_purch && (this.bs_prate*this.base_qty > this.rate)) {
      this.printError.push({ 'below_purch': 'Disabled' });
      alert("Sales below Purchase Rate is disabled");
      return false;
    }
   
     //Start  Batch Validation

    
  if(this.checkBatch && this.selBatch == undefined && this.totalBatch >0){
      this.printError.push({ 'batchErr': 'Required' });
      $('#batch').css("border", "1px solid red");
      alert("Please Select Batch..");

   }
   else {
     $('#batch').css("border", "1px solid #dedede");
   }


     if ( 
      (this.selBatch != undefined && this.checkBatch && this.selctedBatchStock <= 0 && this.disable_sales_without_stock) ||
      (this.disable_sales_without_stock &&
        this.selctedBatchStock < parseFloat(this.qty)  
      )
    ) {
      this.printError.push({ 'batchErr': 'Required' });
      $('#batch').css("border", "1px solid red");
      alert("Sales without stock is disabled");
     
      
    }

        
    //End  Batch Validation 
    
    // check promo/ offer with limited quantity   
    if(this.promo && this.isOffer){
      let avlQty = this.promo.promosub_qty- this.promo.sold_qty
      if(this.qty > avlQty){
        alert("Please choose a less Qty. to avail promotional discount. Max Qty:" + avlQty + "Nos");
        return false;
      }
      // Please choose a less Qty. to avail promotional discount. Max Qty:5Nos
    }
    

    if(this.enable_serial_no){
      let qty_num = this.qty ? this.qty : 0;
      qty_num = parseInt(qty_num);
      if(this.sales_serials.length>qty_num){
        this.coreService.showMessage("Only "+qty_num+" Serial Numbers Are Allowed");
        return false;
      }
      if(this.sales_serials.length<qty_num){
        this.coreService.showMessage("Please enter serial number for all quantity");
        return false;
      }
  
      this.sales_serials.forEach((element, i) => {
        
        if(element.serial_no == ''){
          this.serialsErrors['serial_no_' + i] = {msg : 'Required'};
          this.printError.push({ 'serial_no': 'Required' });
        }else{
          delete this.serialsErrors['serial_no_' + i];
        }
      })
    }else{
      this.sales_serials = [];
    }
    if (this.printError.length <= 0) {
      // made some changes due to duplicate and exist array not working by ajml
      this.resDup = this.coreService.checkSaleItem(this.salesItems, Number(selected.value.stkprdsel.prd_id), Number(selected.value.rate), selected.value.unit, selected.value.item_dicrptn, this.selBatch);
      var existingArray = this.coreService.findItemSales(this.salesItems, Number(selected.value.stkprdsel.prd_id), Number(selected.value.rate), selected.value.unit, selected.value.item_dicrptn, this.selBatch);
      if (this.resDup && existingArray.length > 0) {

      
        
        if(this.sales_settings.sales_item_grid_warning){
        
            let pname=existingArray?existingArray[0].prd_name:'Item';
            
             if(this.sales_settings.sales_item_grid_warning==1){
                 alert( pname+" Already Exist in this List");
               }else if(this.sales_settings.sales_item_grid_warning==2){
                 alert(pname +" Already Exist in this List,You Cannot Add");
                 return false;
               }
        
           
        }
        var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty;
        // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate;
        // this.taxvaltotqty = ((Number(existQty) + Number(selected.value.qty * this.base_qty)) * selected.value.tax_amnt / this.base_qty);
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty = Number(existQty) + Number(selected.value.qty);
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate = Number(existPrc) + Number(selected.value.rate);
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_tax_amnt = this.taxvaltotqty;
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty + this.base_qty;

        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_tax_amnt += Number(selected.value.tax_amnt);
        if(this.checkIsExciseEnble && this.excs_tax_cat_id){
          this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).excs_tax_amnt += Number(selected.value.excs_tax_amnt);
        }
       
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_amnt = (Number(existQty) + Number(selected.value.qty)) * Number(selected.value.rate);
     

        // code for saving fuel reading by ajml
        if(this.op_rd>0||this.cls_rd>0){
          this.readings.find(x => x.fr_prd_id === existingArray[0].salesub_prd_id).ob_reading = this.op_rd;
          this.readings.find(x => x.fr_prd_id === existingArray[0].salesub_prd_id).cb_reading = this.cls_rd;
        }
       
       
      } else {
        if (this.gd_id == undefined)
          this.gd_id = 0;

      
        
         
          if(selected.value.parent_product && selected.value.parent_product['prd_id'] > 0){
            
            this.is_addon =1;
            this.addon_prd_id =selected.value.parent_product['prd_id'];
            this.addon_prd = this.salesItems.filter(x => x.salesub_prd_id == this.addon_prd_id);
  
            // this.salesItems.find(x => x.sl_no === this.addon_prd[0].sl_no).as_child = 1;

            this.salesItems.find(x => x.salesub_prd_id === selected.value.parent_product['prd_id']).as_child = 1;
  
           
            this.is_parent_Prod =0;

          
          }

      
        
        else{
          this.is_parent_Prod =1;
          this.is_addon =0;
          this.addon_prd_id =0;
          this.as_child =0;

        }
        

        if(this.selBatch != undefined &&this.checkBatch){
           this.batch_code =this.batchs.find(batch => batch.sb_id ===this.selBatch ).sb_batch_code;
        }
        // code for saving fuel reading by ajml
        if(this.op_rd>0||this.cls_rd>0){
          this.readings=[];
          this.readings.push(
            {
              ob_reading: this.op_rd,
              cb_reading: this.cls_rd,
              fr_prd_id: selected.value.stkprdsel.prd_id,
              fr_unit_id:selected.value.unit
            }
          );
        }
       
       
        this.salesItems.push({
          "sl_no": (this.salesItems.length) + 1,
          "rate_org": this.rate_org,
          'salesub_prd_id': selected.value.stkprdsel.prd_id,
          'prd_name': selected.value.stkprdsel.prd_name,
          'prd_alias': selected.value.stkprdsel.prd_alias,
          'prd_code': selected.value.stkprdsel.prd_code,
          'ean': selected.value.stkprdsel.prd_ean,
          'salesub_stock_id': selected.value.stkprdsel.bs_stock_id,
          'salesub_qty': selected.value.qty,
          'salesub_rate': this.itemRate,
          'batch_code': (this.checkBatch&&this.selBatch != undefined) ?this.batch_code : null,
          'salesub_rate_with_tax': selected.value.rate,
          'salesub_amnt': this.itemRate * selected.value.qty,
          'salesub_tax_amnt': selected.value.tax_amnt,
          'salesub_tax': selected.value.tax_amnt / selected.value.qty,
          'salesub_tax_per': this.taxpercentage,
          'salesub_price': this.purchAmnt,
          'salesub_exchange_rate': this.exchange_rate,
          'salesub_currency_id': (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0,
          'purchpricesingle': this.itemRate / this.base_qty,
          'salesub_gd_id': this.gd_id,
          'taxvalperqty': selected.value.tax_amnt / this.base_qty,
          'base_qty': this.base_qty,
          'prd_unit_name': this.unit_name,
          'salesub_unit_id': selected.value.unit,
          'prd_barcode': selected.value.stkprdsel.prd_barcode,
          'prd_tax_cat_id': selected.value.prd_tax_cat_id,
          'item_desc': this.item_dicrptn,
          'item_disc': this.itemDisc,
          'prd_type': selected.value.stkprdsel.prd_type,
          'vat_type': selected.value.excl_vat,
          'item_disc_type': selected.value.item_disc_type,
          'sales_serials': this.sales_serials,
          'enable_serial_no': this.enable_serial_no,
          'excs_tax_cat_id':  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_cat_id:0,
          'excs_tax_perc':  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excstaxpercentage:0,
          'excs_tax_amnt':  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_amnt/ selected.value.qty:0,
          'excs_tax_type':  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_type:0,
          'sb_id': this.selBatch||'' ,
          'promo_disc': this.promo_disc, 
          'promo_per': this.promo_per, 
          'promo_id': (this.promo && this.promo.promo_id) ? this.promo.promo_id : 0 ,
          'sale_total_amount' : this.itemRate * selected.value.qty + selected.value.tax_amnt + (this.excs_tax_amnt) - this.itemDisc - this.promo_disc,
          'is_addon' :this.is_addon,
          'addon_prd_id' :this.addon_prd_id,
          'as_child' :this.as_child,
          'is_parent_Prod' : this.is_parent_Prod,
          'fuel_readings' : this.readings,
        
        });
        this.cls_rd=0;
        this.op_rd=0;
        this.readings=[];
    

      }
    
      this.addOnProdProcess();

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
     
      this.changeAmnt();
      selected = null;
      if (close == 'close')
        $('.close').trigger("click");
      // this.selctbarcode.nativeElement.focus();
      if (this.dflt_search_methd) {
        this.selectproductId.focus();
      } else {
        this.selctbarcode.nativeElement.focus();
      }
    }

  }


  editSaleItem(selected: { value: any; }, close) {
    
    this.validationMsg = '';

    if(this.checkIsExciseEnble){
        this.isExcsEdit=true;
    }
    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }

    if (this.qty == "" || this.qty <= 0) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }

    //Start  Batch Validation

    // if(this.checkBatch && this.selBatch == undefined){
    //   this.printError.push({ 'batchErr': 'Required' });
    //   $('#batch').css("border", "1px solid red");
    //   alert("Please Select Batch..");

    // }
    
    //End  Batch Validation



    if ((this.unit_available_stock <= 0 && this.disable_sales_without_stock) || (this.disable_sales_without_stock && (this.unit_available_stock.toFixed(3) < parseFloat(this.qty)))) {
      this.printError.push({ 'without_stock': 'Disabled' });
      alert("Sales without stock is disabled");
      return false;
    }

    const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === this.unit);
    var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
    this.rate_org = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    console.log('this.enable_sale_below_mrp',!this.enable_sale_below_mrp);
    console.log('this.cust_enable_sale_below_mrp',!this.cust_enable_sale_below_mrp);
    if ((((!this.enable_sale_below_mrp && (this.rate < this.rate_org)) && !this.cust_id) || ((this.rate < this.rate_org)&&!this.cust_enable_sale_below_mrp &&!this.enable_sale_below_mrp))) {
      this.printError.push({ 'below_mrp': 'Disabled' });
      alert("Sales below MRP is disabled");
      return false;
    }
    console.log('this.bs_prate',this.bs_prate);
    console.log('this.rate',this.rate);
    console.log('this.rate_org',this.rate_org);
    console.log('this.base_qty',this.base_qty);
    if (this.enable_sale_below_purch && (this.bs_prate*this.base_qty > this.rate)) {
      this.printError.push({ 'below_purch': 'Disabled' });
      alert("Sales below Purchase Rate is disabled");
      return false;
    }

    if(this.enable_serial_no){
      let qty_num = this.qty ? this.qty : 0;
      qty_num = parseInt(qty_num);
      if(this.sales_serials.length>qty_num){
        this.coreService.showMessage("Only "+qty_num+" Serial Numbers Are Allowed");
        return false;
      }
      if(this.sales_serials.length<qty_num){
        this.coreService.showMessage("Please enter serial number for all quantity");
        return false;
      }
  
      this.sales_serials.forEach((element, i) => {
        
        if(element.serial_no == ''){
          this.serialsErrors['serial_no_' + i] = {msg : 'Required'};
          this.printError.push({ 'serial_no': 'Required' });
        }else{
          delete this.serialsErrors['serial_no_' + i];
        }
      })
    }else{
      this.sales_serials = [];
    }

    // if(this.selctedProd.stock_qty<=0 && this.disable_sales_without_stock){
    //   this.printError.push({ 'without_stock': 'Disabled' });
    //   alert("Sales without stock is disabled");
    //   return false;
    // }

    if (this.printError.length <= 0) {
      this.selectedUnit(this.unit);

      // this.resDup = this.coreService.checkSaleItem(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // var existingArray = this.coreService.findItemSales(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // if (this.resDup && existingArray.length > 0) {

      //   var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty;
      //   // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate;
      //   this.taxvaltotqty = ((Number(existQty) + Number(selected.value.qty * this.base_qty)) * selected.value.tax_amnt / this.base_qty);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_qty = Number(existQty) + Number(selected.value.qty);
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_rate = Number(existPrc) + Number(selected.value.rate);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).salesub_tax = this.taxvaltotqty;
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty + this.base_qty;


      // } else {
      if (this.gd_id == undefined)
        this.gd_id = 0;
      // this.salesItems.push({
      //   "sl_no": (this.salesItems.length) + 1, 'salesub_prd_id': selected.value.stkprdsel.prd_id, 'prd_name': selected.value.stkprdsel.prd_name, 'ean': selected.value.stkprdsel.prd_ean, 'salesub_stock_id': selected.value.stkprdsel.bs_stock_id, 'salesub_qty': selected.value.qty,
      //   'salesub_rate': this.itemRate, 'salesub_amnt': this.itemRate * selected.value.qty, 'salesub_tax_amnt': selected.value.tax_amnt, 'salesub_tax': selected.value.tax_amnt / selected.value.qty, 'salesub_tax_per': this.taxpercentage, 'salesub_price': this.purchAmnt,
      //   'purchpricesingle': this.itemRate / this.base_qty, 'salesub_gd_id': this.gd_id, 'taxvalperqty': selected.value.tax_amnt / this.base_qty,
      //   'base_qty': this.base_qty, 'prd_unit_name': this.unit_name, 'salesub_unit_id': selected.value.unit, 'prd_barcode': selected.value.stkprdsel.prd_barcode, 'prd_tax_cat_id': selected.value.prd_tax_cat_id
      //   , 'item_desc': this.item_dicrptn, 'item_disc': this.itemDisc, 'prd_type': selected.value.stkprdsel.prd_type, 'vat_type': selected.value.excl_vat
      // });
   
      if(this.selBatch == ""){
        console.log("hloodddddd");
      }
      
      
      if(this.selBatch != undefined  && this.batchs != undefined &&this.checkBatch && this.selBatch != ""){
        this.batch_code =this.batchs.find(batch => batch.sb_id ===this.selBatch ).sb_batch_code;
     }
      selected.value.rate = (selected.value.excl_vat == 2) ?  selected.value.rate - selected.value.tax_amnt/selected.value.qty : selected.value.rate;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_prd_id = selected.value.stkprdsel.prd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_name = selected.value.stkprdsel.prd_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).ean = selected.value.stkprdsel.prd_ean;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_stock_id = selected.value.stkprdsel.bs_stock_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_qty = selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_rate = selected.value.rate;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_amnt = selected.value.rate * selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax_amnt = selected.value.tax_amnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax = selected.value.tax_amnt / selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_tax_per = this.taxpercentage;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_price = this.purchAmnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).purchpricesingle = this.itemRate / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_gd_id = this.gd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).taxvalperqty = selected.value.tax_amnt / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).base_qty = this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_unit_name = this.unit_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).salesub_unit_id = selected.value.unit;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_barcode = selected.value.stkprdsel.prd_barcode;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_tax_cat_id = selected.value.prd_tax_cat_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_desc = this.item_dicrptn;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc = this.itemDisc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_type = selected.value.stkprdsel.prd_type;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).vat_type = selected.value.excl_vat;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc_type = selected.value.item_disc_type;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).sales_serials = this.sales_serials;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).enable_serial_no = this.enable_serial_no;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).promo_disc = this.promo_disc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).promo_per = this.promo_per; 
     this.salesItems.find(x => x.sl_no === selected.value.sl_no).batch_code =(this.selBatch != undefined &&this.checkBatch)? this.batch_code:null;
     this.salesItems.find(x => x.sl_no === selected.value.sl_no).sb_id = this.selBatch;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).promo_id = (this.promo && this.promo.promo_id) ? this.promo.promo_id : 0;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).excs_tax_cat_id =(this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_cat_id:0;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).excs_tax_perc = (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excstaxpercentage:0;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).excs_tax_amnt =  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_amnt/selected.value.qty:0;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).excs_tax_type =  (this.checkIsExciseEnble && this.excs_tax_cat_id)?this.excs_tax_type:0;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).sale_total_amount =  selected.value.rate * selected.value.qty + selected.value.tax_amnt + this.excs_tax_amnt - this.itemDisc - this.promo_disc
      // }
       // code for saving fuel reading by ajml
       if(this.op_rd>0||this.cls_rd>0){
        this.salesItems.find(x => x.sl_no === selected.value.sl_no).fuel_readings=[];
        this.readings=[];
        this.readings.push(
          {
            ob_reading: this.op_rd,
            cb_reading: this.cls_rd,
            fr_prd_id: selected.value.stkprdsel.prd_id,
            fr_unit_id:selected.value.unit
          }
        );
        this.salesItems.find(x => x.sl_no === selected.value.sl_no).fuel_readings=this.readings;

       
      }


      if(this.checkParentProduct && selected.value.parent_product && selected.value.parent_product['prd_id'] > 0){
        this.is_addon =1;
        this.addon_prd_id =selected.value.parent_product['prd_id'];
        this.addon_prd = this.salesItems.filter(x => x.salesub_prd_id == this.addon_prd_id);

        // this.as_child = this.salesItems.find(x => x.sl_no === this.addon_prd[0].sl_no).as_child ? 1 : 0;

        // this.as_child = this.salesItems.find(x => x.salesub_prd_id === this.addon_prd_id).as_child ? 1 : 0;

        this.salesItems.find(x => x.salesub_prd_id === selected.value.parent_product['prd_id']).as_child = 1;
       
        this.is_parent_Prod =0;
      }
      else{
        this.is_parent_Prod =1;
        this.is_addon =0;
        this.addon_prd_id =0;
        this.as_child =0;

      }

      this.salesItems.find(x => x.sl_no === selected.value.sl_no).is_addon =this.is_addon;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).addon_prd_id =this.addon_prd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).as_child =this.as_child;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).is_parent_Prod =this.is_parent_Prod;

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
      this.changeAmnt();
     
      

      if (close == 'close')
        $('.close').trigger("click");

    }

  }


  // delete product from table
  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the List ?");
    if (remopstk) {
      var type = data.sl_no,
        i,k;
      for (i = this.salesItems.length - 1; i >= 0; --i) {
        if (this.salesItems[i].sl_no == type) {
          this.salesItems.splice(i, 1);
        }  
      }

      let slno = 1;
      this.salesItems.forEach((element, index) => {
        this.salesItems[index].sl_no = slno;
        slno++;
      });

      this.sumSaleTotal();
      this.changeAmnt();

      var item = this.salesItems.filter(x => x.addon_prd_id == data.addon_prd_id);
      this.addon_prd = this.salesItems.filter(x => x.salesub_prd_id == data.addon_prd_id);
      // this.salesItems.find(x => x.sl_no === this.addon_prd[0].sl_no).as_child = 1;
      
     
      if(item.length == 0){
        // this.salesItems.find(x => x.sl_no === this.addon_prd[0].sl_no).as_child = 0;
        this.salesItems.find(x => x.salesub_prd_id === data.addon_prd_id).as_child = 0;
        
        

      }


      this.addOnProdProcess();

    }

  }

  // Edit product from List
  editProd(data) {
    console.log("edit Product");
    console.log(data);
    
    
      
    if (data) {


      const searchval = new FormData();
      searchval.append('prd_id', data.salesub_prd_id);
      searchval.append('gd_id', data.salesub_gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('sales_inv_no', data.salesub_sales_inv_no);
      searchval.append('date', this.saleqt_date);
      this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
        this.selctedProd = res['data'];
        this.available_stock = this.selctedProd.stock_qty;
        this.unit_available_stock = this.selctedProd.stock_qty;
        this.single_weight =this.selctedProd.prd_weight;
       
        // code for saving fuel reading by ajml
        if(data.fuel_readings){
          this.op_rd=data.fuel_readings.ob_reading;
          this.cls_rd=data.fuel_readings.cb_reading;
        }
       

        if(this.checkIsExciseEnble){
          this.excs_tax_cat_id = data.excs_tax_cat_id;
          this.excs_tax_type = data.excs_tax_type?data.excs_tax_type:this.selctedProd.prd_excs_tax_type;
          this.updateExcsTaxPerc(this.excs_tax_cat_id);
        }
        this.unit_bs_prate = this.selctedProd.bs_prate;
        this.bs_prate = this.selctedProd.bs_prate;
        this.base_qty = data.base_qty;

        console.log('this.base_qty ',this.base_qty );


        this.batches = this.selctedProd.batches;
        this.batchs =this.selctedProd.batches;
        this.totalBatch =this.selctedProd.total_batch;
        if (this.purchtypesel == 1)
          this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);
      });
      this.shop_qty = this.selctedProd.bs_stock_quantity_shop;
      this.van_qty = this.selctedProd.bs_stock_quantity_van;
      this.selBatch = data.sb_id;

      this.getGodownWiseStock(data.salesub_prd_id);
      this.getBranchWiseStock(data.salesub_prd_id);
      if (this.cust_id) {
        this.getLastSaleToCust(data.salesub_prd_id, this.cust_id.cust_id);
      }
      this.apiService.geteditSalesItem(data).subscribe((res: any) => {

      
        this.sl_no = data.sl_no;
        this.selBatch = data.sb_id;
        this.stkprdsel = res.data.product;
        this.qty = data.salesub_qty;
        this.unit = data.salesub_unit_id;
        this.excl_vat = data.vat_type;
        if (this.excl_vat == 2) {
          this.rate = data.salesub_rate + data.salesub_tax;
        } else {
          this.rate = data.salesub_rate;
        }
        if (data.item_disc_type == 2)
          this.disc_amnt = (data.item_disc / (data.salesub_qty * data.salesub_rate)) * 100;
        else
          this.disc_amnt = data.item_disc;

        this.prd_tax_cat_id = Number(data.prd_tax_cat_id);
        this.item_dicrptn = data.item_desc;
        this.gd_id = data.salesub_gd_id;
        this.calcRates();
        $('#searchItem').trigger('click');
        this.addon_prd_id = data.addon_prd_id;

     

        this.parents = this.salesItems.filter(x => x.salesub_prd_id != data.salesub_prd_id && x.is_addon == 0);

       
        this.parents_products = [];
       // this.parent_product ={ id: 0, parent_product: 'None',prd_id :0 };
        this.parents_products.push({ "id": 0, 'parent_product': 'None','prd_id':0 });
        this.parents.forEach((element, index) => {
          let tmp = <any>{};
          tmp.id =index+1;
          tmp.parent_product = element.prd_name;
          tmp.prd_id = element.salesub_prd_id;
          this.parents_products.push(tmp);
        });

       

        var existQty = this.parents_products.find(x => x.prd_id ===  data.addon_prd_id);

        this.parent_product = existQty;
        this.edit_as_child =data.as_child;


      });
      this.enable_serial_no = data.enable_serial_no;
      if(data.sales_serials){
        this.sales_serials = data.sales_serials;
      }else{
        this.sales_serials=[];
      }

     
     // this.parent_product =data.addon_prd_id;
      this.isEdit = true;
      this.updateStock();
 

    }
  }

  sumSaleTotal() {

    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totExcsAmnt = 0;
    this.totPromoDisc = 0;

    for (var i = 0; i < this.salesItems.length; i++) {

      this.totItemprice = Number(this.totItemprice) + (Number(this.salesItems[i]['salesub_qty']) * Number(this.salesItems[i]['salesub_rate']));
      this.totItemDisc = Number(this.totItemDisc) + Number(this.salesItems[i]['item_disc']);
      this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['salesub_tax_amnt']);
      if(this.checkIsExciseEnble){
        this.totExcsAmnt = Number(this.totExcsAmnt) + Number(this.salesItems[i]['excs_tax_amnt']?this.salesItems[i]['salesub_qty'] *this.salesItems[i]['excs_tax_amnt']:0);

      }
      this.totPromoDisc = Number(this.totPromoDisc) + Number(this.salesItems[i]['promo_disc']?this.salesItems[i]['promo_disc']:0);


    }
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        prnt_sign_section_display : res.data.prnt_sign_section_display,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }

      // put here for getting header & footer height before startPrint()
      this.headerheightInmm=this.savedTemplate.header_height;
      this.footerheightInmm=this.savedTemplate.footer_height;
      this.pagelabelBottomInmm=this.savedTemplate.botom_h;
      this.pagelabelLeftInmm=this.savedTemplate.left_h;
      
      this.hide_prnt_alias = res.data.prnt_show_alias;
      this.hide_prnt_time = res.data.prnt_show_time;
      this.hide_prnt_cust_bal = res.data.prnt_cust_show_bal;
      this.hide_prnt_pay_type = res.data.prnt_show_pay_type;
      this.hide_prnt_preview_h_f_show = res.data.prnt_preview_deflt_hdrftr_show;
      this.hide_print_header_footer = this.hide_prnt_preview_h_f_show == 1 ? true : false ;

      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2 || this.print_style == 6 || this.print_style == 7) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
        
      } else {
        this.tempImgUrl = '';
        this.footImgUrl = '';
      }

      this.baseUrl = this.apiService.PHP_API_SERVER;
      this.branchLogo = this.branch_img_url ;

      

      if(res.data.water_mark_img_path){
        this.water_mark = this.apiService.PHP_API_SERVER + "/" + res.data.water_mark_img_path;
      } else {
        this.water_mark = "";
      }

      this.hide_item_discount = res.data.hide_item_discount;
      this.hide_vat = res.data.hide_vat;
      
    });
  }


  selectTaxType(ptypeid) {

    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }
    //
    // ];


    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);

      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
        this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      }
      this.zeroType = true;
    } else {
      this.zeroType = false;

    }
  }

  draftQuotation(formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sale_items = this.salesItems;
    // formdata.value.terms = this.terms;
    formdata.value.sales_note = this.sales_note;

    this.draftLoader = true;

    this.apiService.draftSalesQuotn(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message);
        this.draftLoader = false;
        this.getDraftList();


      }
    });
  }

  getDraftList() {

    this.apiService.getSalesDraftList('').subscribe((res) => {
      this.draftList = res.data;

    });
  }

  removeSaleFromDraft(draft_id) {

    if (confirm("Are You Sure You Want To Remove This Quotation?")) {
      let searchval = new FormData();
      searchval.append("sqdrf_id", draft_id);
      this.apiService.removeSalesDraft(searchval).subscribe((res) => {
        this.coreService.showMessage("Removed Successfully");
        this.getDraftList();

      });
    }
  }


  getDraftDet(draft_id) {
    let searchval = new FormData();
    searchval.append("sqdrf_id", draft_id);
    this.imporDrafttLoader = true;

    this.apiService.getSalesDraft(searchval).subscribe((res: any) => {

      this.salesItems = res.data.sales_drft_sub;
      if (res.data.sqdrf_valid_till) {
        this.valid_till_date = new Date(res.data.sqdrf_valid_till);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sqdrf_date);
      this.cust_type = res.data.sqdrf_cust_type;
      this.cust_code = res.data.sqdrf_cust_code;
      this.cust_name = res.data.sqdrf_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sqdrf_cust_address;
      this.cntct_num = res.data.sqdrf_cust_phone;
      this.vat_no = res.data.sqdrf_cust_tin;
      this.net_disc = res.data.sqdrf_disc;
      this.round_sign = 1;
      this.roundoff = 0;
      this.sqdrf_id = res.data.sqdrf_id;
      this.sales_note = res.data.sqdrf_notes;


      // this.sale_agent = res.data.sqdrf_sales_agent;
      this.sumSaleTotal();
      this.changeAmnt();

      this.imporDrafttLoader = false;

      $('.close').trigger("click");



    });
  }
  searchInp(keyword) {
    let searchval = new FormData();
    if (keyword)
      searchval.append("keyword", keyword);
    
 if(this.invoice_type &&this.eInvoiceSupport){
  searchval.append("invoiceType_id",this.invoice_type);
}

      if(this.usr_hide_others_sales_data){
        let hide_other='hide';
        searchval.append("hide_others", hide_other);
      }
    this.searchLoadingImg = true;
    this.apiService.getSalesInvList(searchval, 1).subscribe((res) => {

    
      this.list_sales = res.data.data;
      this.agent_check = res.agent_check;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
    });
  }
  searchInput(keyword){
    let searchval = new FormData();
    if (keyword)
      searchval.append("keyword", keyword);
 if(this.invoice_type &&this.eInvoiceSupport){
  searchval.append("invoiceType_id",this.invoice_type);
}
      if(this.usr_hide_others_sales_data){
        let hide_other='hide';
        searchval.append("hide_others", hide_other);
      }
    this.searchLoadingImg = true;
    this.apiService.getSalesdescription(searchval, 1).subscribe((res) => { 
      this.list_sales = res.data.data;
      this.agent_check = res.agent_check;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;

    })


  }

  listQtn(pageNo = 1) {
    let searchval = new FormData();
    // if(keyword)
    // searchval.append("keyword", keyword);
    
     if(this.invoice_type &&this.eInvoiceSupport){
       searchval.append("invoiceType_id",this.invoice_type);
     }
    if(this.usr_hide_others_sales_data){
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    // searchval.append("invoiceType_id",this.invoiceId);
    if(this.period_type){
      searchval.append("period_type",this.period_type);
    }
    if(this.convert_type){
      searchval.append("convert_type",this.convert_type);
    }
   
    if(this.pay_type){
      searchval.append("pay_type",this.pay_type);
    }
    
    // searchval.append("added_by",this.added_by);
    this.searchLoadingImg = true;
    this.apiService.getSalesInvList(searchval, pageNo).subscribe((res) => {
      

      this.list_sales = res.data.data;
      this.agent_check = res.agent_check;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
      this.headerSalesEinvoiceLink=null;
    });
  }

  tabClick(tab: { index: number; }) {
    
    if(tab.index==1 && !this.saleEditLogLoaded){
      this.getEditLogList(1);
    }
  }
  getEditLogList(pageNo = 1) {
    let searchval = new FormData();
    this.logLoadingImg = true;
    this.apiService.getSalesInvLogList(searchval, pageNo).subscribe((res) => {
      this.saleEditLogLoaded = true;
      this.saleEditLog = res.data.data;
      this.log_curpage = res.data['current_page'];
      this.log_lastpage = res.data['last_page'];
      this.log_from = res.data['from'];
      this.log_pgEnd(this.log_curpage, this.log_lastpage);
      this.log_pgStart(this.log_curpage);
      this.logLoadingImg = false;
    });
  }
  log_pgEnd(curr, end) {
    if (curr == end)
      this.log_pgend = true;
    else
      this.log_pgend = false;

  }

  log_pgStart(curr) {
    if (curr == 1)
      this.log_pgstart = true;
    else
      this.log_pgstart = false;
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  importSales(data) {
    this.getAdditonalCharge();

    if (data.sales_due_sub.length <= 0) {
      this.is_sale_edit = true;

      let searchval = new FormData();
      searchval.append("sales_inv_no", data.sales_inv_no);
      this.importLoader = true;
      this.apiService.getSalesDet(searchval).subscribe((res: any) => {
        
        this.sales_is_del_chellan = res.data.sales_is_del_chellan;
        this.saleRefNo = res.data.sales_inv_no;
        this.qt_no = res.data.sales_branch_inv;
        this.sq_inv_no = res.data.sales_inv_no;
        this.cnvert_ref_no = res.data.sales_cnvert_ref_no;
        this.cntctPerson = res.data.sales_contct_prsn;
        this.purchtypesel = res.data.sales_tax_type + 1;
        this.inv_sub_type=res.data.zatca_inv_sub_type.toString().padStart(5, '0');

        this.salesItems = res.data.sales_sub;
        this.edit_service_additional_charges = res.data.additional_chrg;
        this.ServiceAdditionaltotalAmount=res.data.sales_additional_charges ? res.data.sales_additional_charges:0;
        if (res.data.sales_due_date) {
          this.valid_till_date = new Date(res.data.sales_due_date);
        } else {
          this.valid_till_date = '';
        }

        if (res.data.sales_inv_type == 1) {
          this.inv_type = 1;
        } else if (res.data.sales_inv_type == 2) {
          this.inv_type = 2;
        } else {
          this.inv_type = 3;
        }

        this.saleqt_date = new Date(res.data.sales_date);

        if (res.data.sales_cust_type == 0) {
          this.cust_type = 1;
        } else if (res.data.sales_cust_type == 1) {
          this.cust_type = 2;
        }

        if (res.data.sales_pay_type == 0) {
          this.sale_pay_type = 2;
        } else if (res.data.sales_pay_type == 1) {
          this.sale_pay_type = 1;
          this.sale_acc_ledger_id =''
        } else if (res.data.sales_pay_type == 2) {
          this.sale_pay_type = 3;
        }else if (res.data.sales_pay_type == 3) {
          this.sale_pay_type = 4;
        }

        if(res.data.sales_pay_type==3){
          this.defLedgerBank = res.data.bank_ledgers;
          this.defLedgerCash = res.data.cash_ledgers;
          this.sale_acc_ledger_id_bank = res.data.sale_acc_ledger_id_bank;
          this.sale_acc_ledger_id_cash = res.data.sale_acc_ledger_id_cash;
          this.cash_amnt = res.data.cash_amnt;
          this.card_amnt = res.data.card_amnt;
        }else{
          if (res.data.sales_acc_ledger_id > 0) {
            this.defLedger = res.data.acc_ledger;
            this.sale_acc_ledger_id = res.data.sales_acc_ledger_id;
          }
  
        }
       
        this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
        if(res.data.customer && res.data.customer.price_group_id){
          this.price_group_id = res.data.customer.price_group_id;
        }
        if(res.data.customer && res.data.customer.default_currency_id &&this.multipleCurrency){
          this.cust_default_currency_id = res.data.customer.default_currency_id;
          this.selcurrency = this.currencies.find(x => x.cur_id == this.cust_default_currency_id);
          this.exchange_rate = res.data.sales_exchange_rate?res.data.sales_exchange_rate:this.selcurrency.cur_exchange_rate;
        }else{
          this.selcurrency = null;
          this.exchange_rate = 1;
        }
        
        this.cust_name = res.data.sales_cust_name;
        this.cust_enable_sale_below_mrp = (res.data.customer ? res.data.customer.enable_sale_below_mrp : 0);
        this.cust_id = res.data.customer;
        this.cust_addr = res.data.sales_cust_address;
        this.delivry_addr = res.data.sales_dlvry_addrs;
        this.cntct_num = res.data.sales_cust_ph;
        this.vat_no = res.data.sales_cust_tin;
        this.reference = res.data.sales_reference;
        this.net_disc = res.data.sales_discount;
        this.round_sign = (res.data.sales_roundoff && res.data.sales_roundoff< 0) ? -1 : 1;
        this.roundoff = Math.abs(res.data.sales_roundoff);
        this.sales_note = res.data.sales_notes;
        this.sales_note2 = res.data.sales_notes2;
        this.sales_note3 = res.data.sales_notes3;
        this.terms = res.data.sales_terms;
        this.sale_agent = res.data.sales_agent_ledger_id;
        this.gd_id = res.data.godown_id;
        this.sumSaleTotal();
        this.changeAmnt();

        this.importLoader = false;

        this.listing = false;
        this.is_description = this.salesItems.filter(x => x.item_desc !== '');

        this.editMessage = true;
        this.editAdditionalServ = true;

      });
    }
  }

  previewSales(id, balance, data = null) {
    // data.sales_inv_type
    if(data && data.is_e_inv && data.sales_inv_type == 2 && this.print_style_tmp == 1000){
      if(this.einv_simplfied_print_style == 1){
        this.print_style = 1001;
      } else {
        this.print_style = 1002;
      }
    } else{
      this.print_style = this.print_style_tmp;
    }
    let searchval = new FormData();
    searchval.append("sale_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesInvPreview(searchval).subscribe((res: any) => {
      if (res.data) {
        console.log('inv previeeeeeww')
        console.log(res.data['preview'])
        this.saleQtnData = res.data['preview'];
        this.is_prew_description = this.saleQtnData['sales_sub'].filter(x => x.item_descrp);
        this.previewLoader = false;
        this.whatsapplink = 'Dear Customer ,' + '%0A%0A'
          + 'Here is your invoice bill no' + '%20' + '%2A' + this.saleQtnData.sq_inv_no + '%2A' + '%20' + 'amounting in' + '%20' + '%2A' + this.saleQtnData.tot_amount  + '%2A'+'%20'+ this.saleQtnData.tot_amount_prefix  + '.'

          + '%0A%0A' + '%2A' + '_Details :-_' + '%2A' + '%0A%0A' + ''
          + 'Name' + '%20%20%20%20%20%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_cust_name + '%2A' + '%0A'
          + 'Invoice No' + '%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_inv_no + '%2A' + '%0A'
          + 'Invoice Date' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_date + '%2A' + '%0A'
          + 'Due Date' + '%20%20%20%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_valid_till + '%2A' + '%0A' +

          '______________________________' + '%0A%0A'
          + 'Total Bill Amount' + '%3A' + '%20' + '%2A' + this.saleQtnData.tot_amount + '%2A' + '%2F-' + '%0A'
        if (balance > 0) {
          this.whatsapplink += 'Total Due Amount' + '%20' + '%3A' + '%20' + '%2A' + balance.toFixed(2) + '/-' + '%2A' + '%0A';
        }
        this.whatsapplink += 'Total Bill Amount In Words' + '%3A' + '%20' + '%2A' + this.saleQtnData.total_amount_in_words + '%2A' + '%0A%0A';

        if (balance > 0) { this.whatsapplink += 'Please remit payment at earliest convinience.' + '%0A%0A'; }
        this.whatsapplink += '%2A' + '_Thank you for shoping_' + '%2A' + '%20';

        // this.whatsapplink2 = 'Dear Customer ,' + '%0A%0A' + 'Here is your invoice bill no' + '%20' + '%2A' + this.saleQtnData.sq_inv_no + '%2A' + '%20' + 'amounting in' + '%20' + '%2A' + this.saleQtnData.tot_amount + '%20' + 'SR' + '%2A' + '.'  + '%0A' + '%0A' + '%2A' + '_Details :-_' + '%2A' + '%0A%0A' + '' +  'Name' + '%20%20%20%20%20%20%20%20%20%20%20%20' + '%3A'
        //   + '%20' + '%2A' +this.saleQtnData.sq_cust_name + '%2A' +'%0A' + 'Invoice No' + '%20%20%20' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_inv_no + '%2A' + '%0A' +'Invoice Date' + '%3A' + '%20' + '%2A' + this.saleQtnData.sq_date
        //     + '%2A' + '%0A' + 'Due Date' + '%20%20%20%20%20%20' + '%3A' + '%20' +'%2A' +  this.saleQtnData.sq_valid_till +'%2A' +  '%0A' + '______________________________' + '%0A%0A' + 'Total Amount' + '%3A'  + '%20' +'%2A' +  this.saleQtnData.tot_amount + '%2A' + '%2F-' + '%0A' +  'Total Amount In Words' + '%3A' + '%20' + '%2A' + this.saleQtnData.total_amount_in_words +'%2A' +  '%0A%0A' + '%2A' + '_Thank you for shoping_' + '%2A'+'%20';

        // if(balance==0){
        //   this.whatsapplink=this.whatsapplink2;
        // }else{
        //   this.whatsapplink=this.whatsapplink1;
        // }

        // let link = 'http://127.0.0.1:8000/';
        // if(this.apiService.PHP_API_SERVER == "https://bquickerp.com/backend"){
        //   link = 'https://bquickerp.com/';
        // }
        // if(this.apiService.PHP_API_SERVER == "https://alhazmicloud.com/backend"){
        //   link = 'https://alhazmicloud.com/';
        // }
        // if(this.apiService.PHP_API_SERVER == "https://fujishka.vip//backend" || this.apiService.PHP_API_SERVER == "https://fujishka.vip/backend"){
        //   link = 'https://fujishka.vip/';
        // }
        // if(this.apiService.PHP_API_SERVER == "http://bquickerp.in/backend"){
        //   link = 'https://bquickerp.in/';
        // }
        // this.qr_inv = link + this.saleQtnData['qr_link'];

        this.qr_inv = this.saleQtnData['qr_link'];

        if(this.list_sales[this.downloadingIndex].pdfLoadingImg){
        if (this.saleQtnData) {
          this.data = this.saleQtnData.sales_sub;
          this.ttlPage = Math.ceil(this.saleQtnData.sales_sub.length /this.noRowPerPageInv);
          this.lastpageIteemCount = this.saleQtnData.sales_sub.length % this.noRowPerPageInv;
          if(this.lastpageIteemCount == 0 || this.lastpageIteemCount > 7){
            this.ttlPage = this.ttlPage +1;
          }
          this.noPage = this.createRange(this.ttlPage);
          this.generateSalesOrderPdf1();
        }
      }
      }
    });
  }

  packingListPreview(id, balance, data = null){
    let searchval = new FormData();
    searchval.append("sale_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesInvPreview(searchval).subscribe((res: any) => {
      console.log(res);
      
      if (res.data){
        let master= <any>{};
        master.sq_id = res.data.preview.sq_id;
        master.sq_cust_name = res.data.preview.sq_cust_name;
        master.sq_cust_address = res.data.preview.sq_cust_address;
        master.sq_cust_ph = res.data.preview.sq_cust_ph;
        master.sq_date = new Date(res.data.preview.sq_date);
        master.sq_inv_no = res.data.preview.sq_inv_no;
        master.sq_branch_qt_no = res.data.preview.sq_branch_qt_no;
        master.cust_code = res.data.preview.cust_code;
        master.del_chellan_po_no = res.data.preview.sales_reference;
        master.sq_cust_tin = res.data.preview.sq_cust_tin;
        master.sq_note = res.data.preview.sales_notes;
        master.sq_note2 = res.data.preview.sales_notes2;
        master.sq_note3 = res.data.preview.sales_notes3;
        master.sales_contct_prsn = res.data.preview.sales_contct_prsn;

        this.packingListSub = [];

        res.data.preview.sales_sub.forEach((element, index) => {
          let sub= <any>{};
          sub.sl_no = index +1;
          sub.prd_code = element.prd_code;
          sub.prd_name = element.prd_name;
          sub.prd_alias = element.prd_alias;
          sub.item_descrp = element.item_descrp;
          sub.prd_tax_code = element.prd_tax_code;
          sub.manftr_comp_name = element.manftr_comp_name;
          sub.unit_display = element.unit_display;
          sub.sqs_qty = element.sqs_qty;
          sub.unit_base_qty = 1;
          this.packingListSub.push(sub);
          
        });

        master.delivery_challan_sub = this.packingListSub;
        this.packingList = master;
        
        
      }
      this.previewLoader = false;
    });
  }

  openPopUp(stkprdsel) {
  
    if(stkprdsel != null){
      this.selectedProduct(stkprdsel.prd_id, this.gd_id, stkprdsel);
      $('#searchItem').trigger('click');
    }
   
  }

  getLogDet(id) {
    let searchval = new FormData();
    searchval.append("sale_inv_no", id);
    this.editLogpreviewLoader = true;
    this.saleEditLog = [];

    this.apiService.getLogPreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.saleEditLog = res.data;

      }
      this.editLogpreviewLoader = false;

    });
  }
  


  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = 'createCustOrSup';
    if (this.userType == 'ADMIN') {
      this.customer_dt.usr_type = 1;
    } else {
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
     
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.resultobj = {};
        this.modalRef.close();
        this.clearForm();
      }
    });
  }
  clearForm() {
    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email: '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id: 0,
      usr_type: 0,
      is_supplier: false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
 
  }

  // checkDisplaySQMCalc() {
  //   this.apiService.getClientSettingBykey({ key: 'show_sales_sqm_calc' }).subscribe((res) => {
  //     if (res['data']) {
  //       this.showSqmCalc = (res['data']['cs_value']) ? true : false;
  //     }
  //   });
  // }
  // checkDisplaySalesImport() {
  //   this.apiService.getClientSettingBykey({ key: 'show_sales_file_import' }).subscribe((res) => {
  //     if (res['data']) {
  //       this.showFileImport = (res['data']['cs_value']) ? true : false;
  //     }
  //   });
  // }

  addMoreRow() {
    this.sqmCalc.push(
      {
        height: 0,
        width: 0,
        qty: 1,
        sqm: 0
      }
    );
  }

  remove(index) {
    this.sqmCalc.splice(index, 1);
    this.updateTotoal();
  }
  sqmCalculate(index) {
    this.sqmCalc[index].sqm = this.sqmCalc[index].height * this.sqmCalc[index].width * this.sqmCalc[index].qty / 10000;
    this.updateTotoal();
  }
  updateTotoal() {
    this.ttlSqm = 0;
    this.sqmCalc.forEach(element => {
      this.ttlSqm += Number(element.sqm);
    });
  }
  clearAll() {
    this.sqmCalc = [
      {
        height: 0,
        width: 0,
        qty: 1,
        sqm: 0
      }
    ]
    this.updateTotoal();
  }
  apply() {
    this.qty = this.ttlSqm;
    let desc = '';
    this.sqmCalc.forEach(element => {
      desc += element.height + 'H x ' + element.width + 'W x ' + element.qty + " QTY, ";
    });
    this.item_dicrptn = desc.replace(/,\s*$/, "");
    this.calcRates();
    $('.closbtn').parents('.modal:first').hide();
    $('#btnsqlcalc').click();
  }

  uploadFile(form) {
    this.fileImpBtnShow = false;
    this.fileImpErr = '';
    this.fileImpWar = '';
    this.pageLoadingImg = true;
    let searchval = new FormData(form);
    this.apiService.importSalesValidate(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.wrngmsg && res.wrngmsg.length > 0) {
        this.fileImpWar = '----------------WARNINGS----------------<br>';
        res.wrngmsg.forEach((element, key) => {
          this.fileImpWar += (key + 1) + '. ' + element + '<br>'
        });
      }

      if (res.error) {
        if (res.errmsg && res.errmsg.length > 0) {
          this.fileImpErr = '----------------ERRORS----------------<br>';
          res.errmsg.forEach((element, key) => {
            this.fileImpErr += (key + 1) + '. ' + element + '<br>'
          });
        }
        this.fileImpBtnShow = false;
      } else {
        this.fileImpBtnShow = true;
        this.fileImpinfo = res.info;
        this.resultobj = [];
      }

    });
  }

  completeSalesImport(stepper, step1, formdata) {
    this.pageLoadingImg = true;
    this.apiService.importSales({ sales: this.fileImpinfo }).subscribe((res) => {
      if (res.error) {
        this.pageLoadingImg = false;
        if (res.errmsg && res.errmsg.length > 0) {
          this.fileImpErr = '----------------ERRORS----------------<br>';
          res.errmsg.forEach((element, key) => {
            this.fileImpErr += (key + 1) + '. ' + element + '<br>'
          });
        }
      } else {
        this.coreService.showMessage(res.message);
        $('.fileclose').trigger('click');
        this.pageLoadingImg = false;
        this.fileImpBtnShow = false;
        this.fileImpErr = '';
        this.fileImpWar = '';
        formdata.reset();
        this.listQtn();
      }
    });
  }
  searchDeliverychellan(del_chellan_inv_no) {
    const searchval = new FormData();
    searchval.append("del_chellan_inv_no", del_chellan_inv_no);
    this.apiService.searchDeliverychellan(searchval).subscribe((res) => {
      // console.log(res['data']);
      this.challan = res['data'];
      //  console.log(this.challan);
    });

  }
  previewSales1(del_chellan_inv_no) {
    let searchval = new FormData();
    searchval.append("del_chellan_inv_no", del_chellan_inv_no);
    this.listing = false;
    // this.previewLoader1 = true;
    this.apiService.getAllDelChallanDet(searchval).subscribe((res: any) => {
      // console.log(res.mul_del_chellan_inv_number);
      // this.salesItems = res.data.delivery_challan_sub;

      // if (res.data.del_chellan_date) {
      //   this.valid_till_date = new Date(res.del_chellan_date);
      // } else {
      //   this.valid_till_date = '';
      // }
      // this.saleqt_date = new Date(res.del_chellan_date);
      this.valid_till_date = '';
      this.saleqt_date = new Date();
      if (!this.cust_id) {
        //this.cust_type = res.del_chellan_cust_type;
        this.cust_code = res.del_chellan_cust_code;
        this.cust_name = res.del_chellan_cust_name;
        this.cust_id = res.customer;
        this.cust_addr = res.del_chellan_cust_address;
        this.cntct_num = res.del_chellan_cust_ph;
        this.vat_no = res.del_chellan_cust_tin;
        this.sales_note = res.del_chellan_notes;
        this.terms = res.del_chellan_terms;
        this.reference=res.del_chellan_reference;
        this.cust_type = 2;
      }
      this.net_disc = 0;
      this.round_sign = 1;
      this.roundoff = 0;
      this.inv_type = this.sales_settings.ss_inv_type;
      this.mul_del_chellan_inv_number = res.mul_del_chellan_inv_number;
      // let sl_no =0;
      res.data.forEach((element, index) => {

        var existArrayIndex = this.salesItems.findIndex(
          (x => x.salesub_prd_id === element.del_chellan_sub_prd_id &&
            (x.item_desc == element.item_desc) &&
            (x.salesub_unit_id === element.del_chellan_sub_unit_id) &&
            x.salesub_rate === element.del_chellan_sub_rate)
        );

        if (existArrayIndex > -1) {

          this.salesItems[existArrayIndex].salesub_qty = Number(this.salesItems[existArrayIndex].salesub_qty) + Number(element.del_chellan_sub_qty);
          this.salesItems[existArrayIndex].salesub_tax_amnt = Number(this.salesItems[existArrayIndex].salesub_tax_amnt) + Number(element.del_chellan_sub_tax_amnt);
          this.salesItems[existArrayIndex].salesub_amnt = Number(this.salesItems[existArrayIndex].salesub_amnt) + Number(element.del_chellan_sub_rate * element.del_chellan_sub_qty);

        } else {
          // sl_no++;
          //console.log(this.salesItems.length);
          let tmp = <any>{};
          tmp.salesub_prd_id = element.del_chellan_sub_prd_id;
          tmp.sl_no = this.salesItems.length + 1;
          // tmp.sl_no = index+1;
          tmp.prd_name = element.prd_name;
          tmp.ean = element.ean;
          tmp.salesub_stock_id = element.del_chellan_sub_stock_id;
          tmp.salesub_qty = element.del_chellan_sub_qty;
          tmp.salesub_rate = element.del_chellan_sub_rate;
          tmp.salesub_amnt = element.del_chellan_sub_rate * element.del_chellan_sub_qty;
          tmp.salesub_tax_amnt = element.del_chellan_sub_tax_amnt;
          tmp.salesub_tax = element.del_chellan_sub_tax;
          tmp.salesub_tax_per = element.del_chellan_sub_tax_per;
          tmp.salesub_price = element.del_chellan_sub_price;
          tmp.purchpricesingle = element.del_chellan_sub_rate / element.base_qty;
          tmp.salesub_gd_id = element.del_chellan_sub_gd_id;
          tmp.taxvalperqty = element.del_chellan_sub_tax / element.base_qty;
          tmp.base_qty = element.base_qty;
          tmp.prd_unit_name = element.prd_unit_name;
          tmp.salesub_unit_id = element.del_chellan_sub_unit_id;
          tmp.prd_barcode = element.prd_barcode;
          tmp.prd_tax_cat_id = element.prd_tax_cat_id;
          tmp.item_desc = element.item_desc;
          tmp.item_disc = element.item_disc;
          tmp.prd_type = element.prd_type;
          tmp.vat_type = element.vat_type;
          tmp.item_disc_type = element.item_disc_type;
          tmp.sale_total_amount = element.del_chellan_sub_rate * element.del_chellan_sub_qty + element.del_chellan_sub_tax_amnt - element.item_disc;
          this.salesItems.push(tmp);
        }
      });

      // // this.sale_agent = res.data.sqdrf_sales_agent;
      this.sumSaleTotal();
      this.changeAmnt();
      // this.importLoader = false;

      // this.listing = false;

      // this.is_description = this.salesItems.filter(x => x.item_desc !== '');
      this.showQtnImpLoader = false;




    });
    this.mul_del_chellan_inv_number = '';
    // this.del_chellan_inv_no ='';
  }

  rate_history(prd_id) {
 
    this.hist_prd_id=prd_id;
    this.desktoken =localStorage.cmptoken;
   
    // if(this.usr_hide_others_sales_data){
    //   this.user_id=this.user_id;
  
    //   }else{
    //     this.user_id='';
    //   }
    //console.log(localStorage.setItem("cmptoken", res.cmptoken););
    
    $("#ifrm").attr("src", environment.php_api_server + "/product-history?prod_id="+prd_id +"&desktoken="+this.desktoken+"&user="+this.user_id+"&Lang="+this.translate.currentLang);
    
    // const searchval = new FormData();
    // searchval.append("product_id", prd_id);
    // this.apiService.rate_history(searchval).subscribe((res) => {
    //   this.rate_histry = res['data']['sales'];
    //   this.product_name = res['data']['product'];
    //   // console.log(this.product_name);

    // });



  }


  @HostListener('document:keydown.enter', ['$event.target'])
  handleEnterKey(target: HTMLElement) {
    // Check if the target element is not an input or textarea
    if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {

    
      if(this.matindex==1){
        if(this.sale_pay_type==4){
          this.secndPayButton.nativeElement.click()
        }else{
          this.secndnextButton.nativeElement.click()
        }
        
      }else if(this.matindex !=2 && this.matindex !=3){
        this.firstnextButton.nativeElement.click()
      }
    }

  }
  onStepSelected(event: any) {
    if (event.selectedIndex == event.selectedIndex && event.previouslySelectedIndex != event.selectedIndex) {

      this.matindex=event.selectedIndex;
      // console.log(`Selected step:` ,event.selectedIndex);
      // console.log('hehehe',event.previouslySelectedIndex);
      // console.log('sui',this.matindex);
      
    }
  }

  langChange(){
    this.translate.get(['Common.tax_invoice', 'Common.simplified_tax_invoice', 'Common.invoice', 'Common.new', 'Common.registerd','HOME.credit','Common.cash','HOME.Card_Bank','Common.standard_rated_domestic','Common.zero_rated_domestic','Common.exempt_sales','Common.both',
  'Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing','Purchase.refno','HOME.Invoice_no','Purchase.date','Common.customer_name','Common.sales_agent_name','Common.Pay_Type','HOME.paid_status','Table.amnt','Common.Balance_Amount','Common.notes','HOME.location','Common.Delivery_Status','Purchase.convert_no','Branch.e_invoice','HOME.Bank','settings.Multi']).subscribe((res: string) => { 
   this.disp_col = [
      { id: "ref_no",  name: res["Purchase.refno"], show: true },
      { id: "inv_no", name: res["HOME.Invoice_no"], show: true },
      { id: "date", name: res["Purchase.date"], show: true },
      { id: "cus_name", name: res["Common.customer_name"], show: true },
      { id: "sale_agent", name: res["Common.sales_agent_name"], show: false },
      { id: "pay_typ", name:  res["Common.Pay_Type"], show: false },
      { id: "paid_sts", name: res["HOME.paid_status"], show: true },
      { id: "amnt", name:res["Table.amnt"], show: true },
      { id: "balnc_amnt", name: res["Common.Balance_Amount"], show: true },
      { id: "note", name: res["Common.notes"], show: false },
      { id: "location", name: res["HOME.location"], show: false },
      { id: "delivery_sts", name:res["Common.Delivery_Status"], show: false },
      { id: "convrt_no", name: res["Purchase.convert_no"], show: false },
     
    ];
    if(this.eInvoiceSupport){
      this.disp_col.push({ id: "e_invoice", name: res["Branch.e_invoice"], show: true });
    }
      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this._invoice = res['Common.invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this.parents_products = [
        { id: 0, parent_product: res['Common.none'],prd_id :0},
      
      ];
      
      if(!this.eInvoiceSupport){
        this.inv_types.push({ id: 3, name:  this._invoice });
      }
      this.cust_types = [
        { id: 1, name: res['Common.new']},
        { id: 2, name: res['Common.registerd'] },
  
      ];
      this.salesPayType = [
        { id: 1, name: res['HOME.credit'] },
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Card_Bank'] },
        { id: 4, name: res['Common.both'] }
    
      ];

      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic']},
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_sales'] }
      ];
      this.cust_types = [
        { id: 1, name:  res['Common.new'], disabled: true },
        { id: 2, name:  res['Common.registerd'] }
      ];
      this.filter_paytype = [
        { id: 1, name: res['HOME.credit'] },
        { id: 4, name: res['Common.cash'] },
        { id: 2, name: res['HOME.Bank'] },
        { id: 3, name: res['settings.Multi'] }
    
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
    
    });

    
  }
  updateStock(){

     console.log("this.selBatch");
     console.log(this.selBatch);
    if(this.selBatch > 0){
      // this.selctedProd.stock_qty = 333
      // getGodownBatchStock(){}
      const searchval = new FormData();
      searchval.append('prd_id', this.selctedProd.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id', this.selBatch);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

       // this.selctedProd.stock_qty = resp['data'].gbs_qty || 0;
       this.selctedProd.stock_qty = resp['data']['gdStock'].gbs_qty || 0;
       if(this.price_group_id == 1){
        this.selctedProd.prod_units =resp['data']['prod_units'];  
        // this.rate =600;
        this.calcRates();
       }
     
       this.selctedBatchStock = resp['data']['gdStock'].gbs_qty || 0;
       this.batch_available_stock = resp['data']['gdStock'].gbs_qty || 0;
       this.calcRates();
       this.selectedUnit(this.unit);

       console.log("selctedBatchStock1");
       console.log(this.selctedBatchStock);

      });
    } else{
      this.selctedProd.stock_qty = this.stock_qty;

      console.log("selctedBatchStock2");
      console.log(this.selctedBatchStock);
    }
  }

  selectBatch()
  {
    if(this.selBatch){
      const searchval = new FormData();
      searchval.append('batch_id', this.selBatch);
      this.apiService.getBatch(searchval).subscribe((res) => {
        this.expiry_date =new Date(res.sb_expiry_date);
        this.manufacture_date =new Date(res.sb_manufacture_date);
      });
    }
  }
  
  // for fixed header & footer in every page
  startPrint(){
    this.headerheightInmm=this.savedTemplate.header_height;
    this.footerheightInmm=this.savedTemplate.footer_height;
    this.pagelabelBottomInmm=this.savedTemplate.botom_h;
    this.pagelabelLeftInmm=this.savedTemplate.left_h;
    if(this.savedTemplate.page_no_display){
      this.dispalyPageNo=true;
      this.pageLabel=this.savedTemplate.page_format;
    }   
    

    // if(this.dispalyPageNo){
      const contentHeight = document.getElementById("printContentArea").scrollHeight;
      var dpi = this.getDPI();
      var contentHeightinmm = (contentHeight/dpi * 25.4) - this.headerheightInmm - this.footerheightInmm;
      var correctionpx = 0;
      var correctionpxinmm = (correctionpx/dpi * 25.4);
 
      var singlePageAvlSpaceInmm = 297 - this.headerheightInmm - this.footerheightInmm - correctionpxinmm;

      var totalPage = Math.ceil(contentHeightinmm / singlePageAvlSpaceInmm);
      

      // for (var i = 1; i <= totalPage; i++) {
      //   var pageNumberDiv = document.createElement("div");
      //   var pageNodisplay = this.pageLabel.replace("[page_no]", "" + i).replace("[page_total_no]", "" + totalPage)
      //   var pageNumber = document.createTextNode(pageNodisplay);
      //   pageNumberDiv.style.position = "absolute";
      //   pageNumberDiv.style.color = "#000";
       
      //   pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - " + this.pagelabelBottomInmm + "mm)"; 
      //   pageNumberDiv.style.height = "16px";
      //   pageNumberDiv.appendChild(pageNumber);
      //     $('#print-sales').append(pageNumberDiv);
      //   // }
      //   pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + " + this.pagelabelLeftInmm + "mm))";
      // }
    // }
    
    // Date & Time are displayed only on (Invoice With Header & Footer TEMPLATE 2)
    if (this.print_style == 14) {
      // Clear the container before appending new elements
      $('#print-sales .dateTime').remove();

      // Get current date & time
      for (var i = 1; i <= totalPage; i++) {
        var date = new Date();
        var currentDateTime = formatDate(date, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
        var dateTime = document.createElement("div");
        var dateAndTime = document.createTextNode(currentDateTime);
        dateTime.style.position = "absolute";
        dateTime.style.color = "#000";
        dateTime.style.top = "calc((" + i + " * (297mm - 0.5px)) - " + this.footerheightInmm + "mm)";
        dateTime.style.height = "16px";
        dateTime.style.left = "18px";
        dateTime.className = "dateTime";
        dateTime.appendChild(dateAndTime);
        $('#print-sales').append(dateTime);
      }
    }

    $('#printButton').click();
  }

  // for header in first & footer in last
  startPrint1() {
    const contentHeight = document.getElementById("printContentArea").scrollHeight;
    var dpi = this.getDPI();
    var contentHeightinmm = (contentHeight / dpi * 25.4) + this.headerheightInmm + this.footerheightInmm;


     var totalPage = Math.ceil(contentHeightinmm / 297 );
    
     if (this.savedTemplate.header_type != 1) {
      var footerImgDiv = document.createElement("div");
      footerImgDiv.setAttribute("id", "footerImgDiv");
      footerImgDiv.style.position = "absolute";
      footerImgDiv.style.color = "#000";
      footerImgDiv.style.width = "100%";
      footerImgDiv.innerHTML += "<img src = " + this.footImgUrl +" width='100%'>";
      footerImgDiv.style.top = "calc((" + totalPage  + " * (297mm - 0.5px)) - "+ this.footerheightInmm +"mm)";
     }

      $('#print-sales').append(footerImgDiv);
      $('#printButton').click();
}

  getDPI(){  
      var div = document.createElement('div');
        div.style.display = 'block';
        div.style.height = '100mm';
        div.setAttribute("id","my_mm");
        document.body.appendChild(div);
        var px = $('#my_mm').height();
      return Math.round(px/3.93901);
  }

  changePrintStyle(printID){
          // style for print
          if(printID == 13 || printID ==14){
            this.stylecssheaderfooter = "assets/fixed_print.css?v=3";
    
          }else{
            this.stylecssheaderfooter = "assets/falcon_print.css?v=3";
            
          }
  }


  copysalesinvno(refno){
    this.service_additional_charges=[];
    this.ServiceAdditionaltotalAmount=0;
    this.additional_charges=[];
    this.additional_total_amount=0;
    this.doneMessage=false;
    this.sq_inv_no=null;
    this.editAdditionalServ = false;
    this.getAdditonalCharge();
    const searchval = new FormData();
    searchval.append('sales_inv_no', refno);
  
  this.importLoader=true;
  
    this.apiService.getsalesinvoicecopyDetails(searchval).subscribe((res) => {
      console.log(this.salesItems);
      
      if(res.data){
      this.purchtypesel = res.data.sales_tax_type + 1;
      this.salesItems = res.data.sales_sub;
      if (res.data.sales_due_date) {
        this.valid_till_date = new Date(res.data.sales_due_date);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sales_date);

      if (res.data.sales_cust_type == 0) {
        this.cust_type = 1;
      } else if (res.data.sales_cust_type == 1) {
        this.cust_type = 2;
      }

      if (res.data.sales_pay_type == 0) {
        this.sale_pay_type = 2;
      } else if (res.data.sales_pay_type == 1) {
        this.sale_pay_type = 1;
      } else if (res.data.sales_pay_type == 2) {
        this.sale_pay_type = 3;
      }

      if (res.data.sales_acc_ledger_id > 0) {
        this.defLedger = res.data.acc_ledger;
        this.sale_acc_ledger_id = res.data.sales_acc_ledger_id;
      }

      this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
      if(res.data.customer && res.data.customer.price_group_id){
        this.price_group_id = res.data.customer.price_group_id;
      }
      this.cust_enable_sale_below_mrp = (res.data.customer ? res.data.customer.enable_sale_below_mrp : 0);
      this.cust_name = res.data.sales_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sales_cust_address;
      this.cntct_num = res.data.sales_cust_ph;
      this.cntctPerson = res.data.sales_contct_prsn;
      this.reference = res.data.sales_reference;
      this.vat_no = res.data.sales_cust_tin;
      this.net_disc = res.data.sales_discount;
      this.sale_agent = res.data.sales_agent_ledger_id;
      this.round_sign = (res.data.sales_roundoff && res.data.sales_roundoff< 0) ? -1 : 1;
      this.roundoff = Math.abs(res.data.sales_roundoff);

      this.sales_note = res.data.sales_notes;
      this.sales_note2 = res.data.sales_notes2;
      this.sales_note3 = res.data.sales_notes3;
      // this.sale_agent = res.data.sqdrf_sales_agent;
      this.sumSaleTotal();
      this.changeAmnt();
      this.no_transfr='';
      this.importLoader = false;
      this.editMessage = false;
      this.listing = false;
      this.is_description = this.salesItems.filter(x => x.item_desc !== '');
      
    }else{
      this.importLoader=false;
      this.no_transfr='Not found. Please check the reference number';
      this.salesItems = [];
      this.totItemprice = 0;
      this.totItemDisc = 0;
      this.totVatAmnt = 0;
      this.net_disc = 0;
      this.round_sign = 1;
      this.roundoff = 0;
      this.resetProdForm();
      this.sqdrf_id = '';
      this.sq_inv_no = '';
      this.sale_agent=null;
      this.reference='';
      this.cust_id=null;
      this.editMessage = false;
      this.valid_till_date = '';
      this.saleqt_date = new Date();
      // this.sale_acc_ledger_id=null;
      // this.sale_pay_type = 1;
      this.clearCustomer();
   }
    });
  }

  generateSalesOrderPdf(id,i) {
    this.downloadingIndex = i;
    this.list_sales[i].pdfLoadingImg = true;
    this.previewSales(id,0);
    

  }

  generateSalesOrderPdf1() {
    const options = {
      filename: this.saleQtnData.sq_branch_qt_no + ".pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2,y: 0,  scrollY: 0, allowTaint: false, useCORS: true },
      jsPDF: { orientation: "p",  unit: "mm", format: "a4", precision: 5 },
      pagebreak: { after: ".page-break" },
    };

    setTimeout(() => {
      const content = document.getElementById("sale-order-print");
      html2pdf().from(content).set(options).then(() =>{
        this.list_sales[this.downloadingIndex].pdfLoadingImg = false;
        this.downloadingIndex = -1;
      }).save()
    }, 1000);
  }

  eInvoiceResubmit(sales_inv_no,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'sales',
        ref_no : sales_inv_no
      }
      this.list_sales[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.list_sales[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.apiService.updateEinvoiceNotificationChange();
          this.listQtn(1);
        }
      });
    }
  }

  addOnProdProcess(){

    this.parents = this.salesItems.filter(x => x.is_addon == 0);
    this.parents_products = [];
    this.parent_product ={ id: 0, parent_product: 'None',prd_id :0 };
    this.parents_products.push({ "id": 0, 'parent_product': 'None','prd_id':0 });
    this.parents.forEach((element, index) => {
      let tmp = <any>{};
      tmp.id =index+1;
      tmp.parent_product = element.prd_name;
      tmp.prd_id = element.salesub_prd_id;
      this.parents_products.push(tmp);
    });


  }
  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      if(res['data']){
        this.g_settings = res['data'];
        this.generalSearch_type = this.g_settings.g_s_search_type;
      }else{
        this.generalSearch_type=0;
      }
      
    });

  }

  searchcustvat(Vatnomber){
    if(this.cust_type == 2){
    const searchval = new FormData();
      searchval.append('cust_vat_no', Vatnomber)
      this.apiService.getCustomerByCode(searchval).subscribe((res) => {
        if (res['data']  && res['data'].length >0 ) {
          this.srchmsg=null;
          this.noCustomer = false;
          this.customer  = res['data'];
          this.myselect.open();
        }
        else {
          this.srchmsg= res['msg']
          this.noCustomer = true;
          this.cust_id=null;

          this.clearCustomer();
        }
  
      });

  }
}

  searchCusPhone(phone){
   if(this.cust_type == 2){
    const searchval = new FormData();
      searchval.append('cust_phone_number', phone)
      this.apiService.getCustomerByCode(searchval).subscribe((res) => {
        this.cust_id = res['data'];
        this.srchmsg= res['msg'];
        if (res['data']) {
          this.srchmsg=null;
          this.noCustomer = false;
          this.selectCustomer(this.cust_id);
        }
        else {
          this.cust_balance=0
          this.noCustomer = true;
          this.clearCustomer();
        }
  
      });


  }

  
}
updateSalesTableSettigns() {
  let tabSett = this.coreService.getLocalStorage("sls");
  let disp_col = [];
  if (tabSett) {
    disp_col = tabSett;
  } else {
    disp_col = this.disp_col;
  }
 
  disp_col.forEach((val) => {
    this.tableSettings[val.id] = !val.show;
  });
 
}
updateTableSettings() {
  this.coreService.setLocalStorage(
    "sls",
    JSON.stringify(this.disp_col)
  );
  this.updateSalesTableSettigns();
}
  addCustomer(){
    this.loadCustomerPopup = true;
  }

  loadNewProd(){
    this.loadNewProductPopup = true;
    setTimeout(function () { 
      $('.addProdNewBtn').click();
    }, 100);
    
    // this.openModal('add_qp_mdl', 'lg')
  }
}

