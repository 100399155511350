
import { Component, OnInit,ViewChild, ɵConsole,ElementRef } from '@angular/core';
import { Catagory } from 'src/app/model/catagory.model';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-branch-stock-receipt',
  templateUrl: './branch-stock-receipt.component.html',
  styleUrls: ['./branch-stock-receipt.component.css']
})


export class BranchStockReceiptComponent implements OnInit {
   @ViewChild('prdctSearch', { static: false }) prdctSearch: NgSelectComponent;
   @ViewChild('fieldName1', { static: false }) fieldName1: ElementRef;
  @ViewChild('fieldName2', { static: false }) fieldName2: ElementRef;

  @ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;
  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
   loading: boolean;

   rate_all = [
    { "type_id": 1, 'name': 'Sales Rate'  },
    { "type_id": 2, 'name': 'Purchase Rate'  },
    { "type_id": 3, 'name': 'Custom' },
    { "type_id": 4, 'name': 'Last Transfer Rate' },
    { "type_id": 5, 'name': 'Average Cost' }

  ];
   rate_status_type: any;
   selctedProd: any;
   rate_type = [
    { id: 1, name: 'Rate1' },
    { id: 2, name: 'Rate2' },
  ];
  sel_rate_type = 1;
  received_date: any;
  cat_name: any;
  cat_remarks: any;
  cat_pos: any;
  allcat: any;
  showrate:any=false;
  prdn_date :any;
  resultobj: any = {};
  selectedData: any = { cat_id: null, error: null, cat_name: null, message: null, cat_pos: null };
  result = '';
  catagories: string[];
  previewData = [];
  formula_list = [];
  accepted_list = [];
  rejected_list = [];
  voidInprogress=<any>{};
  request_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  request_slnum: number;
  printError = [];
  
  curpage = 1;
  lastpage = 1;
  request_curpage = 1;
  request_lastpage = 1;
  perpage = 5;
  from: any;
  request_from: any;
  rlastpage: any;
  rcurpage: any;
  rpgend: boolean;
  rpgstart: boolean;
  rfrom: any;
  acurpage: any;
  alastpage: any;
  afrom: any;
  apgstart: boolean;
  apgend: boolean;
  accept: any;
  godowns: any;
  option: any;
  gd_id: any;
  is_purch_rate: number;
  country_dec: string;
  base_url: any;
  img_url: any;
  sales_settings: any;
  header_type: any;
  logoImg: any;
  logoImgUrl: any;
  branch_id: any;
  balance: number;
  prd_id: any;
  brcode: any;
  prd_units = [];
  type_id= 1;
  stkreqsub_qty: any;
  default_unit: any;
  branch_default_search: any;
  branch_all= [];
  add_perc:any;
  net_rate:any;
  purchase_rate: any;
  branchStocksTotal: number;
  branchStocks: any;
  salestatus: any;
  avgPurch_rate: any;
  base_rate: any;
  userEncpType: any;
  tran_purch_price: any;
  sale_rate: any;

  net_rate_edit: any;
  prd_barcode: String;
  add_perc_edit:any;
  prd_all= [];
  search_type: any;
  commAll =0;
  below_purch_enable: any=1;
  total_amount: any;
  selectedItems = [];
  box =0;
  unit_qty: any;
  display_qty: any;
  sl_no = 0;
  total_qty = 0;
  userdefltGwn: any;
  isEdit: boolean;
  previewDataitems =[];
  transferdate: any;
  previewReqData=[];
  reqId: any;
  datevar: any;
  stkreq_notes: any;
  editData =false;
  stkreq_delivery_date: any;
  stkreq_delivery_time: any;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
    private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.prdn_date = new Date();
    this.setProdId();
    this.langChange();
    this.listAllBranches();
    this.accept = 0;
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.gd_id = Number(this.userdefltGwn);
    this.country_dec=this.coreService.setDecimalLength();
    this.listTransfers();
    this.listAccepted();
    this.listRejected();
    this.getGodownList();
    this.listTransferRequests();
    this.option = 0;
    this.gd_id = Number(this.coreService.getUserData('usr_default_godown_id'));
    if(this.gd_id > 0){
      this.gd_id = this.gd_id;
    }else{
      this.gd_id = 0;
    }
    // console.log(this.gd_id);
    this.is_purch_rate = 0;
    this.getTrasferSetting();

    this.getHeaderImage();
    this.showrate = localStorage.showrate&&localStorage.showrate=='1' ? 1 : 0;
    this.type_id = 1;
  }

  langChange(){
    this.translate.get(['Table.mrp','HOME.purchase_rate','HOME.CUSTOM','Common.last_transfer_rate','Common.avg_cost']).subscribe((res: string) => {    
      
      this.rate_all = [
        { type_id: 1, name:  res['Table.mrp'] },
        { type_id: 2, name:  res['HOME.purchase_rate'] },
        { type_id: 3, name:  res['HOME.CUSTOM'] },
        { type_id: 4, name:  res['Common.last_transfer_rate'] },
        { type_id: 5, name:  res['Common.avg_cost'] },
      ];

    });
    
  }
  getTrasferSetting() {

    this.apiService.getTrasferSetting().subscribe((res) => {
      if (res['data']) {
        this.is_purch_rate = res['data'].change_purch_rate;
        this.sales_settings = res['data'];
      
        this.below_purch_enable = res['data'].ts_grant_below_purch_rate;
        this.branch_default_search = res['data'].ts_default_search; 
      } 
     
    });

  }
  
  showOption(opt){

    if(opt){
        this.option = 0;
    }else{
        this.option = 1;
    }

  }

  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godowns = res['data'];  
      //this.godowns.push({ "gd_id": '0', 'gd_name': 'Shop' });

         });
  }

  listTransfers() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '0');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.formula_list = res['data'];


      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }

  pageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.lastpage = res['last_page'];
      this.from = res['from'];

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  productionPreview(prdn_id) {
    this.previewData = [];
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      this.received_date = new Date(res.stocktr_date);
      console.log(this.previewData);
      console.log(this.previewData['frombranch']['branch_name']);

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }
  listAccepted() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '1');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.accepted_list = res['data'];
      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;
    });
  }

  acceptpageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;

    });
  }

  apgEnd(curr, end) {
    if (curr == end)
      this.apgend = true;
    else
      this.apgend = false;
  }

  apgStart(curr) {
    if (curr == 1)
      this.apgstart = true;
    else
      this.apgstart = false;
  }






  listRejected() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '2');
    this.apiService.listReceivedTransfers(searchval, 1).subscribe((res) => {

      this.rejected_list = res['data'];


      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];

      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;
    });
  }

  rejectpageNext(status, pageno: any) {
    console.log(status);
    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listReceivedTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }

      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];

      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;

    });
  }

  rpgEnd(curr, end) {
    if (curr == end)
      this.rpgend = true;
    else
      this.rpgend = false;
  }

  rpgStart(curr) {
    if (curr == 1)
      this.rpgstart = true;
    else
      this.rpgstart = false;
  }

  createCat(formdata: { value: Catagory; }) {
    this.pageLoadingImg = true;

    this.apiService.createCat(formdata.value).subscribe((catagory: Catagory) => {
      this.pageLoadingImg = false;

      if (catagory.error != null) {
        this.result = catagory.error;
        console.log(this.result);
      }
      else {
        this.coreService.createfunct(formdata, this.result, catagory.message);
      }

    });
  }


  acceptTransfer(formdata: { value: any; }) {
    if (confirm('Are you sure you want to accept the transfer? ')) {
      // const searchval = new FormData();
      formdata.value.status = 1;
      // searchval.append('stocktr_id', tranID);
      // searchval.append('status', '1');
      this.pageLoadingImg = true;
      this.apiService.acceptOrrejectTransfer(formdata.value).subscribe((res) => {
        this.pageLoadingImg = false;
        this.ngOnInit();
        $('.close').click();
        this.coreService.createfunct(formdata.value, this.resultobj, res.message);
      });
    }
  }

  rejectTransfer(tranID: string) {
    var confrm = confirm("Are you sure you want to reject the transfer? ");

    if (confrm) {
      let searchval = new FormData();
      searchval.append('stocktr_id', tranID);
      searchval.append('status', '2');
      this.pageLoadingImg = true;
      this.apiService.acceptOrrejectTransfer(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        this.ngOnInit();
        $('.close').click();
        this.coreService.createfunct(searchval, this.resultobj, res.message);
      });

    }
  }


  //form search
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').show();
  }
  onShowRateChange(showrate){ 
    if(showrate){
      localStorage.setItem("showrate","1");
      console.log('this.showrate',this.showrate);
    
    }else{
      localStorage.setItem("showrate","0");
      console.log('this.showrate',this.showrate);
    
    }
     
  }

  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });
    $('.prev-list').hide();
  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    $('.prev-list').hide();
  }

  selectCat(cat: Catagory) {
    if (cat) {
      this.result = '';
      $("#editcatf").show();
      this.selectedData = cat;
    }
    this.catagories = [];
  }

  tabClick(tab: { index: number; }) {
    if (tab.index == 1)
      this.result = '';
  }

  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {
    this.base_url = this.apiService.PHP_API_SERVER
    this.img_url = res.data.prnt_common_header_img;
    this.header_type = res.data.prnt_header_type;
    this.logoImg = res.data.prnt_header_logo;
    this.logoImgUrl = res.data.prnt_header_logo_img_path;
    });
  }

  clearForm() {
    this.stkreq_delivery_date ="";
    this.stkreq_delivery_time ="";
    this.isEdit =false;
    this.selctedProd=[];
    this.pageLoadingImg =false;
    this.selectedItems =[];
    this.editData =false;
    this.net_rate = 0;
    this.getTrasferSetting();
    this.branch_id = 0;
    this.balance = 0;
    this.prd_id = '';
    this.brcode = '';
    this.prd_units = [];
    this.type_id = 0;
    this.stkreqsub_qty = '';
    this.default_unit = [];
   
    // setTimeout(() => {
    //   if(this.branch_default_search){
    //     this.prdctSearch.focus();
    //   }else{
    //     this.fieldName2.nativeElement.focus();
    //   }
    // }, 500);
    this.ngOnInit();

  }

  listAllBranches() {
    let searchval = new FormData();

    this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this. branch_all  = res['data'];



    });

  }

  prodDetails(prd_id,branchto: string,actn_type=''){
   
    if(prd_id){
      this.stkreqsub_qty='';
      const searchval = new FormData();
      searchval.append('prd_id', prd_id.prd_id);
      searchval.append("branch_received", branchto);
      searchval.append("godown_id", this.gd_id);
      this.loading=true;
      this.apiService.gettrnsferinfProdDetailsAll(searchval).subscribe((res) => {
        this.loading=false;
        this.selctedProd = res['data'][0];
       this.setValues(this.selctedProd,1,actn_type)
      });
    }
    
  }

  setValues(prd_data, type,actn_type='') {


    console.log('prd_data',prd_data);
    
    if (prd_data) {
      // for purchaserate validation
      // if(prd_data.prd_id){
        this.getStockDetails(prd_data.prd_id, this.gd_id);
        this.getBranchWiseStock(prd_data.prd_id);
      }else{
        this.selctedProd=[];
        return false;
      }
    if (prd_data.prd_base_unit_id) {

      if (type > 0) {
        this.fieldName1.nativeElement.focus();
      }
      else {
    
        this.fieldName3.nativeElement.focus();
      }
    }

    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    
    // this.transub_qty = this.stkreqsub_qty = '1';
    if(prd_data.prd_default_unit){
      this.default_unit = prd_data.prd_default_unit;
     
    }else{
      if (!prd_data.eanunit) {
        this.default_unit = {
          "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name,
          "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1,
          "sale_rate": prd_data.bs_srate, "purch_rate": prd_data.bs_prate,
          "avg_cost": prd_data.bs_avg_prate,
          "last_rate": prd_data.last_rate,
          "purchavrg_rate": prd_data.bs_avg_prate,

        };
      }
      else {
        this.default_unit = prd_data.eanunit;
      }
    }

    // this.type_id = 1;

    if (this.gd_id > 0) {
    }
    else {
      this.gd_id = 0;
    }

  }

  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
     
    });

  }

  getBranchWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.branchStocksTotal = 0;

    this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
      this.branchStocks = res['data']['data'];
      this.branchStocksTotal = res['data']['total'];

    });

  }


  searchProducts(search: string, brcode: string, branchto: string) {

    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();

    if (search) {
      searchval.append("keyword", search);
      searchval.append("branch_received", branchto);
      if(this.commAll==1){
        searchval.append("alias",'1');
      }
      searchval.append("godown_id", this.gd_id);
     
    }

    this.apiService.searchProductsForTranrequest(searchval).subscribe((res) => {
      
       //this.prd_all =  res['data'];
      //   this.prd_all =  res['data'].filter(x => x.branch_qty != "" || x.branch_qty >= 0);
      
      if(this.gd_id == 0){
      
        this.prd_all =  res['data'];
       }
       if(this.gd_id > 0 && this.search_type==2){
       
        this.prd_all =  res['data'].filter(x => x.branch_qty != "");
       }
       

    });
  }

  resetItemsearch() {
    this.prd_barcode = '';
    this.default_unit = [];
    this.stkreqsub_qty = '';
  }

  
  checkEnterClicked(e) {
    if (e.keyCode === 13) {
      $("#plusButton").click();
    }
  }

  appendItem(prd_data, prd_barcode, stkreqsub_unit_id) {

    //  alert(prd_data.prd_barcode);
    // alert(stkreqsub_unit_id.unit_base_qty);

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }



    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!stkreqsub_unit_id) {
      this.printError.push({ 'stkreqsub_unit_idErr': 'Required' });
      $('#stkreqsub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#stkreqsub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {

     


      this.box = this.stkreqsub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (stkreqsub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = stkreqsub_unit_id.unit_code;
        this.stkreqsub_qty = this.box * (stkreqsub_unit_id.unit_base_qty);
      }


      this.sl_no = (this.selectedItems.length) + 1;

      this.stkreqsub_qty = Number(this.stkreqsub_qty);
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      
      else {

        existingArray = this.coreService.checkTransferReqExist(this.selectedItems,
          prd_data.branch_stock_id,
          stkreqsub_unit_id.sur_unit_id,
          prd_data.prd_barcode,
          stkreqsub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).stkreqsub_qty += this.stkreqsub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.stkreqsub_qty;
    
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }

        else {
          this.selectedItems.push({
            "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "stocktrsub_prd_id": prd_data.prd_id,
            "prd_alias": prd_data.prd_alias,
            "prd_name": prd_data.prd_name,
            "stkreqsub_qty": this.stkreqsub_qty,
            "stocktrsub_qty": this.stkreqsub_qty,
            "box": this.box,
            "gd_id": this.gd_id,
            "stkreqsub_prd_id": prd_data.prd_id,
            "stkreqsub_stock_id" : prd_data.bs_stock_id,
            "stkreqsub_branch_stock_id": prd_data.branch_stock_id,
            "stkreqsub_unit_id": stkreqsub_unit_id.sur_unit_id,
            "unit_name": stkreqsub_unit_id.unit_name,
            "unit_base": stkreqsub_unit_id.unit_base_qty,
            "unit_code": prd_data.base_unit_code,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": this.display_qty,
            "item_unit_code": stkreqsub_unit_id.unit_code,
         
          });

        }
      }






      //  this.stkreqsub_qty = Number(this.stkreqsub_qty);
      this.total_qty = this.selectedItems.length;
      this.stkreqsub_qty = this.box;
      // $('#gdtran-btn').show();
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.selctedProd=[];
      this.default_unit = [];
      this.stkreqsub_qty = '';
     

    }
    if(this.branch_default_search){
      console.log('branch_default_search',this.branch_default_search);
      // document.getElementById("prdctSearch").focus();
      this.prdctSearch.focus();
    }else{
      this.fieldName2.nativeElement.focus();
    }
    // this.fieldName2.nativeElement.focus();

  }


  validateForm() {


    this.printError = [];
   
    if (this.stkreqsub_qty == undefined || this.stkreqsub_qty == null || this.stkreqsub_qty < 0) {
      this.printError.push({ 'stkreqsub_qtyErr': 'Required' });
      $('#stkreqsub_qty').css("border", "1px solid red");
    } else {
      $('#stkreqsub_qty').css("border", "1px solid #dedede");
    }

  

    if ($('#stkreqsub_qty').val() == "" || $('#stkreqsub_qty').val() == undefined || $('#stkreqsub_qty').val() == 0) {
      this.printError.push({ 'stkreqsub_qtyErr': 'Required' });
      $('#stkreqsub_qty').css("border", "1px solid red");
    }
  

  }

 

  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;



      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }
    }
      
  }

 

 
  addRequest(formData) {
     
    console.log("formData.value");
    console.log(formData.value);
    formData.value.stkreq_delivery_time = (formData.value.stkreq_delivery_time) ? this.apiService.formatTime(formData.value.stkreq_delivery_time) : formData.value.stkreq_delivery_time;
    formData.value.stkreq_delivery_date = (formData.value.stkreq_delivery_date) ? this.apiService.formatDate(formData.value.stkreq_delivery_date) : formData.value.stkreq_delivery_date;
    var confrm = confirm("Are you sure?");
    if (confrm) {

      this.validateTransfer(formData.value);

      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        this.apiService.branchTransferReq(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          $('.close11').click();
          this.coreService.showMessage(res.message);
          this.ngOnInit();

          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          this.tran_purch_price = 0;
          this.prdn_date = new Date();
          //this.requestPreview(res.stkreq_id,"");
          //$("#previewButton").click();
          this.gd_id = Number(this.userdefltGwn);



        });
      }
      else {

      }
    }
  }

  updateRequest(formData) {

    var confrm = confirm("Are you sure?");
    if (confrm) {

      formData.value.stkreq_delivery_time = (formData.value.stkreq_delivery_time) ? this.apiService.formatTime(formData.value.stkreq_delivery_time) : formData.value.stkreq_delivery_time;
      formData.value.stkreq_delivery_date = (formData.value.stkreq_delivery_date) ? this.apiService.formatDate(formData.value.stkreq_delivery_date) : formData.value.stkreq_delivery_date;

      this.validateTransfer(formData.value);

      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        this.apiService.branchTransferReqUpdate(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          $('.close11').click();
          this.coreService.showMessage(res.message);
          this.ngOnInit();

          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          this.tran_purch_price = 0;
          this.prdn_date = new Date();
          // this.requestPreview(res.stkreq_id,"");
          //$("#previewButton").click();
          this.gd_id = Number(this.userdefltGwn);



        });
      }
      else {

      }
    this.editData =false;
    }
  }



  validateTransfer(formData) {




    this.printError = [];
    if (formData.stkreq_date == "" || formData.stkreq_date == undefined) {
      $('#stkreq_date').css("border", "1px solid red");
      this.printError.push({ 'stkreqsub_qtyErr': 'Required' });
    }
    else {
      // if (formData.stkreq_date > new Date()) {
      //   $('#stkreq_date').css("border", "1px solid red");
      //   this.printError.push({ 'rec_date': 'Required' });
      // } else {
        $('#stkreq_date').css("border", "1px solid #dedede");
      // }

    }
    if (formData.branch_to == "" || formData.branch_to == undefined) {

      $('#branchid').css("border", "1px solid red");
      this.printError.push({ 'stkreqsub_qtyErr': 'Required' });
    }
    else {

      $('#branchid').css("border", "1px solid #dedede");

    }
  }


  editItem(data, i) {

 
    $('.edit_row').removeClass('sel_row');
    $('#myrow' + i).addClass('sel_row');
    if(this.selectedItems.length >0)
    {
     
  
        const searchval = new FormData();
        searchval.append('prd_id', data.stkreqsub_prd_id);
        searchval.append('gd_id', data.gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {
          
       

          this.selctedProd = res['data'];

          this.prd_units = this.selctedProd[0].prd_units;
  
  
          this.selctedProd[0].prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.stkreqsub_unit_id) {

  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
       
      
            };
          });

       
    
         
        });
  
        
        data.salesub_prd_id =data.stkreqsub_prd_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {
  
          this.sl_no = data.sl_no;
         // this.prd_id = data.prod;
         this.prd_id = res.data.product;
          
          // this.sale_rate = this.prd_id.bs_srate;
          //  this.base_rate = this.prd_id.bs_prate;
          this.stkreqsub_qty = data.stkreqsub_qty / data.unit_base;
          this.add_perc = data.add_perc;
          this.net_rate =data.net_rate;
          this.type_id =data.type_id;
          // this.gd_id =data.gd_id;
  
        });
  
        this.isEdit =true;
    }

    else{
    
  
        const searchval = new FormData();
        searchval.append('prd_id', data.prd_id);
        searchval.append('gd_id', data.extstktrsub_gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {

        

          this.selctedProd = res['data'][0];

        
  
          this.prd_units = this.selctedProd.prd_units;
  
  
          this.selctedProd.prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.stkreqsub_unit_id) {
      
             
  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
          
      
            };
          });
    
         
        });
  
        
        data.salesub_prd_id =data.prd_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {
  
         
          this.sl_no = data.sl_no;
          this.prd_id = res.data.product;
          
          // this.sale_rate = this.prd_id.bs_srate;
          // this.base_rate = this.prd_id.bs_prate;

          // this.transub_qty = data.unit_base_qty;
          // this.transub_qty = data.box;
         // this.tran_purch_price =data.extstktrsub_rate;

          //this.convertRate(this.default_unit);

          this.tran_purch_price =data.rate;
          this.add_perc_edit = data.add_perc;
          this.net_rate_edit =data.net_rate;
          this.type_id =data.type_id;
          // this.gd_id_edit =data.extstktrsub_gd_id;
       
  
        });
      
        // this.isEdit1 =true;
      
    }

    

    // if (data) {
     

    // }
  }

  editSaleItem(prd_data, prd_barcode, stkreqsub_unit_id)
  {
    $('.edit_row').removeClass('sel_row');
   

    // alert(prd_data.prd_barcode);
    // alert(stkreqsub_unit_id.unit_base_qty);

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }



    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!stkreqsub_unit_id) {
      this.printError.push({ 'stkreqsub_unit_idErr': 'Required' });
      $('#stkreqsub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#stkreqsub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {


     

      this.box = this.stkreqsub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (stkreqsub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = stkreqsub_unit_id.unit_code;
        this.stkreqsub_qty = this.box * (stkreqsub_unit_id.unit_base_qty);
      
      }


      //this.sl_no = (this.selectedItems.length) + 1;


      this.stkreqsub_qty = Number(this.stkreqsub_qty);
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferReqExist(this.selectedItems,
          prd_data.branch_stock_id,
          stkreqsub_unit_id.sur_unit_id,
          prd_data.prd_barcode,
          stkreqsub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).stkreqsub_qty += this.stkreqsub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.stkreqsub_qty;
       
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
         

        
      else {
        
      this.selectedItems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stkreqsub_qty = this.stkreqsub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_qty = this.stkreqsub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).box = this.box;
      this.selectedItems.find(x => x.sl_no === this.sl_no).gd_id = this.gd_id;
    
      this.selectedItems.find(x => x.sl_no === this.sl_no).stkreqsub_prd_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stkreqsub_stock_id = prd_data.bs_stock_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stkreqsub_branch_stock_id = prd_data.branch_stock_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).stkreqsub_unit_id = stkreqsub_unit_id.sur_unit_id;
    
      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_name = stkreqsub_unit_id.unit_name;

      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_base = stkreqsub_unit_id.unit_base_qty;
     // this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = prd_data.base_unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = stkreqsub_unit_id.unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).item_unit_code = stkreqsub_unit_id.unit_code;
   
     
     

    
     
        }
      }






      //  this.stkreqsub_qty = Number(this.stkreqsub_qty);
      this.total_qty = this.selectedItems.length;
      this.stkreqsub_qty = this.box;
      // $('#gdtran-btn').show();

      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.stkreqsub_qty = '';
     

    }
    // this.fieldName2.nativeElement.focus();
    if(this.branch_default_search){
      console.log('this.branch_default_search',this.branch_default_search);

      this.prdctSearch.focus();
    }else{
    this.fieldName2.nativeElement.focus();
    }
    this.isEdit =false;
  }

  barcodeSearch(brcode: string, branchto: string, type: string,act_type='') {
    this.net_rate = 0;
    this.add_perc = 0;
    this.net_rate_edit = 0;
    this.add_perc_edit = 0;
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    searchval.append("branch_received", branchto);
    searchval.append("godown_id", this.gd_id);

    this.apiService.searchVanProductsExtStkTrans(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      this.prd_id = res['data'][0];
      this.setValues(res['data'][0], type,act_type);

    });
  }

  listTransferRequests() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    // searchval.append("status", '0');
    this.apiService.listTransferRequest(searchval, 1).subscribe((res) => {

      this.request_list = res['data'];


      this.pageLoadingImg = false;
      this.request_curpage = res['current_page'];
      this.request_from = res['from'];
      this.request_lastpage = res['last_page'];
      this.pgEnd(this.request_curpage, this.request_lastpage);
      this.pgStart(this.request_curpage);
      this.request_slnum = 0;
    });
  }

  requestPreview(stkreq_id,edit_pending) {

    let searchval = new FormData();
    searchval.append("stkreq_id", stkreq_id );
    this.apiService.transferReqPreview(searchval).subscribe((res) => {
      this.previewReqData = res;
    
      this.previewDataitems = res.items;
      $('#brtran-btn').show();
      this.transferdate = this.datePipe.transform(res.stocktr_date, 'dd/MM/yyyy');
      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);
    });

  }

  editRequest(stkreq_id){
     
    // $('#gdtran-btn').show();
    this.editData =true;
    let searchval = new FormData();
    searchval.append("stkreq_id", stkreq_id );
    this.apiService.transferReqPreview(searchval).subscribe((res) => {
      this.previewReqData = res;
     
      this.reqId = res.stkreq_id;
      this.prdn_date = new Date(res.stkreq_date);
      this.branch_id = res.stkreq_to;
      this.gd_id =res.stkreq_gd_id;
      this.stkreq_notes =res.stkreq_notes;
      this.stkreq_delivery_date = new Date(res.stkreq_delivery_date);
      this.stkreq_delivery_time = new Date(res.delivery_date_and_time);

      if(res.stkreq_delivery_date == "0000-00-00"){
        this.stkreq_delivery_date ="";
      }
      if(res.stkreq_delivery_time == "00:00:00"){
        this.stkreq_delivery_time = "";
      }
     
     
      // this.datevar = this.datePipe.transform(res.stocktr_date, 'dd/MM/yyyy');
      // var to = this.datevar.split("/");
      // this.prdn_date = new Date(to[2], to[1] - 1, to[0]);
      // new Date(res.stocktr_date)
     
      



      this.previewDataitems = res.items;
      $('#brtran-btn').show();
      this.selectedItems =this.previewDataitems;

  
    });

  }

  voidTransferRequest(stkreq_id) {
    let formData = new FormData();
    var confrm = confirm("Are you sure you want to void transfer request? ");
    formData.append("stkreq_id", stkreq_id);
    if (confrm) {
      this.voidInprogress[stkreq_id] = true;
      this.apiService.voidTransferRequest(formData).subscribe((res) => {
        this.ngOnInit();
        this.pageLoadingImg = false;
        this.coreService.createfunct(formData, this.resultobj, res.message);
        this.selectedItems = [];
        this.total_qty = 0;
        this.total_amount = 0;




      });

    }

    else {

    }
  }

  setProdId() {
    let searchval = new FormData();
    this.apiService.gereqId(searchval).subscribe((res) => {
      this.reqId = res['data']['stkreq_id'];

    });
  }
}