import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
@Component({
  selector: 'app-qtn-template-two-print',
  templateUrl: './qtn-template-two-print.component.html',
  styleUrls: ['./qtn-template-two-print.component.css']
})
export class QtnTemplateTwoPrintComponent implements OnInit {

  @Input()saleQtnData;
    @Input()print_style;
    @Input()qr_inv;
    @Input()savedTemplate;
    @Input()is_prew_description;
    @Input()payTypes;
    @Input()cmpny;
    @Input()previewLoader;
    @Input()logoImgUrl;
    @Input()tempImgUrl;
    @Input()footImgUrl;
    @Input()sales_print_name;
    @Input()hide_item_discount;
    @Input()hide_vat;
    @Input()sales_settings;
    @Input()hide_prnt_alias;
    country_dec: any;
  
    // pc / dip = inch
    // inch * 25.4 = mm
    // in mm milli meter ( pixcel / dip * 25.4)
    // 130px means 130 / 96 * 25.4 =  34.3958
    // headerheightInmm = 34.4;
  
    // 73.5mm (278px) maximum height allowed 
    headerheightInmm = 34.4;
  
    // 130px means 130 / 96 * 25.4 =  31.75
    /// 73.5mm (278px) maximum height allowed 
    footerheightInmm = 31.75;
  
    constructor(private coreService: CoreService) { }
  
    ngOnInit() {
  
      this.country_dec = this.coreService.setDecimalLength();
    }
  
    ngOnChanges(changes) {
      if (changes['saleQtnData'] && this.saleQtnData) {
  
        this.headerheightInmm = this.savedTemplate.header_height;
        this.footerheightInmm = this.savedTemplate.footer_height;
  
      }
    }
  
    startPrint() {
      const contentHeight = document.getElementById("printContentArea").scrollHeight;
      var dpi = this.getDPI();
      var contentHeightinmm = (contentHeight / dpi * 25.4) - this.headerheightInmm - this.footerheightInmm;
      var correctionpx = 0;
      var correctionpxinmm = (correctionpx / dpi * 25.4);
      // 297 mm A4 height 
      var singlePageAvlSpaceInmm = 297 - this.headerheightInmm - this.footerheightInmm - correctionpxinmm;

      var totalPage = Math.ceil(contentHeightinmm / singlePageAvlSpaceInmm);
      console.log("contentHeightinmm" + contentHeightinmm);
      console.log("totalPage" + totalPage);
  
      if (this.savedTemplate.header_type != 1) {
        var footerImgDiv = document.createElement("div");
        footerImgDiv.setAttribute("id", "footerImgDiv");
        footerImgDiv.style.position = "absolute";
        footerImgDiv.style.color = "#000";
        footerImgDiv.style.width = "100%";
        footerImgDiv.innerHTML += "<img src = " + this.footImgUrl + " width='100%'>";
        footerImgDiv.style.top = "calc((" + totalPage + " * (297mm - 0.5px)) - " + this.footerheightInmm + "mm)";
      }
  
      $('#print-sales').append(footerImgDiv);
      $('#printButton').click();
    }
  
    getDPI() {
      var div = document.createElement('div');
      div.style.display = 'block';
      div.style.height = '100mm';
      div.setAttribute("id", "my_mm");
      document.body.appendChild(div);
      var px = $('#my_mm').height();
      return Math.round(px / 3.93901);
    }

}
