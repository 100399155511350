import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputnumonly]'
})
export class InputnumonlyDirective {

  constructor(private _el: ElementRef) { }

//   @HostListener('input', ['$event']) onInputChange(event) {
//     const initalValue = this._el.nativeElement.value;
//     this._el.nativeElement.value = initalValue.replace(/[^0-9.]*/g,'');    
//     if ( initalValue !== this._el.nativeElement.value) {
//       return false;
//       event.stopPropagation();
//     }

// }
@HostListener('input', ['$event'])
onInputChange(event: any) {
  const initalValue = this._el.nativeElement.value;

  // Remove all non-numeric characters except the dot
  let sanitizedValue = initalValue.replace(/[^0-9.]/g, '');

  // Ensure only one dot is allowed
  const dotIndex = sanitizedValue.indexOf('.');
  if (dotIndex !== -1) {
    sanitizedValue = sanitizedValue.slice(0, dotIndex + 1) + sanitizedValue.slice(dotIndex + 1).replace(/\./g, '');
  }

  // Update the input value if changed
  if (initalValue !== sanitizedValue) {
    this._el.nativeElement.value = sanitizedValue;
    event.stopPropagation();

    // Manually dispatch an input event to update Angular's model
    const inputEvent = new Event('input', { bubbles: true });
    this._el.nativeElement.dispatchEvent(inputEvent);
  }
}



}