import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-sales-order-logistic-temp1',
  templateUrl: './sales-order-logistic-temp1.component.html',
  styleUrls: ['./sales-order-logistic-temp1.component.css']
})
export class SalesOrderLogisticTemp1Component implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()hide_rate_colmn;
  @Input() hide_prnt_alias;
  country_dec: any;
  cmpny: any;
  noPage = [];
  noRowPerPage = 18;
  ttlPage = 1;
  data = [];
  emptyRow: any = 0;


  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.cmpny = this.coreService.getUserData('cmpny');
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {

      this.data = this.saleQtnData.sales_order_sub;
      this.ttlPage = Math.ceil((this.saleQtnData.sales_order_sub.length) /this.noRowPerPage);
      this.noPage = this.createRange(this.ttlPage);
      
      this.emptyRow = this.ttlPage * this.noRowPerPage - this.saleQtnData.sales_order_sub.length;
  
      

    }
  }

  createRange(number,start=0){
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
