import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rep-stock-verify-summary',
  templateUrl: './rep-stock-verify-summary.component.html',
  styleUrls: ['./rep-stock-verify-summary.component.css']
})
export class RepStockVerifySummaryComponent implements OnInit {
  pageLoadingImg: boolean;

  fileName = 'stock_verification.xlsx';


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  verfy_result = [
    { id: 'missing', name: 'Aval Qty. Less Than Req Stock' },
    { id: 'excess', name: 'Aval Qty. Excesses Than Req Stock' },
    { id: 'equal', name: 'Aval Qty. Is Equal To Req Stock' }

  ];

  verfy_status_items = [
    { id: 'verified', name: 'Verified' },
    { id: 'unverified', name: 'Unverified' }

  ];
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  userType: any;
  repDate: any;
  summary: any;
  users: any;
  products: any[];
  catagories: any;
  mfs: any;
  tot_items: any;
  tot_req_qty: any;
  total_vrfy_qty: any;
  tot_diff_qty: any;
  period_type: string;
  verfy_status: string;
  Uvsummary: any;
  UvTotal: any;
  rep_type: any;
  godowns: any;
  Expsummary: any;
  ExpUvsummary: any[];
  ExpUvTotal: any;
  Exprep_type: any;
  Exptot_items: any;
  Exptot_req_qty: any;
  Exptotal_vrfy_qty: any;
  Exptot_diff_qty: any;
  exportLoader: boolean;
  logData: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  branch_all: any;
  cur_lang: string;

  country_dec: string;
  exportname: any;
  withouprdname: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.cur_lang = sessionStorage.getItem("baseLang");
 this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.verfy_status = 'verified';
    this.pageLoadingImg = true;

    let searchval = new FormData();
    this.summary = [];
    this.period_type = 't';
    searchval.append("period_type", 't');
    searchval.append("verfy_status", 'verified');

    this.apiService.stockVerificationSummary(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_prods'];
      this.tot_req_qty = res['data']['total']['tot_req_qty'];
      this.total_vrfy_qty = res['data']['total']['total_vrfy_qty'];
      this.tot_diff_qty = res['data']['total']['tot_diff_qty'];
      this.rep_type = res['data']['rep_type'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.userType = this.coreService.getUserData('user_type');

    this.getGodownList();
  }
  langChange(){
    this.translate.get(['Common.today','Common.last_day','Common.last_week','Common.this_month','Common.last_yr','Common.cust_date','Common.all','Common.Transfer_Returns','Common.Transfer','Common.Returns','Common.summary','Common.detailed','HOME.Pending','HOME.PURCHASED','Common.Aval_Qty_Less_Than_Req_Stock','Common.Aval_Qty_Excesses_Than_Req_Stock','Common.Aval_Qty_Is_Equal_To_Req_Stock','Common.Verified','Common.Unverified']).subscribe((res: string) => {

     this.periodTypes = [
        { id: 't', name:res['Common.today'] },
        { id: 'ld', name:res['Common.last_day'] },
        { id: 'lw', name:res['Common.last_week'] },
        { id: 'lm', name:res['Common.this_month'] },
        { id: 'ly', name:res['Common.last_yr'] },
        { id: 'c', name:res['Common.cust_date'] },
        { id: '', name:res['Common.all'] }
      ];
      this.verfy_result = [
        { id: 'missing', name: res['Common.Aval_Qty_Less_Than_Req_Stock'] },
        { id: 'excess', name: res['Common.Aval_Qty_Excesses_Than_Req_Stock'] },
        { id: 'equal', name: res['Common.Aval_Qty_Is_Equal_To_Req_Stock'] }
    ];
    this.verfy_status_items = [
      { id: 'verified', name: res['Common.Verified'] },
      { id: 'unverified', name: res['Common.Unverified'] }
  ];
  
    });

  }
  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.summary = [];
    this.Uvsummary = [];
    if (formdata.value.verfy_status == 'unverified') {

      this.apiService.unverifiedStockSummary(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.Uvsummary = res['data']['data']['data'];
        this.pageFrom = res['data']['data']['from'];
        this.curpage = res['data']['data']['current_page'];
        this.lastpage = res['data']['data']['last_page'];
        this.repDate = res.data.Date;

        this.UvTotal = res['data']['total'];
        this.rep_type = res['data']['rep_type'];




        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
      });
    } else {

      this.apiService.stockVerificationSummary(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.repDate = res.data.Date;
        this.summary = res['data']['data']['data'];
        this.pageFrom = res['data']['data']['from'];
        this.curpage = res['data']['data']['current_page'];
        this.lastpage = res['data']['data']['last_page'];

        this.tot_items = res['data']['total']['tot_prods'];
        this.tot_req_qty = res['data']['total']['tot_req_qty'];
        this.total_vrfy_qty = res['data']['total']['total_vrfy_qty'];
        this.tot_diff_qty = res['data']['total']['tot_diff_qty'];
        this.rep_type = res['data']['rep_type'];


        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
      });
    }
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    if(this.withouprdname){
      this.exportname= 'export-group1';

    }else{
      this.exportname= 'export-group';
    }
   
    
    let element = document.getElementById(this.exportname);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.Expsummary = [];
    this.ExpUvsummary = [];
    this.exportLoader = true;

    if (formdata.value.verfy_status == 'unverified') {

      this.apiService.unverifiedStockSummary(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.ExpUvsummary = res['data']['data']['data'];

        this.ExpUvTotal = res['data']['total'];
        this.Exprep_type = res['data']['rep_type'];

        setTimeout(() => {
          this.exportexcel();
        }, 3000);

      });
    } else {

      this.apiService.stockVerificationSummary(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.repDate = res.data.Date;
        this.Expsummary = res['data']['data']['data'];

        this.Exptot_items = res['data']['total']['tot_prods'];
        this.Exptot_req_qty = res['data']['total']['tot_req_qty'];
        this.Exptotal_vrfy_qty = res['data']['total']['total_vrfy_qty'];
        this.Exptot_diff_qty = res['data']['total']['tot_diff_qty'];
        this.Exprep_type = res['data']['rep_type'];

        setTimeout(() => {
          this.exportexcel();
        }, 3000);

      });
    }
  }


  exportLog(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export_log';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.exportLoader = true;

    this.apiService.unverifiedStockSummary(formdata.value, pageno).subscribe((res: any) => {
      this.logData = res['data']['data']['data'];
      var fileText = '';
      this.logData.forEach(function (value) {
        fileText += value + ",0\n";
      });

      setTimeout(() => {
        this.saveTextAsFile(fileText, 'Unverified_stock_log.txt');
      }, 3000);

    });

  }


  // For TXT file export 

  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a')
    // FOR IE:

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
    this.exportLoader = false;

  }

  //  TXT File Export End 

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.Expsummary = [];
    this.ExpUvsummary = [];
    this.exportLoader = true;

    if (formdata.value.verfy_status == 'unverified') {

      this.apiService.unverifiedStockSummary(formdata.value, 1).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.ExpUvsummary = res['data']['data']['data'];

        this.ExpUvTotal = res['data']['total'];
        this.Exprep_type = res['data']['rep_type'];

        setTimeout(() => {
           this.pdfDownload()
        }, 2000);

      });
    } else {

      this.apiService.stockVerificationSummary(formdata.value, 1).subscribe((res: any) => {
        this.pageLoadingImg = false;
        this.repDate = res.data.Date;
        this.Expsummary = res['data']['data']['data'];
        

        this.Exptot_items = res['data']['total']['tot_prods'];
        this.Exptot_req_qty = res['data']['total']['tot_req_qty'];
        this.Exptotal_vrfy_qty = res['data']['total']['total_vrfy_qty'];
        this.Exptot_diff_qty = res['data']['total']['tot_diff_qty'];
        this.Exprep_type = res['data']['rep_type'];

        setTimeout(() => {
          this.pdfDownload()
       }, 2000);

      });
    }

        
  }

  pdfDownload(){
    const heads = [];

    if(this.rep_type == 'verified'){
      heads.splice(0,0, this.translate.instant('Common.prd_name'))
      heads.splice(1,0, this.translate.instant('HOME.stock_verification_status'))
      heads.splice(2,0, this.translate.instant('HOME.verification_date'))
      heads.splice(3,0, this.translate.instant('HOME.required_qty'))
      heads.splice(4,0, this.translate.instant('HOME.verified_qty'))
      heads.splice(5,0, this.translate.instant('HOME.diffrence_qty'))
    } else if (this.rep_type == 'unverified'){
      heads.splice(0,0, this.translate.instant('Common.prd_name'))
      heads.splice(1,0, this.translate.instant('Common.stock_qty'))
    }

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      console.log(this.Expsummary);
      

      if(this.rep_type == 'verified'){
      this.Expsummary.forEach(item=>{
        const arr=[]

          arr.splice(0,0, item.prd_name)
          arr.splice(1,0, 'Last Verified')
          arr.splice(2,0, this.datePipe.transform(item.items[0].stkvrfy_date, 'dd/MM/yyyy'))
          arr.splice(3,0, this.currencyPipe.transform(item.items[0].stkvrfy_req_qty, '', '', '1.1-1'))
          arr.splice(4,0, this.currencyPipe.transform(item.items[0].stkvrfy_existing_qty, '', '', '1.1-1'))
          arr.splice(5,0, this.currencyPipe.transform(item.items[0].stkvrfy_existing_qty - item.items[0].stkvrfy_req_qty, '', '', '1.1-1'))

        data.push(arr)

        item.items.forEach(item1=>{
          const arr=[]

            arr.splice(0,0, item.prd_barcode)
            arr.splice(1,0, 'Verified')
            arr.splice(2,0, this.datePipe.transform(item1.stkvrfy_date, 'dd/MM/yyyy'))
            arr.splice(3,0, this.currencyPipe.transform(item1.stkvrfy_req_qty, '', '', '1.1-1'))
            arr.splice(4,0, this.currencyPipe.transform(item1.stkvrfy_existing_qty, '', '', '1.1-1'))
            arr.splice(5,0, this.currencyPipe.transform(item1.stkvrfy_existing_qty - item1.stkvrfy_req_qty, '', '', '1.1-1'))

          data.push(arr)
        })

      })
    }  else if (this.rep_type == 'unverified'){
      
      this.ExpUvsummary.forEach(item=>{
        data.push([
          item.prd_name,
          item.bs_stock_quantity
        ])
      })
    }
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.stock_verification_summary')}`,  valueXPos, headerHeight + 15);

      if(this.rep_type == 'verified'){

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.tot_items,  valueXPos, headerHeight + 20);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_required_qty')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.tot_req_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_verified_qty')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_vrfy_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_diffrence_qty')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.tot_diff_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 35);
        
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.verfy_status')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + this.rep_type,  valueXPos, headerHeight + 40);

      } else if (this.rep_type == 'unverified'){

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.UvTotal.total_products,  valueXPos, headerHeight + 20);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_quantity')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' +  this.currencyPipe.transform(this.UvTotal.tot_stock_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);
        
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.verfy_status')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.rep_type,  valueXPos, headerHeight + 30);

      }

      doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, heads, data, null);
     
      doc.save(`Stock Verification Summary.pdf`);

      this.exportLoader = false;

  }

}
